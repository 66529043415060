// @todo : que faire pour ça ? C'est hyper long à chaque compilation du SCSS sinon
//@import '../../../node_modules/webf/vars-default-theme.scss';
//@import '../../../node_modules/webf/plugins.scss';
@import '../../vars_site';
@import '../../styles/site/header';
@import '../../styles/site/footer';

* {
  font-family: Poppins, 'sans-serif';
}

.inter {
  font-family: Inter, 'sans-serif';
}

.orange {
  color: $orange;
}

.bleu-clair {
  color: $bleu-clair;
}

.bleu-fonce {
  color: $bleu-fonce;
}

.test {
  color: white;
}

.noir {
  color: $texte-noir;
}

.bg-bleu-clair {
  background-color: $bleu-clair;
}

.bg-bleu-clair-light {
  background-color: lighten($bleu-clair, 50%);
}

.bg-orange {
  background-color: $orange;
}

a.bouton-acte2i,
button.bouton-acte2i {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  border-radius: 38px;
  padding: 8px 30px;
  text-decoration: none;
  
  &.bouton-bleu-clair {
    color: $bleu-clair;
    
    &:hover {
      //color: $bleu-fonce;
    }
  }
  
  &.bouton-border-bleu-clair {
    border: 2.23px solid $bleu-clair;
    
    &:hover {
      background-color: $bleu-clair;
      color: white;
    }
  }
  
  &.bouton-bg-bleu-clair {
    background-color: $bleu-clair;
    color: white;
    
    &:hover {
      background-color: white !important;
      color: $bleu-clair !important;
    }
  }
  
  &.bouton-orange {
    color: $orange;
    
    &:hover {
      //color: $bleu-fonce;
    }
  }
  
  &.bouton-border-orange {
    border: 2.23px solid $orange;
    
    &:hover {
      background-color: $orange;
      color: white;
    }
  }
  
  &.bouton-bg-orange {
    background-color: $orange;
    color: white;
    
    &:hover {
      background-color: white;
      border-color: $orange;
      color: $orange;
    }
  }
  
  &.bouton-bg-blanc {
    background-color: white;
  }
  
  &.big-bouton-acte2i {
    font-size: 20px;
    padding: 12px 40px;
  }
}

.bloc-shadow {
  box-shadow: 0 0 15px -5px rgba(0,0,0,0.25);
}

.bloc-newsletter {
  background: linear-gradient(284deg, #003D6B 6.42%, #00A3E2 82.19%);
  
  h2 {
    color: white;
    font-size: 3em;
    font-weight: 700;
  }
  
  input {
    padding: 8px 10px 8px 33px;
    border-radius: 47px;
    border: 2.23px solid #00A3E2;
    outline: none;
    width: 45%;
    display: inline;
  }
}

@media (max-width: 568px) {
  .bloc-newsletter {
    h2 {
      font-size: 2.5em;
    }
  }
}

@media (max-width: 767px) {
  .bloc-newsletter {
    input {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
    
    a {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
