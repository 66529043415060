$gutter-horizontal: 15px;
$gutter-vertical: 10px;

.p-no {
    padding: 0 !important;
}
.p-left-no {
    padding-left: 0 !important;
}
.p-right-no {
    padding-right: 0 !important;
}
.p-left {
    padding-left: $gutter-horizontal !important;
}
.p-right {
    padding-right: $gutter-horizontal !important;
}

.m-no {
    margin: 0 !important;
}
.m-left-no {
    margin-left: 0 !important;
}
.m-right-no {
    margin-right: 0 !important;
}
.m-left {
    margin-left: $gutter-horizontal !important;
}
.m-right {
    margin-right: $gutter-horizontal !important;
}

@media (max-width: 767px) {
    .p-no-xs {
        padding: 0 !important;
    }
    .p-left-no-xs {
        padding-left: 0 !important;
    }
    .p-right-no-xs {
        padding-right: 0 !important;
    }
    .p-left-xs {
        padding-left: $gutter-horizontal !important;
    }
    .p-right-xs {
        padding-right: $gutter-horizontal !important;
    }

    .m-no-xs {
        margin: 0 !important;
    }
    .m-left-no-xs {
        margin-left: 0 !important;
    }
    .m-right-no-xs {
        margin-right: 0 !important;
    }
    .m-left-xs {
        margin-left: $gutter-horizontal !important;
    }
    .m-right-xs {
        margin-right: $gutter-horizontal !important;
    }
}

@media (min-width: 768px) {
    .p-no-sm {
        padding: 0 !important;
    }
    .p-left-no-sm {
        padding-left: 0 !important;
    }
    .p-right-no-sm {
        padding-right: 0 !important;
    }
    .p-left-sm {
        padding-left: $gutter-horizontal !important;
    }
    .p-right-sm {
        padding-right: $gutter-horizontal !important;
    }

    .m-no-sm {
        margin: 0 !important;
    }
    .m-left-no-sm {
        margin-left: 0 !important;
    }
    .m-right-no-sm {
        margin-right: 0 !important;
    }
    .m-left-sm {
        margin-left: $gutter-horizontal !important;
    }
    .m-right-sm {
        margin-right: $gutter-horizontal !important;
    }
}

@media (min-width: 992px) {
    .p-no-md {
        padding: 0 !important;
    }
    .p-left-no-md {
        padding-left: 0 !important;
    }
    .p-right-no-md {
        padding-right: 0 !important;
    }
    .p-left-md {
        padding-left: $gutter-horizontal !important;
    }
    .p-right-md {
        padding-right: $gutter-horizontal !important;
    }

    .m-no-md {
        margin: 0 !important;
    }
    .m-left-no-md {
        margin-left: 0 !important;
    }
    .m-right-no-md {
        margin-right: 0 !important;
    }
    .m-left-md {
        margin-left: $gutter-horizontal !important;
    }
    .m-right-md {
        margin-right: $gutter-horizontal !important;
    }
}

@media (min-width: 1200px) {
    .p-no-lg {
        padding: 0 !important;
    }
    .p-left-no-lg {
        padding-left: 0 !important;
    }
    .p-right-no-lg {
        padding-right: 0 !important;
    }
    .p-left-lg {
        padding-left: $gutter-horizontal !important;
    }
    .p-right-lg {
        padding-right: $gutter-horizontal !important;
    }

    .m-no-lg {
        margin: 0 !important;
    }
    .m-left-no-lg {
        margin-left: 0 !important;
    }
    .m-right-no-lg {
        margin-right: 0 !important;
    }
    .m-left-lg {
        margin-left: $gutter-horizontal !important;
    }
    .m-right-lg {
        margin-right: $gutter-horizontal !important;
    }
}

@mixin transition($seconds: 1s, $property: all) {
    -webkit-transition: $property $seconds ease-in-out;
    -moz-transition: $property $seconds ease-in-out;
    -ms-transition: $property $seconds ease-in-out;
    -o-transition: $property $seconds ease-in-out;
    transition: $property $seconds ease-in-out;
}

@mixin transform($transformation) {
    -webkit-transform: $transformation;
    -moz-transform: $transformation;
    -ms-transform: $transformation;
    -o-transform: $transformation;
    transform: $transformation;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.transition02 {
    transition: 0.2s;
}
.transition05 {
    transition: 0.5s;
}
.transition1 {
    transition: 1s;
}

.m-no {
    margin: 0;
}
.m-big {
    margin-top: 80px;
    margin-bottom: 80px;
}
.m-big-top {
    margin-top: 80px;
}
.m-big-bottom {
    margin-bottom: 80px;
}
.m-medium {
    margin-top: 30px;
    margin-bottom: 30px;
}
.m-medium-top {
    margin-top: 30px;
}
.m-medium-bottom {
    margin-bottom: 30px;
}
.m-medium-sides {
    margin-left: 30px;
    margin-right: 30px;
}
.m-small {
    margin-top: 15px;
    margin-bottom: 15px;
}
.m-small-top {
    margin-top: 15px;
}
.m-small-bottom {
    margin-bottom: 15px;
}
.m-small-sides {
    margin-left: 15px;
    margin-right: 15px;
}

.p-no {
    padding: 0;
}
.p-big {
    padding-top: 80px;
    padding-bottom: 80px;
}
.p-medium {
    padding-top: 30px;
    padding-bottom: 30px;
}
.p-small {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-big-top {
    padding-top: 80px;
}
.p-medium-top {
    padding-top: 30px;
}
.p-small-top {
    padding-top: 15px;
}

.p-big-bottom {
    padding-bottom: 80px;
}
.p-medium-bottom {
    padding-bottom: 30px;
}
.p-small-bottom {
    padding-bottom: 15px;
}

.p-medium-sides {
    padding-left: 30px;
    padding-right: 30px;
}
.p-small-sides {
    padding-left: 15px;
    padding-right: 15px;
}
.p-small-vertical {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-medium-all {
    padding: 30px;
}
.p-small-all {
    padding: 15px;
}
