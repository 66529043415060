@import '../../../node_modules/webf/vars-default-theme.scss';
// ici les modifications des variables de webf (surcharge)
@import '../../../node_modules/webf/plugins.scss';

input {
    outline: 0;
    outline: none;

    &[readonly] {
        cursor: not-allowed;
    }
}

textarea[readonly] {
    cursor: not-allowed;
}

.with-addon + .input-group-addon {
    cursor: help;
}

.webf-dialog.dialog-loading {
  width: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  
  .wrap-img-dialog-wait {
    width: 120px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 75px;
  }
}
