/**
 *  Bootstrap pull for Twitter Bootstrap v3.
 *  
 *  To have pull-right and pull-left to Bootstrap v3 for xs, sm, md, lg media.
 *  This will help having some complex behavior for contents.
 *  https://github.com/Natshah/bootstrap-pull
 *
 */

@media (max-width: 767px) {
    /** Pull left xs **/
    .pull-left-xs {
        float: left;
    }

    /** Pull right xs **/
    .pull-right-xs {
        float: right;
    }
}

@media (min-width: 768px) {
    /** Pull left sm **/
    .pull-left-sm {
        float: left;
    }

    /** Pull right sm **/
    .pull-right-sm {
        float: right;
    }
}

@media (min-width: 992px) {
    /** Pull left **/
    .pull-left-md {
        float: left;
    }

    /** Pull right **/
    .pull-right-md {
        float: right;
    }
}

@media (min-width: 1200px) {
    /** Pull left **/
    .pull-left-lg {
        float: left;
    }

    /** Pull right **/
    .pull-right-lg {
        float: right;
    }
}
