footer {
  a {
    color: black;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .bloc-contact-footer {
    h3 {
      font-size: 50px;
      text-align: center;
      color: $bleu-clair;
      font-weight: 700;
    }
    
    form {
      input {
        border: none;
        border-bottom: 1px solid $bleu-fonce;
        outline: none;
        border-radius: 0;
        margin-bottom: 30px;
      }
      
      textarea {
        height: 150px;
        border-color: $bleu-fonce;
        border-radius: 5px;
        margin-top: 50px;
        resize: none;
      }
      
      input:focus,
      textarea:focus {
        box-shadow: none;
        border-color: black;
      }
      
      input::placeholder,
      textarea::placeholder {
        color: #939393  ;
      }
      
      button {
        color: $bleu-clair;
        border: none;
        background-color: white;
        padding: 5px 10px;
        border-radius: 5px;
        
        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
  }
  
  .bloc-informations-contact {
    & > div {
      margin-bottom: 30px;
    }
    
    .adresse {
      background-image: url("../../images/footer/pictogramme-carte.svg");
      background-repeat: no-repeat;
      background-position: center left;
      padding-top: 5px;
    }
    
    .telephone {
      background-image: url("../../images/footer/pictogramme-telephone.svg");
      background-repeat: no-repeat;
      background-position: center left;
      padding-top: 5px;
    }
    
    .email {
      background-image: url("../../images/footer/pictogramme-email.svg");
      background-repeat: no-repeat;
      background-position: center left;
      padding-top: 5px;
    }
    
    .adresse,
    .telephone,
    .email {
      a {
        color: black;
        text-decoration: none;
        padding-left: 40px;
        display: inline-block;
        
        &:hover {
          text-decoration: underline;
        }
      }
      
    }
    
    .acces-espace-client {
      font-size: 1.2em;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  .bloc-informations-legales-acte2i {
    h4 {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
    
    .logos-certifications {
      width: 300px;
    }
    
    .logo-anm-consommation {
      width: 130px;
    }
  }
}

@media (max-width: 568px) {
  footer {
    .bloc-contact-footer {
      h3 {
        font-size: 2.5em;
      }
    }
    
    .bloc-informations-legales-acte2i {
      .logos-certifications {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {
  footer {
    .bloc-informations-contact {
      & > div {
        margin-bottom: 15px;
      }
    }
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  footer {
    .bloc-informations-contact {
      .acces-espace-client {
        font-size: 1em;
      }
    }
  }
}
