@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
.p-no {
  padding: 0 !important;
}

.p-left-no {
  padding-left: 0 !important;
}

.p-right-no {
  padding-right: 0 !important;
}

.p-left {
  padding-left: 15px !important;
}

.p-right {
  padding-right: 15px !important;
}

.m-no {
  margin: 0 !important;
}

.m-left-no {
  margin-left: 0 !important;
}

.m-right-no {
  margin-right: 0 !important;
}

.m-left {
  margin-left: 15px !important;
}

.m-right {
  margin-right: 15px !important;
}

@media (max-width: 767px) {
  .p-no-xs {
    padding: 0 !important;
  }
  .p-left-no-xs {
    padding-left: 0 !important;
  }
  .p-right-no-xs {
    padding-right: 0 !important;
  }
  .p-left-xs {
    padding-left: 15px !important;
  }
  .p-right-xs {
    padding-right: 15px !important;
  }
  .m-no-xs {
    margin: 0 !important;
  }
  .m-left-no-xs {
    margin-left: 0 !important;
  }
  .m-right-no-xs {
    margin-right: 0 !important;
  }
  .m-left-xs {
    margin-left: 15px !important;
  }
  .m-right-xs {
    margin-right: 15px !important;
  }
}
@media (min-width: 768px) {
  .p-no-sm {
    padding: 0 !important;
  }
  .p-left-no-sm {
    padding-left: 0 !important;
  }
  .p-right-no-sm {
    padding-right: 0 !important;
  }
  .p-left-sm {
    padding-left: 15px !important;
  }
  .p-right-sm {
    padding-right: 15px !important;
  }
  .m-no-sm {
    margin: 0 !important;
  }
  .m-left-no-sm {
    margin-left: 0 !important;
  }
  .m-right-no-sm {
    margin-right: 0 !important;
  }
  .m-left-sm {
    margin-left: 15px !important;
  }
  .m-right-sm {
    margin-right: 15px !important;
  }
}
@media (min-width: 992px) {
  .p-no-md {
    padding: 0 !important;
  }
  .p-left-no-md {
    padding-left: 0 !important;
  }
  .p-right-no-md {
    padding-right: 0 !important;
  }
  .p-left-md {
    padding-left: 15px !important;
  }
  .p-right-md {
    padding-right: 15px !important;
  }
  .m-no-md {
    margin: 0 !important;
  }
  .m-left-no-md {
    margin-left: 0 !important;
  }
  .m-right-no-md {
    margin-right: 0 !important;
  }
  .m-left-md {
    margin-left: 15px !important;
  }
  .m-right-md {
    margin-right: 15px !important;
  }
}
@media (min-width: 1200px) {
  .p-no-lg {
    padding: 0 !important;
  }
  .p-left-no-lg {
    padding-left: 0 !important;
  }
  .p-right-no-lg {
    padding-right: 0 !important;
  }
  .p-left-lg {
    padding-left: 15px !important;
  }
  .p-right-lg {
    padding-right: 15px !important;
  }
  .m-no-lg {
    margin: 0 !important;
  }
  .m-left-no-lg {
    margin-left: 0 !important;
  }
  .m-right-no-lg {
    margin-right: 0 !important;
  }
  .m-left-lg {
    margin-left: 15px !important;
  }
  .m-right-lg {
    margin-right: 15px !important;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transition02 {
  transition: 0.2s;
}

.transition05 {
  transition: 0.5s;
}

.transition1 {
  transition: 1s;
}

.m-no {
  margin: 0;
}

.m-big {
  margin-top: 80px;
  margin-bottom: 80px;
}

.m-big-top {
  margin-top: 80px;
}

.m-big-bottom {
  margin-bottom: 80px;
}

.m-medium {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-medium-top {
  margin-top: 30px;
}

.m-medium-bottom {
  margin-bottom: 30px;
}

.m-medium-sides {
  margin-left: 30px;
  margin-right: 30px;
}

.m-small {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-small-top {
  margin-top: 15px;
}

.m-small-bottom {
  margin-bottom: 15px;
}

.m-small-sides {
  margin-left: 15px;
  margin-right: 15px;
}

.p-no {
  padding: 0;
}

.p-big {
  padding-top: 80px;
  padding-bottom: 80px;
}

.p-medium {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-small {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-big-top {
  padding-top: 80px;
}

.p-medium-top {
  padding-top: 30px;
}

.p-small-top {
  padding-top: 15px;
}

.p-big-bottom {
  padding-bottom: 80px;
}

.p-medium-bottom {
  padding-bottom: 30px;
}

.p-small-bottom {
  padding-bottom: 15px;
}

.p-medium-sides {
  padding-left: 30px;
  padding-right: 30px;
}

.p-small-sides {
  padding-left: 15px;
  padding-right: 15px;
}

.p-small-vertical {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-medium-all {
  padding: 30px;
}

.p-small-all {
  padding: 15px;
}
/**
 *  Bootstrap pull for Twitter Bootstrap v3.
 *  
 *  To have pull-right and pull-left to Bootstrap v3 for xs, sm, md, lg media.
 *  This will help having some complex behavior for contents.
 *  https://github.com/Natshah/bootstrap-pull
 *
 */

@media (max-width: 767px) {
    /** Pull left xs **/
    .pull-left-xs {
        float: left;
    }

    /** Pull right xs **/
    .pull-right-xs {
        float: right;
    }
}

@media (min-width: 768px) {
    /** Pull left sm **/
    .pull-left-sm {
        float: left;
    }

    /** Pull right sm **/
    .pull-right-sm {
        float: right;
    }
}

@media (min-width: 992px) {
    /** Pull left **/
    .pull-left-md {
        float: left;
    }

    /** Pull right **/
    .pull-right-md {
        float: right;
    }
}

@media (min-width: 1200px) {
    /** Pull left **/
    .pull-left-lg {
        float: left;
    }

    /** Pull right **/
    .pull-right-lg {
        float: right;
    }
}

@charset "UTF-8";
/*------------ COLORS ------------ */
/*------------ BTN-BORDER ------------ */
/*------------ BTN-SPACING-BOTTOM ------------ */
/*------------ GRADIENT ANGLE ------------ */
/*------------ BTN SIZES ------------ */
.webf-agenda.fwd .top-container .tb-alldays {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
.webf-agenda.fwd .top-container .tb-alldays .td-coldayname {
  background-color: #e3e3e3;
}
.webf-agenda.fwd .top-container .tb-alldays .td-coldayname .dayname {
  text-align: center;
  padding: 4px;
  font-size: 12px;
}
.webf-agenda.fwd .top-container .tb-alldays .td-coldayname .dayname .daylink {
  cursor: pointer;
}
.webf-agenda.fwd .top-container .tb-alldays .td-coldayname .dayname .daylink:hover {
  text-decoration: underline;
}
.webf-agenda.fwd .top-container .tb-alldays .td-padding {
  width: 45px;
  background-color: #e3e3e3;
}
.webf-agenda.fwd .top-container .tb-alldays .td-scrollbar-padding {
  width: 14px;
  background-color: #e3e3e3;
}
.webf-agenda.fwd .top-container .tb-alldays .alldays {
  position: relative;
  min-height: 14px;
}

.webf-agenda.fwd {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  font-family: arial, Helvetica, sans-serif;
}
.webf-agenda.fwd .header {
  height: 26px;
  line-height: 26px;
  padding: 10px;
  background-image: -owg-linear-gradient(white, #dbe2ee);
  background-image: -webkit-linear-gradient(white, #dbe2ee);
  background-image: -moz-linear-gradient(white, #dbe2ee);
  background-image: -o-linear-gradient(white, #dbe2ee);
  background-image: linear-gradient(white, #dbe2ee);
}
.webf-agenda.fwd .header .webf-buttons-group {
  margin: 0 2px;
}
.webf-agenda.fwd .header .left {
  float: left;
  height: 26px;
}
.webf-agenda.fwd .header .right {
  float: right;
  height: 100%;
  line-height: 26px;
}
.webf-agenda.fwd .header .center {
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.webf-agenda.fwd .days {
  padding: 10px 0 5px;
  background: #e5e5e5;
}
.webf-agenda.fwd .days .tb-days {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.webf-agenda.fwd .days .tb-days .td-day a.link-days {
  color: #555;
}
.webf-agenda.fwd .days .tb-days .td-day a.link-days.disabled {
  cursor: default;
}
.webf-agenda.fwd .days .tb-days .td-day a.link-days.disabled:hover {
  text-decoration: none;
}
.webf-agenda.fwd .days .tb-days .td-hours {
  width: 45px;
}
.webf-agenda.fwd .users {
  padding: 2px 0;
  background: #e5e5e5;
}
.webf-agenda.fwd .users .td-hours {
  width: 45px;
}
.webf-agenda.fwd .users .tb-users {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.webf-agenda.fwd .users .tb-users td .user {
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
}
.webf-agenda.fwd .grid {
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  overflow: auto;
}
.webf-agenda.fwd .grid .tb-grid {
  width: 100%;
  table-layout: fixed;
}
.webf-agenda.fwd .grid .tb-grid .td-hours {
  width: 45px;
  background: #e5e5e5;
}
.webf-agenda.fwd .grid .tb-grid .td-hours span.hour {
  display: block;
  float: right;
  margin: 0 5px 0 0;
  font-size: 13px;
}
.webf-agenda.fwd .grid .tb-grid .td-hours .border-bottom {
  position: relative;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ccc;
  font-size: 0;
}
.webf-agenda.fwd .grid .tb-grid .timeslot {
  border-bottom: 1px dotted #ddd;
}
.webf-agenda.fwd .grid .tb-grid .timeslot.hour {
  border-bottom: 1px solid #ccc;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month {
  border: 1px solid #ccc;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month.drop {
  background: #f2f5ff;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day {
  background-color: #e2e9ff;
  background: #f5f5f5;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 16px;
  line-height: 16px;
  text-align: right;
  overflow: hidden;
  color: #aaa;
  font-size: 11px;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day a {
  color: #aaa;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day a.disabled {
  cursor: default;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day a.disabled:hover {
  text-decoration: none;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day span {
  display: block;
  padding-right: 4px;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day.current {
  color: #444;
  font-weight: bold;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .num-day.current a {
  color: #444;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .events {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 18px;
  left: 0;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .events .event {
  color: #fff;
  cursor: pointer;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day .events .event .title {
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .first-row {
  border-top: 0;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .last-row {
  border-bottom: 0;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .first-col {
  border-left: 0;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .last-col {
  border-right: 0;
}
.webf-agenda.fwd .grid .tb-grid .td-day-month .cell-day {
  position: relative;
}
.webf-agenda.fwd .grid .agenda {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.webf-agenda.fwd .grid .agenda .tb-agenda {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-hours {
  width: 45px;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day {
  border-right: 1px solid #808080;
  height: 100%;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day:last-child {
  border-right: 0;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full {
  position: relative;
  height: 100%;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td {
  border-right: 1px dashed #D3D3D3;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td .col-user {
  position: relative;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users:last-child {
  border: 0;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event {
  width: 100%;
  background: #68A1E5;
  color: #fff;
  position: absolute;
  left: 0;
  cursor: pointer;
  overflow: hidden;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event.temporary {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title {
  border: 1px solid #1B62C0;
  min-height: 15px;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  background: #2B72D0;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title.draggable {
  cursor: move;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event .content {
  text-align: center;
  overflow: hidden;
  padding: 3px 3px 0;
  font-size: 13px;
  line-height: 15px;
  height: 100%;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .td-col-day .col-day-full .event .handle-resizable {
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  text-align: center;
  font-size: 9px;
  height: 10px;
  line-height: 8px;
  background: none;
  cursor: s-resize;
}
.webf-agenda.fwd .grid .agenda .tb-agenda .tb-agenda-days {
  width: 100%;
  table-layout: fixed;
}

.webf-agenda-dragging-event .title {
  color: #fff;
  cursor: pointer;
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
}
.webf-agenda-dragging-event .webf-scrollbar {
  right: 2px;
  width: 6px;
}
.webf-agenda-dragging-event .webf-scrollbar a.rail {
  border: 0;
  width: 4px;
  background: none;
}
.webf-agenda-dragging-event .webf-scrollbar a.handler {
  width: 4px;
  background: #555;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.webf-agenda-user {
  position: relative;
}

.webf-agenda-tooltip-user .triangle {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #ccc;
  position: relative;
  top: -6px;
  margin: auto;
  z-index: 10001;
}
.webf-agenda-tooltip-user .triangle .inner-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #fff;
  top: 1px;
  left: -6px;
}
.webf-agenda-tooltip-user .triangle .content {
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  padding: 5px 10px;
  position: relative;
  top: -6px;
  z-index: 10000;
  box-shadow: 2px 2px 5px #ddd;
}

.webf-input-button .webf-label-input-file {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
}
.webf-input-button input[type=file] {
  display: none;
}

.webf-alert-wrapper .webf-alert {
  position: fixed;
  z-index: 100000;
  top: 0;
  margin: 0 10px;
  width: 360px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 8px 0px #888888;
  -moz-box-shadow: 0px 2px 8px 0px #888888;
  box-shadow: 0px 2px 8px 0px #888888;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
}
.webf-alert-wrapper .webf-alert.center {
  justify-self: center;
  left: 50%;
  margin-left: -180px;
}
.webf-alert-wrapper .webf-alert.left {
  left: 0;
}
.webf-alert-wrapper .webf-alert.right {
  right: 0;
}
@media only screen and (max-width: 395px) {
  .webf-alert-wrapper .webf-alert.center, .webf-alert-wrapper .webf-alert.left, .webf-alert-wrapper .webf-alert.right {
    width: auto;
    margin: 0 10px;
    right: 0;
    left: 0;
  }
}
.webf-alert-wrapper .webf-alert .message {
  padding: 15px 30px 15px 15px;
}
.webf-alert-wrapper .webf-alert .message .webf-button {
  line-height: 0;
  position: relative;
  padding: 4px;
  font-size: 0;
  float: right;
  right: -21px;
}
.webf-alert-wrapper .webf-alert .message .webf-button > i.fas {
  vertical-align: middle;
  font-size: 0.75rem;
}
.webf-alert-wrapper .webf-alert.webf-alert-default {
  border-color: #e6e6e6;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: white;
}
.webf-alert-wrapper .webf-alert.webf-alert-primary {
  border-color: #2550d2;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #5477e1;
}
.webf-alert-wrapper .webf-alert.webf-alert-secondary {
  border-color: #cdd7e8;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #f7f9fb;
}
.webf-alert-wrapper .webf-alert.webf-alert-custom {
  border-color: #e6e6e6;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: white;
}
.webf-alert-wrapper .webf-alert.webf-alert-light {
  border-color: #cdd7e8;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #f7f9fb;
}
.webf-alert-wrapper .webf-alert.webf-alert-dark {
  border-color: #3c3c3c;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #5a5a5a;
}
.webf-alert-wrapper .webf-alert.webf-alert-action {
  border-color: #da2064;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #e55288;
}
.webf-alert-wrapper .webf-alert.webf-alert-info {
  border-color: #23a9d9;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #56bee3;
}
.webf-alert-wrapper .webf-alert.webf-alert-success {
  border-color: #49a54e;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #6dbe71;
}
.webf-alert-wrapper .webf-alert.webf-alert-warning {
  border-color: #f4a31c;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #f6bb57;
}
.webf-alert-wrapper .webf-alert.webf-alert-danger {
  border-color: #e82827;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #ee5f5e;
}
.webf-alert-wrapper .webf-alert.webf-alert-lightgrey {
  border-color: gainsboro;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 1px 1px 2px;
  background-color: #fafafa;
}
.webf-list-autocomplete {
  width: auto;
}
.webf-list-autocomplete li.webf-list-item-more {
  height: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.webf-list-autocomplete li.webf-list-item-more > a {
  display: block;
  border: 1px solid transparent;
  text-align: center;
  height: 10px;
  line-height: 10px;
  font-size: 9px;
  cursor: pointer;
}
.webf-list-autocomplete li.webf-list-item-more > a:hover {
  background: #f3f3f3;
  border: 1px solid #ddd;
}
.webf-list-autocomplete li.webf-list-item.webf-list-item-option.enabled.webf-noresult {
  height: auto;
  font-style: italic;
  color: grey;
  text-align: center;
  padding: 5px 10px;
}
.webf-list-autocomplete li.webf-list-item.webf-list-item-option.enabled.webf-noresult:hover a, .webf-list-autocomplete li.webf-list-item.webf-list-item-option.enabled.webf-noresult.webf-focus a {
  border-color: transparent;
  background: none;
}
.webf-list-autocomplete li.webf-list-item.webf-list-item-option.enabled.webf-noresult:hover a:hover, .webf-list-autocomplete li.webf-list-item.webf-list-item-option.enabled.webf-noresult.webf-focus a:hover {
  text-decoration: underline;
}

.webf-md-text .wrapper-webf-aucocomplete {
  height: 28px;
}
.webf-md-text.outlined .wrapper-webf-aucocomplete {
  height: 14px;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.hidden {
  display: none !important;
}

.webf-separator {
  background: #ddd;
  margin: 20px 0;
  height: 1px;
  font-size: 0;
}
.webf-separator.secondary {
  margin: 12px 0;
  background: #eaeaea;
}

.webf-box {
  background-color: white;
  color: #555;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #bbbbbb;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 0.5rem;
}
.webf-box.fold .box-header {
  border-radius: 0.5rem;
}
.webf-box.fold .box-header.drop {
  cursor: pointer;
}
.webf-box.fold .box-body {
  display: none;
}
.webf-box .box-header,
.webf-box .box-body,
.webf-box .box-footer {
  padding: 0.6rem 1rem;
}
.webf-box .box-header {
  background-color: white;
  color: #555;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 1rem;
  font-family: arial, sans-serif;
  border-bottom: 1px solid #dee2e6;
}
.webf-box .box-header > .wrapper {
  min-height: 25px;
  display: flex;
  align-items: center;
  font-size: 1.05rem;
}
.webf-box .box-header > .wrapper > h3 {
  font-size: inherit;
  line-height: inherit;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.webf-box .box-header > .webf-button {
  float: right;
  margin-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.webf-box > .box-body {
  font-size: 0.8rem;
  line-height: 1.3rem;
}
.webf-box > .box-footer {
  border-top: 1px solid #e4e4e4;
}
.webf-box.default {
  border-top-color: white;
}
.webf-box.default.plain {
  border-top-color: #d6d6d6;
}
.webf-box.default.plain > .box-header {
  background-color: white;
  color: #555;
}
.webf-box.default.plain > .box-header > .wrapper > h3 > a {
  background-color: white;
  color: #555;
}
.webf-box.default.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: white;
  color: #555;
}
.webf-box.default.plain > .box-header .webf-button:hover, .webf-box.default.plain > .box-header .webf-button:focus, .webf-box.default.plain > .box-header .webf-button:active, .webf-box.default.plain > .box-header .webf-button.webf-active {
  background-color: #e6e6e6;
}
.webf-box.default.plain > .box-header .webf-button:active, .webf-box.default.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #cccccc;
  -moz-box-shadow: inset 0px 0px 5px 1px #cccccc;
  box-shadow: inset 0px 0px 5px 1px #cccccc;
}
.webf-box.primary {
  border-top-color: #4b70df;
}
.webf-box.primary.plain {
  border-top-color: #2146b8;
}
.webf-box.primary.plain > .box-header {
  background-color: #4b70df;
  color: white;
}
.webf-box.primary.plain > .box-header > .wrapper > h3 > a {
  background-color: #4b70df;
  color: white;
}
.webf-box.primary.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #4b70df;
  color: white;
}
.webf-box.primary.plain > .box-header .webf-button:hover, .webf-box.primary.plain > .box-header .webf-button:focus, .webf-box.primary.plain > .box-header .webf-button:active, .webf-box.primary.plain > .box-header .webf-button.webf-active {
  background-color: #2550d2;
}
.webf-box.primary.plain > .box-header .webf-button:active, .webf-box.primary.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #1e40a6;
  -moz-box-shadow: inset 0px 0px 5px 1px #1e40a6;
  box-shadow: inset 0px 0px 5px 1px #1e40a6;
}
.webf-box.secondary {
  border-top-color: #f0f3f8;
}
.webf-box.secondary.plain {
  border-top-color: #b8c6de;
}
.webf-box.secondary.plain > .box-header {
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.secondary.plain > .box-header > .wrapper > h3 > a {
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.secondary.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.secondary.plain > .box-header .webf-button:hover, .webf-box.secondary.plain > .box-header .webf-button:focus, .webf-box.secondary.plain > .box-header .webf-button:active, .webf-box.secondary.plain > .box-header .webf-button.webf-active {
  background-color: #cdd7e8;
}
.webf-box.secondary.plain > .box-header .webf-button:active, .webf-box.secondary.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #aabbd8;
  -moz-box-shadow: inset 0px 0px 5px 1px #aabbd8;
  box-shadow: inset 0px 0px 5px 1px #aabbd8;
}
.webf-box.custom {
  border-top-color: #fff;
}
.webf-box.custom.plain {
  border-top-color: #d6d6d6;
}
.webf-box.custom.plain > .box-header {
  background-color: #fff;
  color: #555;
}
.webf-box.custom.plain > .box-header > .wrapper > h3 > a {
  background-color: #fff;
  color: #555;
}
.webf-box.custom.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #fff;
  color: #555;
}
.webf-box.custom.plain > .box-header .webf-button:hover, .webf-box.custom.plain > .box-header .webf-button:focus, .webf-box.custom.plain > .box-header .webf-button:active, .webf-box.custom.plain > .box-header .webf-button.webf-active {
  background-color: #e6e6e6;
}
.webf-box.custom.plain > .box-header .webf-button:active, .webf-box.custom.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #cccccc;
  -moz-box-shadow: inset 0px 0px 5px 1px #cccccc;
  box-shadow: inset 0px 0px 5px 1px #cccccc;
}
.webf-box.light {
  border-top-color: #f0f3f8;
}
.webf-box.light.plain {
  border-top-color: #b8c6de;
}
.webf-box.light.plain > .box-header {
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.light.plain > .box-header > .wrapper > h3 > a {
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.light.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #f0f3f8;
  color: #555;
}
.webf-box.light.plain > .box-header .webf-button:hover, .webf-box.light.plain > .box-header .webf-button:focus, .webf-box.light.plain > .box-header .webf-button:active, .webf-box.light.plain > .box-header .webf-button.webf-active {
  background-color: #cdd7e8;
}
.webf-box.light.plain > .box-header .webf-button:active, .webf-box.light.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #aabbd8;
  -moz-box-shadow: inset 0px 0px 5px 1px #aabbd8;
  box-shadow: inset 0px 0px 5px 1px #aabbd8;
}
.webf-box.dark {
  border-top-color: #555;
}
.webf-box.dark.plain {
  border-top-color: #2c2c2c;
}
.webf-box.dark.plain > .box-header {
  background-color: #555;
  color: white;
}
.webf-box.dark.plain > .box-header > .wrapper > h3 > a {
  background-color: #555;
  color: white;
}
.webf-box.dark.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #555;
  color: white;
}
.webf-box.dark.plain > .box-header .webf-button:hover, .webf-box.dark.plain > .box-header .webf-button:focus, .webf-box.dark.plain > .box-header .webf-button:active, .webf-box.dark.plain > .box-header .webf-button.webf-active {
  background-color: #3c3c3c;
}
.webf-box.dark.plain > .box-header .webf-button:active, .webf-box.dark.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #222222;
  -moz-box-shadow: inset 0px 0px 5px 1px #222222;
  box-shadow: inset 0px 0px 5px 1px #222222;
}
.webf-box.action {
  border-top-color: #e44982;
}
.webf-box.action.plain {
  border-top-color: #bf1c58;
}
.webf-box.action.plain > .box-header {
  background-color: #e44982;
  color: white;
}
.webf-box.action.plain > .box-header > .wrapper > h3 > a {
  background-color: #e44982;
  color: white;
}
.webf-box.action.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #e44982;
  color: white;
}
.webf-box.action.plain > .box-header .webf-button:hover, .webf-box.action.plain > .box-header .webf-button:focus, .webf-box.action.plain > .box-header .webf-button:active, .webf-box.action.plain > .box-header .webf-button.webf-active {
  background-color: #da2064;
}
.webf-box.action.plain > .box-header .webf-button:active, .webf-box.action.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #ad1a50;
  -moz-box-shadow: inset 0px 0px 5px 1px #ad1a50;
  box-shadow: inset 0px 0px 5px 1px #ad1a50;
}
.webf-box.info {
  border-top-color: #4dbbe2;
}
.webf-box.info.plain {
  border-top-color: #1f95be;
}
.webf-box.info.plain > .box-header {
  background-color: #4dbbe2;
  color: white;
}
.webf-box.info.plain > .box-header > .wrapper > h3 > a {
  background-color: #4dbbe2;
  color: white;
}
.webf-box.info.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #4dbbe2;
  color: white;
}
.webf-box.info.plain > .box-header .webf-button:hover, .webf-box.info.plain > .box-header .webf-button:focus, .webf-box.info.plain > .box-header .webf-button:active, .webf-box.info.plain > .box-header .webf-button.webf-active {
  background-color: #23a9d9;
}
.webf-box.info.plain > .box-header .webf-button:active, .webf-box.info.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #1c87ad;
  -moz-box-shadow: inset 0px 0px 5px 1px #1c87ad;
  box-shadow: inset 0px 0px 5px 1px #1c87ad;
}
.webf-box.success {
  border-top-color: #66BB6A;
}
.webf-box.success.plain {
  border-top-color: #409044;
}
.webf-box.success.plain > .box-header {
  background-color: #66BB6A;
  color: white;
}
.webf-box.success.plain > .box-header > .wrapper > h3 > a {
  background-color: #66BB6A;
  color: white;
}
.webf-box.success.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #66BB6A;
  color: white;
}
.webf-box.success.plain > .box-header .webf-button:hover, .webf-box.success.plain > .box-header .webf-button:focus, .webf-box.success.plain > .box-header .webf-button:active, .webf-box.success.plain > .box-header .webf-button.webf-active {
  background-color: #49a54e;
}
.webf-box.success.plain > .box-header .webf-button:active, .webf-box.success.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #3a813d;
  -moz-box-shadow: inset 0px 0px 5px 1px #3a813d;
  box-shadow: inset 0px 0px 5px 1px #3a813d;
}
.webf-box.warning {
  border-top-color: #f6b74d;
}
.webf-box.warning.plain {
  border-top-color: #e6940c;
}
.webf-box.warning.plain > .box-header {
  background-color: #f6b74d;
  color: white;
}
.webf-box.warning.plain > .box-header > .wrapper > h3 > a {
  background-color: #f6b74d;
  color: white;
}
.webf-box.warning.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #f6b74d;
  color: white;
}
.webf-box.warning.plain > .box-header .webf-button:hover, .webf-box.warning.plain > .box-header .webf-button:focus, .webf-box.warning.plain > .box-header .webf-button:active, .webf-box.warning.plain > .box-header .webf-button.webf-active {
  background-color: #f4a31c;
}
.webf-box.warning.plain > .box-header .webf-button:active, .webf-box.warning.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #d2880b;
  -moz-box-shadow: inset 0px 0px 5px 1px #d2880b;
  box-shadow: inset 0px 0px 5px 1px #d2880b;
}
.webf-box.danger {
  border-top-color: #ed5655;
}
.webf-box.danger.plain {
  border-top-color: #d91817;
}
.webf-box.danger.plain > .box-header {
  background-color: #ed5655;
  color: white;
}
.webf-box.danger.plain > .box-header > .wrapper > h3 > a {
  background-color: #ed5655;
  color: white;
}
.webf-box.danger.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #ed5655;
  color: white;
}
.webf-box.danger.plain > .box-header .webf-button:hover, .webf-box.danger.plain > .box-header .webf-button:focus, .webf-box.danger.plain > .box-header .webf-button:active, .webf-box.danger.plain > .box-header .webf-button.webf-active {
  background-color: #e82827;
}
.webf-box.danger.plain > .box-header .webf-button:active, .webf-box.danger.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #c71615;
  -moz-box-shadow: inset 0px 0px 5px 1px #c71615;
  box-shadow: inset 0px 0px 5px 1px #c71615;
}
.webf-box.lightgrey {
  border-top-color: #f5f5f5;
}
.webf-box.lightgrey.plain {
  border-top-color: #cccccc;
}
.webf-box.lightgrey.plain > .box-header {
  background-color: #f5f5f5;
  color: #555;
}
.webf-box.lightgrey.plain > .box-header > .wrapper > h3 > a {
  background-color: #f5f5f5;
  color: #555;
}
.webf-box.lightgrey.plain > .box-header .webf-button {
  border-color: transparent;
  background-color: #f5f5f5;
  color: #555;
}
.webf-box.lightgrey.plain > .box-header .webf-button:hover, .webf-box.lightgrey.plain > .box-header .webf-button:focus, .webf-box.lightgrey.plain > .box-header .webf-button:active, .webf-box.lightgrey.plain > .box-header .webf-button.webf-active {
  background-color: gainsboro;
}
.webf-box.lightgrey.plain > .box-header .webf-button:active, .webf-box.lightgrey.plain > .box-header .webf-button.webf-active {
  -webkit-box-shadow: inset 0px 0px 5px 1px #c2c2c2;
  -moz-box-shadow: inset 0px 0px 5px 1px #c2c2c2;
  box-shadow: inset 0px 0px 5px 1px #c2c2c2;
}
.webf-box.small {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.webf-box.small .box-header {
  padding: 0.2rem 1rem;
}
.webf-box.small .box-header > .wrapper {
  font-size: 0.8rem;
  min-height: 19px;
}
.webf-box.small .box-header > .wrapper > h3 > a {
  color: #555;
}
.webf-box.small .box-header > .webf-button {
  margin-left: 1px;
  padding: 1px 6px;
  font-size: 12px;
}
.webf-box.xsmall {
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.04) !important;
}
.webf-box.xsmall .box-header {
  padding: 0.1rem 1rem;
}
.webf-box.xsmall .box-header > .wrapper {
  font-size: 0.7rem;
  min-height: 17px;
}
.webf-box.xsmall .box-header > .wrapper > h3 > a {
  color: #555;
}
.webf-box.xsmall .box-header > .webf-button {
  margin-left: 1px;
  padding: 0 5px;
  font-size: 10px;
}

.webf-button {
  background-image: none;
  background-color: white;
  border-color: #d5d5d5;
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
  text-align: center;
  text-decoration: none;
  outline: 0;
  vertical-align: middle;
  transition: color 300ms ease-in-out, background-position 300ms ease-in-out, background-color 230ms ease-in-out, border-color 230ms ease-in-out, box-shadow 100ms ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webf-button:focus {
  color: inherit;
  outline: 0;
}
.webf-button:hover {
  color: inherit;
  text-decoration: inherit;
  background-color: rgba(85, 85, 85, 0.07);
}
.webf-button:active, .webf-button.webf-active, .webf-button.webf-state-active {
  color: inherit;
  background-color: rgba(85, 85, 85, 0.14);
}
.webf-button.webf-button-default, .webf-button.webf-button-default.webf-normal, .webf-button.default, .webf-button.default.webf-normal {
  background-color: white;
  color: #555;
  border-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-default:not(.muted):not(.disabled):hover, .webf-button.webf-button-default:not(.muted):not(.disabled):active, .webf-button.webf-button-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-default:not(.muted):not(.disabled).webf-state-active, .webf-button.default:not(.muted):not(.disabled):hover, .webf-button.default:not(.muted):not(.disabled):active, .webf-button.default:not(.muted):not(.disabled).webf-active, .webf-button.default:not(.muted):not(.disabled).webf-state-active {
  color: #555;
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}
.webf-button.webf-button-default:not(.muted):not(.disabled):active, .webf-button.webf-button-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-default:not(.muted):not(.disabled).webf-state-active, .webf-button.default:not(.muted):not(.disabled):active, .webf-button.default:not(.muted):not(.disabled).webf-active, .webf-button.default:not(.muted):not(.disabled).webf-state-active {
  background-color: #cccccc;
  border-color: #cccccc;
}
.webf-button.webf-button-default.webf-button-colored-shadow, .webf-button.webf-button-default.colored-shadow, .webf-button.default.webf-button-colored-shadow, .webf-button.default.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-default.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.default.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-default:not(.disabled):visited, .webf-button.webf-button-default:not(.disabled):focus, .webf-button.default:not(.disabled):visited, .webf-button.default:not(.disabled):focus {
  box-shadow: none;
  color: #555;
}
.webf-button.webf-button-default .webf-button-caret.webf-button-caret-down, .webf-button.default .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-default .webf-button-caret.webf-button-caret-left, .webf-button.default .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-default .webf-button-caret.webf-button-caret-right, .webf-button.default .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-default .webf-button-caret.webf-button-caret-up, .webf-button.default .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-text-default, .webf-button.text-default {
  border-color: transparent;
  color: white;
}
.webf-button.webf-button-text-default:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-default:not(.muted):not(.disabled).webf-state-active, .webf-button.text-default:not(.muted):not(.disabled):hover, .webf-button.text-default:not(.muted):not(.disabled).webf-active, .webf-button.text-default:not(.muted):not(.disabled).webf-state-active {
  color: #e6e6e6;
  background-color: rgba(255, 255, 255, 0.07);
}
.webf-button.webf-button-text-default:not(.muted):not(.disabled):active, .webf-button.webf-button-text-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-default:not(.muted):not(.disabled).webf-state-active, .webf-button.text-default:not(.muted):not(.disabled):active, .webf-button.text-default:not(.muted):not(.disabled).webf-active, .webf-button.text-default:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.14);
}
.webf-button.webf-button-text-default .webf-button-caret.webf-button-caret-down, .webf-button.text-default .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-text-default .webf-button-caret.webf-button-caret-left, .webf-button.text-default .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-text-default .webf-button-caret.webf-button-caret-right, .webf-button.text-default .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-text-default .webf-button-caret.webf-button-caret-up, .webf-button.text-default .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-outline-default, .webf-button.outline-default {
  color: white;
  border-color: white;
}
.webf-button.webf-button-outline-default:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-default:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-default:not(.muted):not(.disabled):hover, .webf-button.outline-default:not(.muted):not(.disabled).webf-active, .webf-button.outline-default:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.07);
  color: #e6e6e6;
}
.webf-button.webf-button-outline-default:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-default:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-default:not(.muted):not(.disabled):active, .webf-button.outline-default:not(.muted):not(.disabled).webf-active, .webf-button.outline-default:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.14);
}
.webf-button.webf-button-outline-default.webf-button-colored-shadow, .webf-button.webf-button-outline-default.colored-shadow, .webf-button.outline-default.webf-button-colored-shadow, .webf-button.outline-default.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-outline-default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-default.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-default.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-outline-default .webf-button-caret.webf-button-caret-down, .webf-button.outline-default .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-outline-default .webf-button-caret.webf-button-caret-left, .webf-button.outline-default .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-outline-default .webf-button-caret.webf-button-caret-right, .webf-button.outline-default .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-outline-default .webf-button-caret.webf-button-caret-up, .webf-button.outline-default .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-gradient-default, .webf-button.gradient-default {
  background: white linear-gradient(60deg, #d9d9d9 0%, white 50%, #eee 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: #555;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-default:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-default:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-default:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-default:not(.muted):not(.disabled):hover, .webf-button.gradient-default:not(.muted):not(.disabled).webf-active, .webf-button.gradient-default:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-default.webf-button-colored-shadow, .webf-button.webf-button-gradient-default.colored-shadow, .webf-button.gradient-default.webf-button-colored-shadow, .webf-button.gradient-default.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-default.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-default.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-default.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-default.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-default.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-primary, .webf-button.webf-button-primary.webf-normal, .webf-button.primary, .webf-button.primary.webf-normal {
  background-color: #4b70df;
  color: white;
  border-color: #4b70df;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-primary:not(.muted):not(.disabled):hover, .webf-button.webf-button-primary:not(.muted):not(.disabled):active, .webf-button.webf-button-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.primary:not(.muted):not(.disabled):hover, .webf-button.primary:not(.muted):not(.disabled):active, .webf-button.primary:not(.muted):not(.disabled).webf-active, .webf-button.primary:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #2550d2;
  background-color: #2550d2;
}
.webf-button.webf-button-primary:not(.muted):not(.disabled):active, .webf-button.webf-button-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.primary:not(.muted):not(.disabled):active, .webf-button.primary:not(.muted):not(.disabled).webf-active, .webf-button.primary:not(.muted):not(.disabled).webf-state-active {
  background-color: #1e40a6;
  border-color: #1e40a6;
}
.webf-button.webf-button-primary.webf-button-colored-shadow, .webf-button.webf-button-primary.colored-shadow, .webf-button.primary.webf-button-colored-shadow, .webf-button.primary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 1rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-primary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.primary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 3rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-primary:not(.disabled):visited, .webf-button.webf-button-primary:not(.disabled):focus, .webf-button.primary:not(.disabled):visited, .webf-button.primary:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-primary .webf-button-caret.webf-button-caret-down, .webf-button.primary .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-primary .webf-button-caret.webf-button-caret-left, .webf-button.primary .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-primary .webf-button-caret.webf-button-caret-right, .webf-button.primary .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-primary .webf-button-caret.webf-button-caret-up, .webf-button.primary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-primary, .webf-button.text-primary {
  border-color: transparent;
  color: #4b70df;
}
.webf-button.webf-button-text-primary:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.text-primary:not(.muted):not(.disabled):hover, .webf-button.text-primary:not(.muted):not(.disabled).webf-active, .webf-button.text-primary:not(.muted):not(.disabled).webf-state-active {
  color: #2550d2;
  background-color: rgba(75, 112, 223, 0.07);
}
.webf-button.webf-button-text-primary:not(.muted):not(.disabled):active, .webf-button.webf-button-text-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.text-primary:not(.muted):not(.disabled):active, .webf-button.text-primary:not(.muted):not(.disabled).webf-active, .webf-button.text-primary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(75, 112, 223, 0.14);
}
.webf-button.webf-button-text-primary .webf-button-caret.webf-button-caret-down, .webf-button.text-primary .webf-button-caret.webf-button-caret-down {
  border-top-color: #4b70df;
}
.webf-button.webf-button-text-primary .webf-button-caret.webf-button-caret-left, .webf-button.text-primary .webf-button-caret.webf-button-caret-left {
  border-right-color: #4b70df;
}
.webf-button.webf-button-text-primary .webf-button-caret.webf-button-caret-right, .webf-button.text-primary .webf-button-caret.webf-button-caret-right {
  border-left-color: #4b70df;
}
.webf-button.webf-button-text-primary .webf-button-caret.webf-button-caret-up, .webf-button.text-primary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #4b70df;
}
.webf-button.webf-button-outline-primary, .webf-button.outline-primary {
  color: #4b70df;
  border-color: #4b70df;
}
.webf-button.webf-button-outline-primary:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-primary:not(.muted):not(.disabled):hover, .webf-button.outline-primary:not(.muted):not(.disabled).webf-active, .webf-button.outline-primary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(75, 112, 223, 0.07);
  color: #2550d2;
}
.webf-button.webf-button-outline-primary:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-primary:not(.muted):not(.disabled):active, .webf-button.outline-primary:not(.muted):not(.disabled).webf-active, .webf-button.outline-primary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(75, 112, 223, 0.14);
}
.webf-button.webf-button-outline-primary.webf-button-colored-shadow, .webf-button.webf-button-outline-primary.colored-shadow, .webf-button.outline-primary.webf-button-colored-shadow, .webf-button.outline-primary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 1rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-primary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-primary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 3rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-outline-primary .webf-button-caret.webf-button-caret-down, .webf-button.outline-primary .webf-button-caret.webf-button-caret-down {
  border-top-color: #4b70df;
}
.webf-button.webf-button-outline-primary .webf-button-caret.webf-button-caret-left, .webf-button.outline-primary .webf-button-caret.webf-button-caret-left {
  border-right-color: #4b70df;
}
.webf-button.webf-button-outline-primary .webf-button-caret.webf-button-caret-right, .webf-button.outline-primary .webf-button-caret.webf-button-caret-right {
  border-left-color: #4b70df;
}
.webf-button.webf-button-outline-primary .webf-button-caret.webf-button-caret-up, .webf-button.outline-primary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #4b70df;
}
.webf-button.webf-button-gradient-primary, .webf-button.gradient-primary {
  background: #4b70df linear-gradient(60deg, #2148bc 0%, #4b70df 50%, #859dde 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-primary:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-primary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-primary:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-primary:not(.muted):not(.disabled):hover, .webf-button.gradient-primary:not(.muted):not(.disabled).webf-active, .webf-button.gradient-primary:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-primary.webf-button-colored-shadow, .webf-button.webf-button-gradient-primary.colored-shadow, .webf-button.gradient-primary.webf-button-colored-shadow, .webf-button.gradient-primary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 1rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-primary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-primary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-primary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-primary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-primary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(75, 112, 223, 0.15), 0 0.7rem 3rem -0.5rem rgba(75, 112, 223, 0.4);
}
.webf-button.webf-button-secondary, .webf-button.webf-button-secondary.webf-normal, .webf-button.secondary, .webf-button.secondary.webf-normal {
  background-color: #f0f3f8;
  color: #555;
  border-color: #f0f3f8;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-secondary:not(.muted):not(.disabled):hover, .webf-button.webf-button-secondary:not(.muted):not(.disabled):active, .webf-button.webf-button-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.secondary:not(.muted):not(.disabled):hover, .webf-button.secondary:not(.muted):not(.disabled):active, .webf-button.secondary:not(.muted):not(.disabled).webf-active, .webf-button.secondary:not(.muted):not(.disabled).webf-state-active {
  color: #555;
  border-color: #cdd7e8;
  background-color: #cdd7e8;
}
.webf-button.webf-button-secondary:not(.muted):not(.disabled):active, .webf-button.webf-button-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.secondary:not(.muted):not(.disabled):active, .webf-button.secondary:not(.muted):not(.disabled).webf-active, .webf-button.secondary:not(.muted):not(.disabled).webf-state-active {
  background-color: #aabbd8;
  border-color: #aabbd8;
}
.webf-button.webf-button-secondary.webf-button-colored-shadow, .webf-button.webf-button-secondary.colored-shadow, .webf-button.secondary.webf-button-colored-shadow, .webf-button.secondary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-secondary:not(.disabled):visited, .webf-button.webf-button-secondary:not(.disabled):focus, .webf-button.secondary:not(.disabled):visited, .webf-button.secondary:not(.disabled):focus {
  box-shadow: none;
  color: #555;
}
.webf-button.webf-button-secondary .webf-button-caret.webf-button-caret-down, .webf-button.secondary .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-secondary .webf-button-caret.webf-button-caret-left, .webf-button.secondary .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-secondary .webf-button-caret.webf-button-caret-right, .webf-button.secondary .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-secondary .webf-button-caret.webf-button-caret-up, .webf-button.secondary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-text-secondary, .webf-button.text-secondary {
  border-color: transparent;
  color: #f0f3f8;
}
.webf-button.webf-button-text-secondary:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.text-secondary:not(.muted):not(.disabled):hover, .webf-button.text-secondary:not(.muted):not(.disabled).webf-active, .webf-button.text-secondary:not(.muted):not(.disabled).webf-state-active {
  color: #cdd7e8;
  background-color: rgba(240, 243, 248, 0.07);
}
.webf-button.webf-button-text-secondary:not(.muted):not(.disabled):active, .webf-button.webf-button-text-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.text-secondary:not(.muted):not(.disabled):active, .webf-button.text-secondary:not(.muted):not(.disabled).webf-active, .webf-button.text-secondary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.14);
}
.webf-button.webf-button-text-secondary .webf-button-caret.webf-button-caret-down, .webf-button.text-secondary .webf-button-caret.webf-button-caret-down {
  border-top-color: #f0f3f8;
}
.webf-button.webf-button-text-secondary .webf-button-caret.webf-button-caret-left, .webf-button.text-secondary .webf-button-caret.webf-button-caret-left {
  border-right-color: #f0f3f8;
}
.webf-button.webf-button-text-secondary .webf-button-caret.webf-button-caret-right, .webf-button.text-secondary .webf-button-caret.webf-button-caret-right {
  border-left-color: #f0f3f8;
}
.webf-button.webf-button-text-secondary .webf-button-caret.webf-button-caret-up, .webf-button.text-secondary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f0f3f8;
}
.webf-button.webf-button-outline-secondary, .webf-button.outline-secondary {
  color: #f0f3f8;
  border-color: #f0f3f8;
}
.webf-button.webf-button-outline-secondary:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-secondary:not(.muted):not(.disabled):hover, .webf-button.outline-secondary:not(.muted):not(.disabled).webf-active, .webf-button.outline-secondary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.07);
  color: #cdd7e8;
}
.webf-button.webf-button-outline-secondary:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-secondary:not(.muted):not(.disabled):active, .webf-button.outline-secondary:not(.muted):not(.disabled).webf-active, .webf-button.outline-secondary:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.14);
}
.webf-button.webf-button-outline-secondary.webf-button-colored-shadow, .webf-button.webf-button-outline-secondary.colored-shadow, .webf-button.outline-secondary.webf-button-colored-shadow, .webf-button.outline-secondary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-outline-secondary .webf-button-caret.webf-button-caret-down, .webf-button.outline-secondary .webf-button-caret.webf-button-caret-down {
  border-top-color: #f0f3f8;
}
.webf-button.webf-button-outline-secondary .webf-button-caret.webf-button-caret-left, .webf-button.outline-secondary .webf-button-caret.webf-button-caret-left {
  border-right-color: #f0f3f8;
}
.webf-button.webf-button-outline-secondary .webf-button-caret.webf-button-caret-right, .webf-button.outline-secondary .webf-button-caret.webf-button-caret-right {
  border-left-color: #f0f3f8;
}
.webf-button.webf-button-outline-secondary .webf-button-caret.webf-button-caret-up, .webf-button.outline-secondary .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f0f3f8;
}
.webf-button.webf-button-gradient-secondary, .webf-button.gradient-secondary {
  background: #f0f3f8 linear-gradient(60deg, #bcc9e0 0%, #f0f3f8 50%, #f0f3f8 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: #555;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-secondary:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-secondary:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-secondary:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-secondary:not(.muted):not(.disabled):hover, .webf-button.gradient-secondary:not(.muted):not(.disabled).webf-active, .webf-button.gradient-secondary:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-secondary.webf-button-colored-shadow, .webf-button.webf-button-gradient-secondary.colored-shadow, .webf-button.gradient-secondary.webf-button-colored-shadow, .webf-button.gradient-secondary.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-secondary.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-secondary.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-secondary.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-secondary.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-custom, .webf-button.webf-button-custom.webf-normal, .webf-button.custom, .webf-button.custom.webf-normal {
  background-color: #fff;
  color: #555;
  border-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-custom:not(.muted):not(.disabled):hover, .webf-button.webf-button-custom:not(.muted):not(.disabled):active, .webf-button.webf-button-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.custom:not(.muted):not(.disabled):hover, .webf-button.custom:not(.muted):not(.disabled):active, .webf-button.custom:not(.muted):not(.disabled).webf-active, .webf-button.custom:not(.muted):not(.disabled).webf-state-active {
  color: #555;
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}
.webf-button.webf-button-custom:not(.muted):not(.disabled):active, .webf-button.webf-button-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.custom:not(.muted):not(.disabled):active, .webf-button.custom:not(.muted):not(.disabled).webf-active, .webf-button.custom:not(.muted):not(.disabled).webf-state-active {
  background-color: #cccccc;
  border-color: #cccccc;
}
.webf-button.webf-button-custom.webf-button-colored-shadow, .webf-button.webf-button-custom.colored-shadow, .webf-button.custom.webf-button-colored-shadow, .webf-button.custom.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-custom.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.custom.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-custom:not(.disabled):visited, .webf-button.webf-button-custom:not(.disabled):focus, .webf-button.custom:not(.disabled):visited, .webf-button.custom:not(.disabled):focus {
  box-shadow: none;
  color: #555;
}
.webf-button.webf-button-custom .webf-button-caret.webf-button-caret-down, .webf-button.custom .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-custom .webf-button-caret.webf-button-caret-left, .webf-button.custom .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-custom .webf-button-caret.webf-button-caret-right, .webf-button.custom .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-custom .webf-button-caret.webf-button-caret-up, .webf-button.custom .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-text-custom, .webf-button.text-custom {
  border-color: transparent;
  color: #fff;
}
.webf-button.webf-button-text-custom:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.text-custom:not(.muted):not(.disabled):hover, .webf-button.text-custom:not(.muted):not(.disabled).webf-active, .webf-button.text-custom:not(.muted):not(.disabled).webf-state-active {
  color: #e6e6e6;
  background-color: rgba(255, 255, 255, 0.07);
}
.webf-button.webf-button-text-custom:not(.muted):not(.disabled):active, .webf-button.webf-button-text-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.text-custom:not(.muted):not(.disabled):active, .webf-button.text-custom:not(.muted):not(.disabled).webf-active, .webf-button.text-custom:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.14);
}
.webf-button.webf-button-text-custom .webf-button-caret.webf-button-caret-down, .webf-button.text-custom .webf-button-caret.webf-button-caret-down {
  border-top-color: #fff;
}
.webf-button.webf-button-text-custom .webf-button-caret.webf-button-caret-left, .webf-button.text-custom .webf-button-caret.webf-button-caret-left {
  border-right-color: #fff;
}
.webf-button.webf-button-text-custom .webf-button-caret.webf-button-caret-right, .webf-button.text-custom .webf-button-caret.webf-button-caret-right {
  border-left-color: #fff;
}
.webf-button.webf-button-text-custom .webf-button-caret.webf-button-caret-up, .webf-button.text-custom .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #fff;
}
.webf-button.webf-button-outline-custom, .webf-button.outline-custom {
  color: #fff;
  border-color: #fff;
}
.webf-button.webf-button-outline-custom:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-custom:not(.muted):not(.disabled):hover, .webf-button.outline-custom:not(.muted):not(.disabled).webf-active, .webf-button.outline-custom:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.07);
  color: #e6e6e6;
}
.webf-button.webf-button-outline-custom:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-custom:not(.muted):not(.disabled):active, .webf-button.outline-custom:not(.muted):not(.disabled).webf-active, .webf-button.outline-custom:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(255, 255, 255, 0.14);
}
.webf-button.webf-button-outline-custom.webf-button-colored-shadow, .webf-button.webf-button-outline-custom.colored-shadow, .webf-button.outline-custom.webf-button-colored-shadow, .webf-button.outline-custom.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-custom.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-custom.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-outline-custom .webf-button-caret.webf-button-caret-down, .webf-button.outline-custom .webf-button-caret.webf-button-caret-down {
  border-top-color: #fff;
}
.webf-button.webf-button-outline-custom .webf-button-caret.webf-button-caret-left, .webf-button.outline-custom .webf-button-caret.webf-button-caret-left {
  border-right-color: #fff;
}
.webf-button.webf-button-outline-custom .webf-button-caret.webf-button-caret-right, .webf-button.outline-custom .webf-button-caret.webf-button-caret-right {
  border-left-color: #fff;
}
.webf-button.webf-button-outline-custom .webf-button-caret.webf-button-caret-up, .webf-button.outline-custom .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #fff;
}
.webf-button.webf-button-gradient-custom, .webf-button.gradient-custom {
  background: #fff linear-gradient(60deg, #d9d9d9 0%, #fff 50%, #fff 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: #555;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-custom:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-custom:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-custom:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-custom:not(.muted):not(.disabled):hover, .webf-button.gradient-custom:not(.muted):not(.disabled).webf-active, .webf-button.gradient-custom:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-custom.webf-button-colored-shadow, .webf-button.webf-button-gradient-custom.colored-shadow, .webf-button.gradient-custom.webf-button-colored-shadow, .webf-button.gradient-custom.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 1rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-custom.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-custom.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-custom.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-custom.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-custom.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(255, 255, 255, 0.15), 0 0.7rem 3rem -0.5rem rgba(255, 255, 255, 0.4);
}
.webf-button.webf-button-light, .webf-button.webf-button-light.webf-normal, .webf-button.light, .webf-button.light.webf-normal {
  background-color: #f0f3f8;
  color: #555;
  border-color: #f0f3f8;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-light:not(.muted):not(.disabled):hover, .webf-button.webf-button-light:not(.muted):not(.disabled):active, .webf-button.webf-button-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-light:not(.muted):not(.disabled).webf-state-active, .webf-button.light:not(.muted):not(.disabled):hover, .webf-button.light:not(.muted):not(.disabled):active, .webf-button.light:not(.muted):not(.disabled).webf-active, .webf-button.light:not(.muted):not(.disabled).webf-state-active {
  color: #555;
  border-color: #e6ebf3;
  background-color: #e6ebf3;
}
.webf-button.webf-button-light:not(.muted):not(.disabled):active, .webf-button.webf-button-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-light:not(.muted):not(.disabled).webf-state-active, .webf-button.light:not(.muted):not(.disabled):active, .webf-button.light:not(.muted):not(.disabled).webf-active, .webf-button.light:not(.muted):not(.disabled).webf-state-active {
  background-color: #c3cfe3;
  border-color: #c3cfe3;
}
.webf-button.webf-button-light.webf-button-colored-shadow, .webf-button.webf-button-light.colored-shadow, .webf-button.light.webf-button-colored-shadow, .webf-button.light.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-light.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.light.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-light:not(.disabled):visited, .webf-button.webf-button-light:not(.disabled):focus, .webf-button.light:not(.disabled):visited, .webf-button.light:not(.disabled):focus {
  box-shadow: none;
  color: #555;
}
.webf-button.webf-button-light .webf-button-caret.webf-button-caret-down, .webf-button.light .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-light .webf-button-caret.webf-button-caret-left, .webf-button.light .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-light .webf-button-caret.webf-button-caret-right, .webf-button.light .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-light .webf-button-caret.webf-button-caret-up, .webf-button.light .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-text-light, .webf-button.text-light {
  border-color: transparent;
  color: #f0f3f8;
}
.webf-button.webf-button-text-light:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-light:not(.muted):not(.disabled).webf-state-active, .webf-button.text-light:not(.muted):not(.disabled):hover, .webf-button.text-light:not(.muted):not(.disabled).webf-active, .webf-button.text-light:not(.muted):not(.disabled).webf-state-active {
  color: #cdd7e8;
  background-color: rgba(240, 243, 248, 0.07);
}
.webf-button.webf-button-text-light:not(.muted):not(.disabled):active, .webf-button.webf-button-text-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-light:not(.muted):not(.disabled).webf-state-active, .webf-button.text-light:not(.muted):not(.disabled):active, .webf-button.text-light:not(.muted):not(.disabled).webf-active, .webf-button.text-light:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.14);
}
.webf-button.webf-button-text-light .webf-button-caret.webf-button-caret-down, .webf-button.text-light .webf-button-caret.webf-button-caret-down {
  border-top-color: #f0f3f8;
}
.webf-button.webf-button-text-light .webf-button-caret.webf-button-caret-left, .webf-button.text-light .webf-button-caret.webf-button-caret-left {
  border-right-color: #f0f3f8;
}
.webf-button.webf-button-text-light .webf-button-caret.webf-button-caret-right, .webf-button.text-light .webf-button-caret.webf-button-caret-right {
  border-left-color: #f0f3f8;
}
.webf-button.webf-button-text-light .webf-button-caret.webf-button-caret-up, .webf-button.text-light .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f0f3f8;
}
.webf-button.webf-button-outline-light, .webf-button.outline-light {
  color: #f0f3f8;
  border-color: #f0f3f8;
}
.webf-button.webf-button-outline-light:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-light:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-light:not(.muted):not(.disabled):hover, .webf-button.outline-light:not(.muted):not(.disabled).webf-active, .webf-button.outline-light:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.07);
  color: #cdd7e8;
}
.webf-button.webf-button-outline-light:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-light:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-light:not(.muted):not(.disabled):active, .webf-button.outline-light:not(.muted):not(.disabled).webf-active, .webf-button.outline-light:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(240, 243, 248, 0.14);
}
.webf-button.webf-button-outline-light.webf-button-colored-shadow, .webf-button.webf-button-outline-light.colored-shadow, .webf-button.outline-light.webf-button-colored-shadow, .webf-button.outline-light.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-outline-light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-light.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-light.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-outline-light .webf-button-caret.webf-button-caret-down, .webf-button.outline-light .webf-button-caret.webf-button-caret-down {
  border-top-color: #f0f3f8;
}
.webf-button.webf-button-outline-light .webf-button-caret.webf-button-caret-left, .webf-button.outline-light .webf-button-caret.webf-button-caret-left {
  border-right-color: #f0f3f8;
}
.webf-button.webf-button-outline-light .webf-button-caret.webf-button-caret-right, .webf-button.outline-light .webf-button-caret.webf-button-caret-right {
  border-left-color: #f0f3f8;
}
.webf-button.webf-button-outline-light .webf-button-caret.webf-button-caret-up, .webf-button.outline-light .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f0f3f8;
}
.webf-button.webf-button-gradient-light, .webf-button.gradient-light {
  background: #f0f3f8 linear-gradient(60deg, #d4ddeb 0%, #f0f3f8 50%, #fff 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: #555;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-light:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-light:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-light:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-light:not(.muted):not(.disabled):hover, .webf-button.gradient-light:not(.muted):not(.disabled).webf-active, .webf-button.gradient-light:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-light.webf-button-colored-shadow, .webf-button.webf-button-gradient-light.colored-shadow, .webf-button.gradient-light.webf-button-colored-shadow, .webf-button.gradient-light.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 1rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-light.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-light.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-light.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-light.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-light.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(240, 243, 248, 0.15), 0 0.7rem 3rem -0.5rem rgba(240, 243, 248, 0.4);
}
.webf-button.webf-button-dark, .webf-button.webf-button-dark.webf-normal, .webf-button.dark, .webf-button.dark.webf-normal {
  background-color: #555;
  color: white;
  border-color: #555;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-dark:not(.muted):not(.disabled):hover, .webf-button.webf-button-dark:not(.muted):not(.disabled):active, .webf-button.webf-button-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.dark:not(.muted):not(.disabled):hover, .webf-button.dark:not(.muted):not(.disabled):active, .webf-button.dark:not(.muted):not(.disabled).webf-active, .webf-button.dark:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #3c3c3c;
  background-color: #3c3c3c;
}
.webf-button.webf-button-dark:not(.muted):not(.disabled):active, .webf-button.webf-button-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.dark:not(.muted):not(.disabled):active, .webf-button.dark:not(.muted):not(.disabled).webf-active, .webf-button.dark:not(.muted):not(.disabled).webf-state-active {
  background-color: #222222;
  border-color: #222222;
}
.webf-button.webf-button-dark.webf-button-colored-shadow, .webf-button.webf-button-dark.colored-shadow, .webf-button.dark.webf-button-colored-shadow, .webf-button.dark.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-dark.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.dark.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-dark:not(.disabled):visited, .webf-button.webf-button-dark:not(.disabled):focus, .webf-button.dark:not(.disabled):visited, .webf-button.dark:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-dark .webf-button-caret.webf-button-caret-down, .webf-button.dark .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-dark .webf-button-caret.webf-button-caret-left, .webf-button.dark .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-dark .webf-button-caret.webf-button-caret-right, .webf-button.dark .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-dark .webf-button-caret.webf-button-caret-up, .webf-button.dark .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-dark, .webf-button.text-dark {
  border-color: transparent;
  color: #555;
}
.webf-button.webf-button-text-dark:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.text-dark:not(.muted):not(.disabled):hover, .webf-button.text-dark:not(.muted):not(.disabled).webf-active, .webf-button.text-dark:not(.muted):not(.disabled).webf-state-active {
  color: #3c3c3c;
  background-color: rgba(85, 85, 85, 0.07);
}
.webf-button.webf-button-text-dark:not(.muted):not(.disabled):active, .webf-button.webf-button-text-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.text-dark:not(.muted):not(.disabled):active, .webf-button.text-dark:not(.muted):not(.disabled).webf-active, .webf-button.text-dark:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(85, 85, 85, 0.14);
}
.webf-button.webf-button-text-dark .webf-button-caret.webf-button-caret-down, .webf-button.text-dark .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-text-dark .webf-button-caret.webf-button-caret-left, .webf-button.text-dark .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-text-dark .webf-button-caret.webf-button-caret-right, .webf-button.text-dark .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-text-dark .webf-button-caret.webf-button-caret-up, .webf-button.text-dark .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-outline-dark, .webf-button.outline-dark {
  color: #555;
  border-color: #555;
}
.webf-button.webf-button-outline-dark:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-dark:not(.muted):not(.disabled):hover, .webf-button.outline-dark:not(.muted):not(.disabled).webf-active, .webf-button.outline-dark:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(85, 85, 85, 0.07);
  color: #3c3c3c;
}
.webf-button.webf-button-outline-dark:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-dark:not(.muted):not(.disabled):active, .webf-button.outline-dark:not(.muted):not(.disabled).webf-active, .webf-button.outline-dark:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(85, 85, 85, 0.14);
}
.webf-button.webf-button-outline-dark.webf-button-colored-shadow, .webf-button.webf-button-outline-dark.colored-shadow, .webf-button.outline-dark.webf-button-colored-shadow, .webf-button.outline-dark.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-dark.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-dark.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-outline-dark .webf-button-caret.webf-button-caret-down, .webf-button.outline-dark .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-outline-dark .webf-button-caret.webf-button-caret-left, .webf-button.outline-dark .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-outline-dark .webf-button-caret.webf-button-caret-right, .webf-button.outline-dark .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-outline-dark .webf-button-caret.webf-button-caret-up, .webf-button.outline-dark .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-gradient-dark, .webf-button.gradient-dark {
  background: #555 linear-gradient(60deg, #2f2f2f 0%, #555 50%, #929292 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-dark:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-dark:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-dark:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-dark:not(.muted):not(.disabled):hover, .webf-button.gradient-dark:not(.muted):not(.disabled).webf-active, .webf-button.gradient-dark:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-dark.webf-button-colored-shadow, .webf-button.webf-button-gradient-dark.colored-shadow, .webf-button.gradient-dark.webf-button-colored-shadow, .webf-button.gradient-dark.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-dark.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-dark.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-dark.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-dark.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-dark.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(85, 85, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(85, 85, 85, 0.4);
}
.webf-button.webf-button-action, .webf-button.webf-button-action.webf-normal, .webf-button.action, .webf-button.action.webf-normal {
  background-color: #e44982;
  color: white;
  border-color: #e44982;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-action:not(.muted):not(.disabled):hover, .webf-button.webf-button-action:not(.muted):not(.disabled):active, .webf-button.webf-button-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-action:not(.muted):not(.disabled).webf-state-active, .webf-button.action:not(.muted):not(.disabled):hover, .webf-button.action:not(.muted):not(.disabled):active, .webf-button.action:not(.muted):not(.disabled).webf-active, .webf-button.action:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #da2064;
  background-color: #da2064;
}
.webf-button.webf-button-action:not(.muted):not(.disabled):active, .webf-button.webf-button-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-action:not(.muted):not(.disabled).webf-state-active, .webf-button.action:not(.muted):not(.disabled):active, .webf-button.action:not(.muted):not(.disabled).webf-active, .webf-button.action:not(.muted):not(.disabled).webf-state-active {
  background-color: #ad1a50;
  border-color: #ad1a50;
}
.webf-button.webf-button-action.webf-button-colored-shadow, .webf-button.webf-button-action.colored-shadow, .webf-button.action.webf-button-colored-shadow, .webf-button.action.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 1rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-action.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.action.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 3rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-action:not(.disabled):visited, .webf-button.webf-button-action:not(.disabled):focus, .webf-button.action:not(.disabled):visited, .webf-button.action:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-action .webf-button-caret.webf-button-caret-down, .webf-button.action .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-action .webf-button-caret.webf-button-caret-left, .webf-button.action .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-action .webf-button-caret.webf-button-caret-right, .webf-button.action .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-action .webf-button-caret.webf-button-caret-up, .webf-button.action .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-action, .webf-button.text-action {
  border-color: transparent;
  color: #e44982;
}
.webf-button.webf-button-text-action:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-action:not(.muted):not(.disabled).webf-state-active, .webf-button.text-action:not(.muted):not(.disabled):hover, .webf-button.text-action:not(.muted):not(.disabled).webf-active, .webf-button.text-action:not(.muted):not(.disabled).webf-state-active {
  color: #da2064;
  background-color: rgba(228, 73, 130, 0.07);
}
.webf-button.webf-button-text-action:not(.muted):not(.disabled):active, .webf-button.webf-button-text-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-action:not(.muted):not(.disabled).webf-state-active, .webf-button.text-action:not(.muted):not(.disabled):active, .webf-button.text-action:not(.muted):not(.disabled).webf-active, .webf-button.text-action:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(228, 73, 130, 0.14);
}
.webf-button.webf-button-text-action .webf-button-caret.webf-button-caret-down, .webf-button.text-action .webf-button-caret.webf-button-caret-down {
  border-top-color: #e44982;
}
.webf-button.webf-button-text-action .webf-button-caret.webf-button-caret-left, .webf-button.text-action .webf-button-caret.webf-button-caret-left {
  border-right-color: #e44982;
}
.webf-button.webf-button-text-action .webf-button-caret.webf-button-caret-right, .webf-button.text-action .webf-button-caret.webf-button-caret-right {
  border-left-color: #e44982;
}
.webf-button.webf-button-text-action .webf-button-caret.webf-button-caret-up, .webf-button.text-action .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #e44982;
}
.webf-button.webf-button-outline-action, .webf-button.outline-action {
  color: #e44982;
  border-color: #e44982;
}
.webf-button.webf-button-outline-action:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-action:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-action:not(.muted):not(.disabled):hover, .webf-button.outline-action:not(.muted):not(.disabled).webf-active, .webf-button.outline-action:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(228, 73, 130, 0.07);
  color: #da2064;
}
.webf-button.webf-button-outline-action:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-action:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-action:not(.muted):not(.disabled):active, .webf-button.outline-action:not(.muted):not(.disabled).webf-active, .webf-button.outline-action:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(228, 73, 130, 0.14);
}
.webf-button.webf-button-outline-action.webf-button-colored-shadow, .webf-button.webf-button-outline-action.colored-shadow, .webf-button.outline-action.webf-button-colored-shadow, .webf-button.outline-action.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 1rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-outline-action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-action.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-action.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 3rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-outline-action .webf-button-caret.webf-button-caret-down, .webf-button.outline-action .webf-button-caret.webf-button-caret-down {
  border-top-color: #e44982;
}
.webf-button.webf-button-outline-action .webf-button-caret.webf-button-caret-left, .webf-button.outline-action .webf-button-caret.webf-button-caret-left {
  border-right-color: #e44982;
}
.webf-button.webf-button-outline-action .webf-button-caret.webf-button-caret-right, .webf-button.outline-action .webf-button-caret.webf-button-caret-right {
  border-left-color: #e44982;
}
.webf-button.webf-button-outline-action .webf-button-caret.webf-button-caret-up, .webf-button.outline-action .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #e44982;
}
.webf-button.webf-button-gradient-action, .webf-button.gradient-action {
  background: #e44982 linear-gradient(60deg, #c31d5a 0%, #e44982 50%, #eea1e1 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-action:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-action:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-action:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-action:not(.muted):not(.disabled):hover, .webf-button.gradient-action:not(.muted):not(.disabled).webf-active, .webf-button.gradient-action:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-action.webf-button-colored-shadow, .webf-button.webf-button-gradient-action.colored-shadow, .webf-button.gradient-action.webf-button-colored-shadow, .webf-button.gradient-action.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 1rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-action.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-action.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-action.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-action.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-action.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(228, 73, 130, 0.15), 0 0.7rem 3rem -0.5rem rgba(228, 73, 130, 0.4);
}
.webf-button.webf-button-info, .webf-button.webf-button-info.webf-normal, .webf-button.info, .webf-button.info.webf-normal {
  background-color: #4dbbe2;
  color: white;
  border-color: #4dbbe2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-info:not(.muted):not(.disabled):hover, .webf-button.webf-button-info:not(.muted):not(.disabled):active, .webf-button.webf-button-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-info:not(.muted):not(.disabled).webf-state-active, .webf-button.info:not(.muted):not(.disabled):hover, .webf-button.info:not(.muted):not(.disabled):active, .webf-button.info:not(.muted):not(.disabled).webf-active, .webf-button.info:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #23a9d9;
  background-color: #23a9d9;
}
.webf-button.webf-button-info:not(.muted):not(.disabled):active, .webf-button.webf-button-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-info:not(.muted):not(.disabled).webf-state-active, .webf-button.info:not(.muted):not(.disabled):active, .webf-button.info:not(.muted):not(.disabled).webf-active, .webf-button.info:not(.muted):not(.disabled).webf-state-active {
  background-color: #1c87ad;
  border-color: #1c87ad;
}
.webf-button.webf-button-info.webf-button-colored-shadow, .webf-button.webf-button-info.colored-shadow, .webf-button.info.webf-button-colored-shadow, .webf-button.info.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 1rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-info.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.info.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 3rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-info:not(.disabled):visited, .webf-button.webf-button-info:not(.disabled):focus, .webf-button.info:not(.disabled):visited, .webf-button.info:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-info .webf-button-caret.webf-button-caret-down, .webf-button.info .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-info .webf-button-caret.webf-button-caret-left, .webf-button.info .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-info .webf-button-caret.webf-button-caret-right, .webf-button.info .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-info .webf-button-caret.webf-button-caret-up, .webf-button.info .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-info, .webf-button.text-info {
  border-color: transparent;
  color: #4dbbe2;
}
.webf-button.webf-button-text-info:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-info:not(.muted):not(.disabled).webf-state-active, .webf-button.text-info:not(.muted):not(.disabled):hover, .webf-button.text-info:not(.muted):not(.disabled).webf-active, .webf-button.text-info:not(.muted):not(.disabled).webf-state-active {
  color: #23a9d9;
  background-color: rgba(77, 187, 226, 0.07);
}
.webf-button.webf-button-text-info:not(.muted):not(.disabled):active, .webf-button.webf-button-text-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-info:not(.muted):not(.disabled).webf-state-active, .webf-button.text-info:not(.muted):not(.disabled):active, .webf-button.text-info:not(.muted):not(.disabled).webf-active, .webf-button.text-info:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(77, 187, 226, 0.14);
}
.webf-button.webf-button-text-info .webf-button-caret.webf-button-caret-down, .webf-button.text-info .webf-button-caret.webf-button-caret-down {
  border-top-color: #4dbbe2;
}
.webf-button.webf-button-text-info .webf-button-caret.webf-button-caret-left, .webf-button.text-info .webf-button-caret.webf-button-caret-left {
  border-right-color: #4dbbe2;
}
.webf-button.webf-button-text-info .webf-button-caret.webf-button-caret-right, .webf-button.text-info .webf-button-caret.webf-button-caret-right {
  border-left-color: #4dbbe2;
}
.webf-button.webf-button-text-info .webf-button-caret.webf-button-caret-up, .webf-button.text-info .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #4dbbe2;
}
.webf-button.webf-button-outline-info, .webf-button.outline-info {
  color: #4dbbe2;
  border-color: #4dbbe2;
}
.webf-button.webf-button-outline-info:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-info:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-info:not(.muted):not(.disabled):hover, .webf-button.outline-info:not(.muted):not(.disabled).webf-active, .webf-button.outline-info:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(77, 187, 226, 0.07);
  color: #23a9d9;
}
.webf-button.webf-button-outline-info:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-info:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-info:not(.muted):not(.disabled):active, .webf-button.outline-info:not(.muted):not(.disabled).webf-active, .webf-button.outline-info:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(77, 187, 226, 0.14);
}
.webf-button.webf-button-outline-info.webf-button-colored-shadow, .webf-button.webf-button-outline-info.colored-shadow, .webf-button.outline-info.webf-button-colored-shadow, .webf-button.outline-info.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 1rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-outline-info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-info.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-info.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 3rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-outline-info .webf-button-caret.webf-button-caret-down, .webf-button.outline-info .webf-button-caret.webf-button-caret-down {
  border-top-color: #4dbbe2;
}
.webf-button.webf-button-outline-info .webf-button-caret.webf-button-caret-left, .webf-button.outline-info .webf-button-caret.webf-button-caret-left {
  border-right-color: #4dbbe2;
}
.webf-button.webf-button-outline-info .webf-button-caret.webf-button-caret-right, .webf-button.outline-info .webf-button-caret.webf-button-caret-right {
  border-left-color: #4dbbe2;
}
.webf-button.webf-button-outline-info .webf-button-caret.webf-button-caret-up, .webf-button.outline-info .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #4dbbe2;
}
.webf-button.webf-button-gradient-info, .webf-button.gradient-info {
  background: #4dbbe2 linear-gradient(60deg, #2098c3 0%, #4dbbe2 50%, #99d8f8 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-info:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-info:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-info:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-info:not(.muted):not(.disabled):hover, .webf-button.gradient-info:not(.muted):not(.disabled).webf-active, .webf-button.gradient-info:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-info.webf-button-colored-shadow, .webf-button.webf-button-gradient-info.colored-shadow, .webf-button.gradient-info.webf-button-colored-shadow, .webf-button.gradient-info.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 1rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-info.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-info.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-info.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-info.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-info.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(77, 187, 226, 0.15), 0 0.7rem 3rem -0.5rem rgba(77, 187, 226, 0.4);
}
.webf-button.webf-button-success, .webf-button.webf-button-success.webf-normal, .webf-button.success, .webf-button.success.webf-normal {
  background-color: #66BB6A;
  color: white;
  border-color: #66BB6A;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-success:not(.muted):not(.disabled):hover, .webf-button.webf-button-success:not(.muted):not(.disabled):active, .webf-button.webf-button-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-success:not(.muted):not(.disabled).webf-state-active, .webf-button.success:not(.muted):not(.disabled):hover, .webf-button.success:not(.muted):not(.disabled):active, .webf-button.success:not(.muted):not(.disabled).webf-active, .webf-button.success:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #49a54e;
  background-color: #49a54e;
}
.webf-button.webf-button-success:not(.muted):not(.disabled):active, .webf-button.webf-button-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-success:not(.muted):not(.disabled).webf-state-active, .webf-button.success:not(.muted):not(.disabled):active, .webf-button.success:not(.muted):not(.disabled).webf-active, .webf-button.success:not(.muted):not(.disabled).webf-state-active {
  background-color: #3a813d;
  border-color: #3a813d;
}
.webf-button.webf-button-success.webf-button-colored-shadow, .webf-button.webf-button-success.colored-shadow, .webf-button.success.webf-button-colored-shadow, .webf-button.success.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 1rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-success.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.success.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 3rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-success:not(.disabled):visited, .webf-button.webf-button-success:not(.disabled):focus, .webf-button.success:not(.disabled):visited, .webf-button.success:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-success .webf-button-caret.webf-button-caret-down, .webf-button.success .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-success .webf-button-caret.webf-button-caret-left, .webf-button.success .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-success .webf-button-caret.webf-button-caret-right, .webf-button.success .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-success .webf-button-caret.webf-button-caret-up, .webf-button.success .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-success, .webf-button.text-success {
  border-color: transparent;
  color: #66BB6A;
}
.webf-button.webf-button-text-success:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-success:not(.muted):not(.disabled).webf-state-active, .webf-button.text-success:not(.muted):not(.disabled):hover, .webf-button.text-success:not(.muted):not(.disabled).webf-active, .webf-button.text-success:not(.muted):not(.disabled).webf-state-active {
  color: #49a54e;
  background-color: rgba(102, 187, 106, 0.07);
}
.webf-button.webf-button-text-success:not(.muted):not(.disabled):active, .webf-button.webf-button-text-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-success:not(.muted):not(.disabled).webf-state-active, .webf-button.text-success:not(.muted):not(.disabled):active, .webf-button.text-success:not(.muted):not(.disabled).webf-active, .webf-button.text-success:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(102, 187, 106, 0.14);
}
.webf-button.webf-button-text-success .webf-button-caret.webf-button-caret-down, .webf-button.text-success .webf-button-caret.webf-button-caret-down {
  border-top-color: #66BB6A;
}
.webf-button.webf-button-text-success .webf-button-caret.webf-button-caret-left, .webf-button.text-success .webf-button-caret.webf-button-caret-left {
  border-right-color: #66BB6A;
}
.webf-button.webf-button-text-success .webf-button-caret.webf-button-caret-right, .webf-button.text-success .webf-button-caret.webf-button-caret-right {
  border-left-color: #66BB6A;
}
.webf-button.webf-button-text-success .webf-button-caret.webf-button-caret-up, .webf-button.text-success .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #66BB6A;
}
.webf-button.webf-button-outline-success, .webf-button.outline-success {
  color: #66BB6A;
  border-color: #66BB6A;
}
.webf-button.webf-button-outline-success:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-success:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-success:not(.muted):not(.disabled):hover, .webf-button.outline-success:not(.muted):not(.disabled).webf-active, .webf-button.outline-success:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(102, 187, 106, 0.07);
  color: #49a54e;
}
.webf-button.webf-button-outline-success:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-success:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-success:not(.muted):not(.disabled):active, .webf-button.outline-success:not(.muted):not(.disabled).webf-active, .webf-button.outline-success:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(102, 187, 106, 0.14);
}
.webf-button.webf-button-outline-success.webf-button-colored-shadow, .webf-button.webf-button-outline-success.colored-shadow, .webf-button.outline-success.webf-button-colored-shadow, .webf-button.outline-success.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 1rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-outline-success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-success.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-success.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 3rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-outline-success .webf-button-caret.webf-button-caret-down, .webf-button.outline-success .webf-button-caret.webf-button-caret-down {
  border-top-color: #66BB6A;
}
.webf-button.webf-button-outline-success .webf-button-caret.webf-button-caret-left, .webf-button.outline-success .webf-button-caret.webf-button-caret-left {
  border-right-color: #66BB6A;
}
.webf-button.webf-button-outline-success .webf-button-caret.webf-button-caret-right, .webf-button.outline-success .webf-button-caret.webf-button-caret-right {
  border-left-color: #66BB6A;
}
.webf-button.webf-button-outline-success .webf-button-caret.webf-button-caret-up, .webf-button.outline-success .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #66BB6A;
}
.webf-button.webf-button-gradient-success, .webf-button.gradient-success {
  background: #66BB6A linear-gradient(60deg, #419345 0%, #66BB6A 50%, #aedcbc 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-success:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-success:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-success:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-success:not(.muted):not(.disabled):hover, .webf-button.gradient-success:not(.muted):not(.disabled).webf-active, .webf-button.gradient-success:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-success.webf-button-colored-shadow, .webf-button.webf-button-gradient-success.colored-shadow, .webf-button.gradient-success.webf-button-colored-shadow, .webf-button.gradient-success.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 1rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-success.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-success.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-success.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-success.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-success.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(102, 187, 106, 0.15), 0 0.7rem 3rem -0.5rem rgba(102, 187, 106, 0.4);
}
.webf-button.webf-button-warning, .webf-button.webf-button-warning.webf-normal, .webf-button.warning, .webf-button.warning.webf-normal {
  background-color: #f6b74d;
  color: white;
  border-color: #f6b74d;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-warning:not(.muted):not(.disabled):hover, .webf-button.webf-button-warning:not(.muted):not(.disabled):active, .webf-button.webf-button-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.warning:not(.muted):not(.disabled):hover, .webf-button.warning:not(.muted):not(.disabled):active, .webf-button.warning:not(.muted):not(.disabled).webf-active, .webf-button.warning:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #f4a31c;
  background-color: #f4a31c;
}
.webf-button.webf-button-warning:not(.muted):not(.disabled):active, .webf-button.webf-button-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.warning:not(.muted):not(.disabled):active, .webf-button.warning:not(.muted):not(.disabled).webf-active, .webf-button.warning:not(.muted):not(.disabled).webf-state-active {
  background-color: #d2880b;
  border-color: #d2880b;
}
.webf-button.webf-button-warning.webf-button-colored-shadow, .webf-button.webf-button-warning.colored-shadow, .webf-button.warning.webf-button-colored-shadow, .webf-button.warning.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 1rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-warning.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.warning.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 3rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-warning:not(.disabled):visited, .webf-button.webf-button-warning:not(.disabled):focus, .webf-button.warning:not(.disabled):visited, .webf-button.warning:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-warning .webf-button-caret.webf-button-caret-down, .webf-button.warning .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-warning .webf-button-caret.webf-button-caret-left, .webf-button.warning .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-warning .webf-button-caret.webf-button-caret-right, .webf-button.warning .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-warning .webf-button-caret.webf-button-caret-up, .webf-button.warning .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-warning, .webf-button.text-warning {
  border-color: transparent;
  color: #f6b74d;
}
.webf-button.webf-button-text-warning:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.text-warning:not(.muted):not(.disabled):hover, .webf-button.text-warning:not(.muted):not(.disabled).webf-active, .webf-button.text-warning:not(.muted):not(.disabled).webf-state-active {
  color: #f4a31c;
  background-color: rgba(246, 183, 77, 0.07);
}
.webf-button.webf-button-text-warning:not(.muted):not(.disabled):active, .webf-button.webf-button-text-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.text-warning:not(.muted):not(.disabled):active, .webf-button.text-warning:not(.muted):not(.disabled).webf-active, .webf-button.text-warning:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(246, 183, 77, 0.14);
}
.webf-button.webf-button-text-warning .webf-button-caret.webf-button-caret-down, .webf-button.text-warning .webf-button-caret.webf-button-caret-down {
  border-top-color: #f6b74d;
}
.webf-button.webf-button-text-warning .webf-button-caret.webf-button-caret-left, .webf-button.text-warning .webf-button-caret.webf-button-caret-left {
  border-right-color: #f6b74d;
}
.webf-button.webf-button-text-warning .webf-button-caret.webf-button-caret-right, .webf-button.text-warning .webf-button-caret.webf-button-caret-right {
  border-left-color: #f6b74d;
}
.webf-button.webf-button-text-warning .webf-button-caret.webf-button-caret-up, .webf-button.text-warning .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f6b74d;
}
.webf-button.webf-button-outline-warning, .webf-button.outline-warning {
  color: #f6b74d;
  border-color: #f6b74d;
}
.webf-button.webf-button-outline-warning:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-warning:not(.muted):not(.disabled):hover, .webf-button.outline-warning:not(.muted):not(.disabled).webf-active, .webf-button.outline-warning:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(246, 183, 77, 0.07);
  color: #f4a31c;
}
.webf-button.webf-button-outline-warning:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-warning:not(.muted):not(.disabled):active, .webf-button.outline-warning:not(.muted):not(.disabled).webf-active, .webf-button.outline-warning:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(246, 183, 77, 0.14);
}
.webf-button.webf-button-outline-warning.webf-button-colored-shadow, .webf-button.webf-button-outline-warning.colored-shadow, .webf-button.outline-warning.webf-button-colored-shadow, .webf-button.outline-warning.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 1rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-warning.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-warning.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 3rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-outline-warning .webf-button-caret.webf-button-caret-down, .webf-button.outline-warning .webf-button-caret.webf-button-caret-down {
  border-top-color: #f6b74d;
}
.webf-button.webf-button-outline-warning .webf-button-caret.webf-button-caret-left, .webf-button.outline-warning .webf-button-caret.webf-button-caret-left {
  border-right-color: #f6b74d;
}
.webf-button.webf-button-outline-warning .webf-button-caret.webf-button-caret-right, .webf-button.outline-warning .webf-button-caret.webf-button-caret-right {
  border-left-color: #f6b74d;
}
.webf-button.webf-button-outline-warning .webf-button-caret.webf-button-caret-up, .webf-button.outline-warning .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f6b74d;
}
.webf-button.webf-button-gradient-warning, .webf-button.gradient-warning {
  background: #f6b74d linear-gradient(60deg, #eb980c 0%, #f6b74d 50%, #f6df5f 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-warning:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-warning:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-warning:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-warning:not(.muted):not(.disabled):hover, .webf-button.gradient-warning:not(.muted):not(.disabled).webf-active, .webf-button.gradient-warning:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-warning.webf-button-colored-shadow, .webf-button.webf-button-gradient-warning.colored-shadow, .webf-button.gradient-warning.webf-button-colored-shadow, .webf-button.gradient-warning.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 1rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-warning.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-warning.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-warning.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-warning.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-warning.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(246, 183, 77, 0.15), 0 0.7rem 3rem -0.5rem rgba(246, 183, 77, 0.4);
}
.webf-button.webf-button-danger, .webf-button.webf-button-danger.webf-normal, .webf-button.danger, .webf-button.danger.webf-normal {
  background-color: #ed5655;
  color: white;
  border-color: #ed5655;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-danger:not(.muted):not(.disabled):hover, .webf-button.webf-button-danger:not(.muted):not(.disabled):active, .webf-button.webf-button-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.danger:not(.muted):not(.disabled):hover, .webf-button.danger:not(.muted):not(.disabled):active, .webf-button.danger:not(.muted):not(.disabled).webf-active, .webf-button.danger:not(.muted):not(.disabled).webf-state-active {
  color: white;
  border-color: #e82827;
  background-color: #e82827;
}
.webf-button.webf-button-danger:not(.muted):not(.disabled):active, .webf-button.webf-button-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.danger:not(.muted):not(.disabled):active, .webf-button.danger:not(.muted):not(.disabled).webf-active, .webf-button.danger:not(.muted):not(.disabled).webf-state-active {
  background-color: #c71615;
  border-color: #c71615;
}
.webf-button.webf-button-danger.webf-button-colored-shadow, .webf-button.webf-button-danger.colored-shadow, .webf-button.danger.webf-button-colored-shadow, .webf-button.danger.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-danger.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.danger.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-danger:not(.disabled):visited, .webf-button.webf-button-danger:not(.disabled):focus, .webf-button.danger:not(.disabled):visited, .webf-button.danger:not(.disabled):focus {
  box-shadow: none;
  color: white;
}
.webf-button.webf-button-danger .webf-button-caret.webf-button-caret-down, .webf-button.danger .webf-button-caret.webf-button-caret-down {
  border-top-color: white;
}
.webf-button.webf-button-danger .webf-button-caret.webf-button-caret-left, .webf-button.danger .webf-button-caret.webf-button-caret-left {
  border-right-color: white;
}
.webf-button.webf-button-danger .webf-button-caret.webf-button-caret-right, .webf-button.danger .webf-button-caret.webf-button-caret-right {
  border-left-color: white;
}
.webf-button.webf-button-danger .webf-button-caret.webf-button-caret-up, .webf-button.danger .webf-button-caret.webf-button-caret-up {
  border-bottom-color: white;
}
.webf-button.webf-button-text-danger, .webf-button.text-danger {
  border-color: transparent;
  color: #ed5655;
}
.webf-button.webf-button-text-danger:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.text-danger:not(.muted):not(.disabled):hover, .webf-button.text-danger:not(.muted):not(.disabled).webf-active, .webf-button.text-danger:not(.muted):not(.disabled).webf-state-active {
  color: #e82827;
  background-color: rgba(237, 86, 85, 0.07);
}
.webf-button.webf-button-text-danger:not(.muted):not(.disabled):active, .webf-button.webf-button-text-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.text-danger:not(.muted):not(.disabled):active, .webf-button.text-danger:not(.muted):not(.disabled).webf-active, .webf-button.text-danger:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(237, 86, 85, 0.14);
}
.webf-button.webf-button-text-danger .webf-button-caret.webf-button-caret-down, .webf-button.text-danger .webf-button-caret.webf-button-caret-down {
  border-top-color: #ed5655;
}
.webf-button.webf-button-text-danger .webf-button-caret.webf-button-caret-left, .webf-button.text-danger .webf-button-caret.webf-button-caret-left {
  border-right-color: #ed5655;
}
.webf-button.webf-button-text-danger .webf-button-caret.webf-button-caret-right, .webf-button.text-danger .webf-button-caret.webf-button-caret-right {
  border-left-color: #ed5655;
}
.webf-button.webf-button-text-danger .webf-button-caret.webf-button-caret-up, .webf-button.text-danger .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #ed5655;
}
.webf-button.webf-button-outline-danger, .webf-button.outline-danger {
  color: #ed5655;
  border-color: #ed5655;
}
.webf-button.webf-button-outline-danger:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-danger:not(.muted):not(.disabled):hover, .webf-button.outline-danger:not(.muted):not(.disabled).webf-active, .webf-button.outline-danger:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(237, 86, 85, 0.07);
  color: #e82827;
}
.webf-button.webf-button-outline-danger:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-danger:not(.muted):not(.disabled):active, .webf-button.outline-danger:not(.muted):not(.disabled).webf-active, .webf-button.outline-danger:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(237, 86, 85, 0.14);
}
.webf-button.webf-button-outline-danger.webf-button-colored-shadow, .webf-button.webf-button-outline-danger.colored-shadow, .webf-button.outline-danger.webf-button-colored-shadow, .webf-button.outline-danger.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-danger.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-danger.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-outline-danger .webf-button-caret.webf-button-caret-down, .webf-button.outline-danger .webf-button-caret.webf-button-caret-down {
  border-top-color: #ed5655;
}
.webf-button.webf-button-outline-danger .webf-button-caret.webf-button-caret-left, .webf-button.outline-danger .webf-button-caret.webf-button-caret-left {
  border-right-color: #ed5655;
}
.webf-button.webf-button-outline-danger .webf-button-caret.webf-button-caret-right, .webf-button.outline-danger .webf-button-caret.webf-button-caret-right {
  border-left-color: #ed5655;
}
.webf-button.webf-button-outline-danger .webf-button-caret.webf-button-caret-up, .webf-button.outline-danger .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #ed5655;
}
.webf-button.webf-button-gradient-danger, .webf-button.gradient-danger {
  background: #ed5655 linear-gradient(60deg, #de1918 0%, #ed5655 50%, #ed7f66 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: white;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-danger:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-danger:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-danger:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-danger:not(.muted):not(.disabled):hover, .webf-button.gradient-danger:not(.muted):not(.disabled).webf-active, .webf-button.gradient-danger:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-danger.webf-button-colored-shadow, .webf-button.webf-button-gradient-danger.colored-shadow, .webf-button.gradient-danger.webf-button-colored-shadow, .webf-button.gradient-danger.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 1rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-danger.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-danger.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-danger.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-danger.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-danger.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(237, 86, 85, 0.15), 0 0.7rem 3rem -0.5rem rgba(237, 86, 85, 0.4);
}
.webf-button.webf-button-lightgrey, .webf-button.webf-button-lightgrey.webf-normal, .webf-button.lightgrey, .webf-button.lightgrey.webf-normal {
  background-color: #f5f5f5;
  color: #555;
  border-color: #f5f5f5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-button.webf-button-lightgrey:not(.muted):not(.disabled):hover, .webf-button.webf-button-lightgrey:not(.muted):not(.disabled):active, .webf-button.webf-button-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.lightgrey:not(.muted):not(.disabled):hover, .webf-button.lightgrey:not(.muted):not(.disabled):active, .webf-button.lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.lightgrey:not(.muted):not(.disabled).webf-state-active {
  color: #555;
  border-color: gainsboro;
  background-color: gainsboro;
}
.webf-button.webf-button-lightgrey:not(.muted):not(.disabled):active, .webf-button.webf-button-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.lightgrey:not(.muted):not(.disabled):active, .webf-button.lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.lightgrey:not(.muted):not(.disabled).webf-state-active {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
.webf-button.webf-button-lightgrey.webf-button-colored-shadow, .webf-button.webf-button-lightgrey.colored-shadow, .webf-button.lightgrey.webf-button-colored-shadow, .webf-button.lightgrey.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 1rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 3rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-lightgrey:not(.disabled):visited, .webf-button.webf-button-lightgrey:not(.disabled):focus, .webf-button.lightgrey:not(.disabled):visited, .webf-button.lightgrey:not(.disabled):focus {
  box-shadow: none;
  color: #555;
}
.webf-button.webf-button-lightgrey .webf-button-caret.webf-button-caret-down, .webf-button.lightgrey .webf-button-caret.webf-button-caret-down {
  border-top-color: #555;
}
.webf-button.webf-button-lightgrey .webf-button-caret.webf-button-caret-left, .webf-button.lightgrey .webf-button-caret.webf-button-caret-left {
  border-right-color: #555;
}
.webf-button.webf-button-lightgrey .webf-button-caret.webf-button-caret-right, .webf-button.lightgrey .webf-button-caret.webf-button-caret-right {
  border-left-color: #555;
}
.webf-button.webf-button-lightgrey .webf-button-caret.webf-button-caret-up, .webf-button.lightgrey .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #555;
}
.webf-button.webf-button-text-lightgrey, .webf-button.text-lightgrey {
  border-color: transparent;
  color: #f5f5f5;
}
.webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled):hover, .webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.text-lightgrey:not(.muted):not(.disabled):hover, .webf-button.text-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.text-lightgrey:not(.muted):not(.disabled).webf-state-active {
  color: gainsboro;
  background-color: rgba(245, 245, 245, 0.07);
}
.webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled):active, .webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-text-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.text-lightgrey:not(.muted):not(.disabled):active, .webf-button.text-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.text-lightgrey:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(245, 245, 245, 0.14);
}
.webf-button.webf-button-text-lightgrey .webf-button-caret.webf-button-caret-down, .webf-button.text-lightgrey .webf-button-caret.webf-button-caret-down {
  border-top-color: #f5f5f5;
}
.webf-button.webf-button-text-lightgrey .webf-button-caret.webf-button-caret-left, .webf-button.text-lightgrey .webf-button-caret.webf-button-caret-left {
  border-right-color: #f5f5f5;
}
.webf-button.webf-button-text-lightgrey .webf-button-caret.webf-button-caret-right, .webf-button.text-lightgrey .webf-button-caret.webf-button-caret-right {
  border-left-color: #f5f5f5;
}
.webf-button.webf-button-text-lightgrey .webf-button-caret.webf-button-caret-up, .webf-button.text-lightgrey .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f5f5f5;
}
.webf-button.webf-button-outline-lightgrey, .webf-button.outline-lightgrey {
  color: #f5f5f5;
  border-color: #f5f5f5;
}
.webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-lightgrey:not(.muted):not(.disabled):hover, .webf-button.outline-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.outline-lightgrey:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(245, 245, 245, 0.07);
  color: gainsboro;
}
.webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled):active, .webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-lightgrey:not(.muted):not(.disabled):active, .webf-button.outline-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.outline-lightgrey:not(.muted):not(.disabled).webf-state-active {
  background-color: rgba(245, 245, 245, 0.14);
}
.webf-button.webf-button-outline-lightgrey.webf-button-colored-shadow, .webf-button.webf-button-outline-lightgrey.colored-shadow, .webf-button.outline-lightgrey.webf-button-colored-shadow, .webf-button.outline-lightgrey.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 1rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-outline-lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-outline-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-outline-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.outline-lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.outline-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.outline-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 3rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-outline-lightgrey .webf-button-caret.webf-button-caret-down, .webf-button.outline-lightgrey .webf-button-caret.webf-button-caret-down {
  border-top-color: #f5f5f5;
}
.webf-button.webf-button-outline-lightgrey .webf-button-caret.webf-button-caret-left, .webf-button.outline-lightgrey .webf-button-caret.webf-button-caret-left {
  border-right-color: #f5f5f5;
}
.webf-button.webf-button-outline-lightgrey .webf-button-caret.webf-button-caret-right, .webf-button.outline-lightgrey .webf-button-caret.webf-button-caret-right {
  border-left-color: #f5f5f5;
}
.webf-button.webf-button-outline-lightgrey .webf-button-caret.webf-button-caret-up, .webf-button.outline-lightgrey .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #f5f5f5;
}
.webf-button.webf-button-gradient-lightgrey, .webf-button.gradient-lightgrey {
  background: #f5f5f5 linear-gradient(60deg, #cfcfcf 0%, #f5f5f5 50%, #fafafa 100%);
  background-position: 100% 0;
  background-size: 200%;
  border: none;
  color: #555;
  padding: -moz-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: -webkit-calc( 0.3rem + 1px ) calc( 1rem + 1px );
  padding: calc( 0.3rem + 1px ) calc( 1rem + 1px );
}
.webf-button.webf-button-gradient-lightgrey:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-lightgrey:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-lightgrey:not(.muted):not(.disabled):hover, .webf-button.gradient-lightgrey:not(.muted):not(.disabled).webf-active, .webf-button.gradient-lightgrey:not(.muted):not(.disabled).webf-state-active {
  background-position: 0 0%;
}
.webf-button.webf-button-gradient-lightgrey.webf-button-colored-shadow, .webf-button.webf-button-gradient-lightgrey.colored-shadow, .webf-button.gradient-lightgrey.webf-button-colored-shadow, .webf-button.gradient-lightgrey.colored-shadow {
  box-shadow: 0 0.4rem 2rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 1rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.webf-button-gradient-lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-gradient-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-gradient-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-lightgrey.webf-button-colored-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.gradient-lightgrey.colored-shadow:not(.muted):not(.disabled):hover, .webf-button.gradient-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-active, .webf-button.gradient-lightgrey.colored-shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0 0.4rem 3rem 0 rgba(245, 245, 245, 0.15), 0 0.7rem 3rem -0.5rem rgba(245, 245, 245, 0.4);
}
.webf-button.webf-button-shadow, .webf-button.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.webf-button.webf-button-shadow:not(.muted):not(.disabled):hover, .webf-button.webf-button-shadow:not(.muted):not(.disabled).webf-active, .webf-button.webf-button-shadow:not(.muted):not(.disabled).webf-state-active, .webf-button.shadow:not(.muted):not(.disabled):hover, .webf-button.shadow:not(.muted):not(.disabled).webf-active, .webf-button.shadow:not(.muted):not(.disabled).webf-state-active {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -2px rgba(0, 0, 0, 0.12);
}
.webf-button.muted, .webf-button.muted:hover, .webf-button.muted:active, .webf-button.muted:visited {
  opacity: 0.5;
  cursor: not-allowed;
}
.webf-button.disabled, .webf-button.disabled:hover, .webf-button.disabled:active, .webf-button.disabled:visited {
  background-image: -owg-none;
  background-image: -webkit-none;
  background-image: -moz-none;
  background-image: -o-none;
  background-image: none;
  background-color: white;
  border-color: #d5d5d5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #bbbbbb;
  cursor: not-allowed;
}
.webf-button.disabled .webf-button-caret.webf-button-caret-down, .webf-button.disabled:hover .webf-button-caret.webf-button-caret-down, .webf-button.disabled:active .webf-button-caret.webf-button-caret-down, .webf-button.disabled:visited .webf-button-caret.webf-button-caret-down {
  border-top-color: #bbbbbb;
}
.webf-button.disabled .webf-button-caret.webf-button-caret-left, .webf-button.disabled:hover .webf-button-caret.webf-button-caret-left, .webf-button.disabled:active .webf-button-caret.webf-button-caret-left, .webf-button.disabled:visited .webf-button-caret.webf-button-caret-left {
  border-right-color: #bbbbbb;
}
.webf-button.disabled .webf-button-caret.webf-button-caret-right, .webf-button.disabled:hover .webf-button-caret.webf-button-caret-right, .webf-button.disabled:active .webf-button-caret.webf-button-caret-right, .webf-button.disabled:visited .webf-button-caret.webf-button-caret-right {
  border-left-color: #bbbbbb;
}
.webf-button.disabled .webf-button-caret.webf-button-caret-up, .webf-button.disabled:hover .webf-button-caret.webf-button-caret-up, .webf-button.disabled:active .webf-button-caret.webf-button-caret-up, .webf-button.disabled:visited .webf-button-caret.webf-button-caret-up {
  border-bottom-color: #bbbbbb;
}
.webf-button.webf-button-square, .webf-button.webf-button-square:hover, .webf-button.webf-button-square:active, .webf-button.square, .webf-button.square:hover, .webf-button.square:active {
  border-radius: 0 !important;
}
.webf-button.webf-button-pill, .webf-button.webf-button-pill:hover, .webf-button.webf-button-pill:active, .webf-button.pill, .webf-button.pill:hover, .webf-button.pill:active {
  border-radius: 100rem !important;
}
.webf-button.webf-button-block, .webf-button.block {
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
}
.webf-button.webf-button-load, .webf-button.load {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.webf-button.webf-button-load .webf-button-maintext, .webf-button.load .webf-button-maintext {
  transition: 0.3s color;
}
.webf-button.webf-button-load .webf-button-icon-loader, .webf-button.load .webf-button-icon-loader {
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: 0.3s color;
  z-index: 1;
}
.webf-button.webf-button-load .webf-button-progressbar, .webf-button.load .webf-button-progressbar {
  background: rgba(0, 0, 0, 0.2);
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0%;
  z-index: -1;
}
.webf-button.webf-button-load.webf-button-load.webf-button-loading .webf-button-maintext, .webf-button.webf-button-load.load.loading .webf-button-maintext, .webf-button.load.webf-button-load.webf-button-loading .webf-button-maintext, .webf-button.load.load.loading .webf-button-maintext {
  color: transparent;
}
.webf-button.webf-button-load.webf-button-load.webf-button-loading .webf-button-icon-loader, .webf-button.webf-button-load.load.loading .webf-button-icon-loader, .webf-button.load.webf-button-load.webf-button-loading .webf-button-icon-loader, .webf-button.load.load.loading .webf-button-icon-loader {
  opacity: 1;
}

.webf-button, .webf-button {
  -webkit-border-radius: 0.225rem;
  -moz-border-radius: 0.225rem;
  -ms-border-radius: 0.225rem;
  -o-border-radius: 0.225rem;
  border-radius: 0.225rem;
  font-size: 0.9rem;
  padding: 0.27rem 0.9rem;
}
.webf-button .webf-button-icon, .webf-button .webf-button-icon {
  font-size: 1em;
  vertical-align: middle;
}
.webf-button .webf-button-icon.webf-icon-left, .webf-button .webf-button-icon.webf-icon-left {
  margin-right: 0.3rem;
}
.webf-button .webf-button-icon.webf-icon-right, .webf-button .webf-button-icon.webf-icon-right {
  margin-left: 0.3rem;
}
.webf-button .webf-button-caret, .webf-button .webf-button-caret {
  border: 0.315rem solid transparent;
  display: inline-block;
  height: 0;
  margin-left: 0.3rem;
  position: relative;
  vertical-align: baseline;
  width: 0;
}
.webf-button .webf-button-caret.webf-button-caret-down, .webf-button .webf-button-caret.webf-button-caret-down {
  top: 0.1575rem;
}
.webf-button .webf-button-caret.webf-button-caret-left, .webf-button .webf-button-caret.webf-button-caret-left {
  right: 0.1575rem;
}
.webf-button .webf-button-caret.webf-button-caret-right, .webf-button .webf-button-caret.webf-button-caret-right {
  left: 0.1575rem;
}
.webf-button .webf-button-caret.webf-button-caret-up, .webf-button .webf-button-caret.webf-button-caret-up {
  bottom: 0.1575rem;
}
.webf-button.webf-button-icon-only, .webf-button.icon-only, .webf-button.webf-button-icon-only, .webf-button.icon-only {
  height: 2.7rem;
  text-align: center;
  width: 2.7rem;
  padding: 0;
}
.webf-button.webf-button-icon-only .webf-button-icon, .webf-button.icon-only .webf-button-icon, .webf-button.webf-button-icon-only .webf-button-icon, .webf-button.icon-only .webf-button-icon {
  font-size: 1.17rem;
  line-height: 2.7rem;
  vertical-align: top;
}

.webf-button-xsmall, .webf-button.xsmall {
  -webkit-border-radius: 0.15rem;
  -moz-border-radius: 0.15rem;
  -ms-border-radius: 0.15rem;
  -o-border-radius: 0.15rem;
  border-radius: 0.15rem;
  font-size: 0.6rem;
  padding: 0.18rem 0.6rem;
}
.webf-button-xsmall .webf-button-icon, .webf-button.xsmall .webf-button-icon {
  font-size: 1em;
  vertical-align: middle;
}
.webf-button-xsmall .webf-button-icon.webf-icon-left, .webf-button.xsmall .webf-button-icon.webf-icon-left {
  margin-right: 0.2rem;
}
.webf-button-xsmall .webf-button-icon.webf-icon-right, .webf-button.xsmall .webf-button-icon.webf-icon-right {
  margin-left: 0.2rem;
}
.webf-button-xsmall .webf-button-caret, .webf-button.xsmall .webf-button-caret {
  border: 0.21rem solid transparent;
  display: inline-block;
  height: 0;
  margin-left: 0.2rem;
  position: relative;
  vertical-align: baseline;
  width: 0;
}
.webf-button-xsmall .webf-button-caret.webf-button-caret-down, .webf-button.xsmall .webf-button-caret.webf-button-caret-down {
  top: 0.105rem;
}
.webf-button-xsmall .webf-button-caret.webf-button-caret-left, .webf-button.xsmall .webf-button-caret.webf-button-caret-left {
  right: 0.105rem;
}
.webf-button-xsmall .webf-button-caret.webf-button-caret-right, .webf-button.xsmall .webf-button-caret.webf-button-caret-right {
  left: 0.105rem;
}
.webf-button-xsmall .webf-button-caret.webf-button-caret-up, .webf-button.xsmall .webf-button-caret.webf-button-caret-up {
  bottom: 0.105rem;
}
.webf-button-xsmall.webf-button-icon-only, .webf-button-xsmall.icon-only, .webf-button.xsmall.webf-button-icon-only, .webf-button.xsmall.icon-only {
  height: 1.8rem;
  text-align: center;
  width: 1.8rem;
  padding: 0;
}
.webf-button-xsmall.webf-button-icon-only .webf-button-icon, .webf-button-xsmall.icon-only .webf-button-icon, .webf-button.xsmall.webf-button-icon-only .webf-button-icon, .webf-button.xsmall.icon-only .webf-button-icon {
  font-size: 0.78rem;
  line-height: 1.8rem;
  vertical-align: top;
}

.webf-button-small, .webf-button.small {
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  font-size: 0.75rem;
  padding: 0.225rem 0.75rem;
}
.webf-button-small .webf-button-icon, .webf-button.small .webf-button-icon {
  font-size: 1em;
  vertical-align: middle;
}
.webf-button-small .webf-button-icon.webf-icon-left, .webf-button.small .webf-button-icon.webf-icon-left {
  margin-right: 0.25rem;
}
.webf-button-small .webf-button-icon.webf-icon-right, .webf-button.small .webf-button-icon.webf-icon-right {
  margin-left: 0.25rem;
}
.webf-button-small .webf-button-caret, .webf-button.small .webf-button-caret {
  border: 0.2625rem solid transparent;
  display: inline-block;
  height: 0;
  margin-left: 0.25rem;
  position: relative;
  vertical-align: baseline;
  width: 0;
}
.webf-button-small .webf-button-caret.webf-button-caret-down, .webf-button.small .webf-button-caret.webf-button-caret-down {
  top: 0.13125rem;
}
.webf-button-small .webf-button-caret.webf-button-caret-left, .webf-button.small .webf-button-caret.webf-button-caret-left {
  right: 0.13125rem;
}
.webf-button-small .webf-button-caret.webf-button-caret-right, .webf-button.small .webf-button-caret.webf-button-caret-right {
  left: 0.13125rem;
}
.webf-button-small .webf-button-caret.webf-button-caret-up, .webf-button.small .webf-button-caret.webf-button-caret-up {
  bottom: 0.13125rem;
}
.webf-button-small.webf-button-icon-only, .webf-button-small.icon-only, .webf-button.small.webf-button-icon-only, .webf-button.small.icon-only {
  height: 2.25rem;
  text-align: center;
  width: 2.25rem;
  padding: 0;
}
.webf-button-small.webf-button-icon-only .webf-button-icon, .webf-button-small.icon-only .webf-button-icon, .webf-button.small.webf-button-icon-only .webf-button-icon, .webf-button.small.icon-only .webf-button-icon {
  font-size: 0.975rem;
  line-height: 2.25rem;
  vertical-align: top;
}

.webf-button-large, .webf-button.large {
  -webkit-border-radius: 0.2625rem;
  -moz-border-radius: 0.2625rem;
  -ms-border-radius: 0.2625rem;
  -o-border-radius: 0.2625rem;
  border-radius: 0.2625rem;
  font-size: 1.05rem;
  padding: 0.315rem 1.05rem;
}
.webf-button-large .webf-button-icon, .webf-button.large .webf-button-icon {
  font-size: 1em;
  vertical-align: middle;
}
.webf-button-large .webf-button-icon.webf-icon-left, .webf-button.large .webf-button-icon.webf-icon-left {
  margin-right: 0.35rem;
}
.webf-button-large .webf-button-icon.webf-icon-right, .webf-button.large .webf-button-icon.webf-icon-right {
  margin-left: 0.35rem;
}
.webf-button-large .webf-button-caret, .webf-button.large .webf-button-caret {
  border: 0.3675rem solid transparent;
  display: inline-block;
  height: 0;
  margin-left: 0.35rem;
  position: relative;
  vertical-align: baseline;
  width: 0;
}
.webf-button-large .webf-button-caret.webf-button-caret-down, .webf-button.large .webf-button-caret.webf-button-caret-down {
  top: 0.18375rem;
}
.webf-button-large .webf-button-caret.webf-button-caret-left, .webf-button.large .webf-button-caret.webf-button-caret-left {
  right: 0.18375rem;
}
.webf-button-large .webf-button-caret.webf-button-caret-right, .webf-button.large .webf-button-caret.webf-button-caret-right {
  left: 0.18375rem;
}
.webf-button-large .webf-button-caret.webf-button-caret-up, .webf-button.large .webf-button-caret.webf-button-caret-up {
  bottom: 0.18375rem;
}
.webf-button-large.webf-button-icon-only, .webf-button-large.icon-only, .webf-button.large.webf-button-icon-only, .webf-button.large.icon-only {
  height: 3.15rem;
  text-align: center;
  width: 3.15rem;
  padding: 0;
}
.webf-button-large.webf-button-icon-only .webf-button-icon, .webf-button-large.icon-only .webf-button-icon, .webf-button.large.webf-button-icon-only .webf-button-icon, .webf-button.large.icon-only .webf-button-icon {
  font-size: 1.365rem;
  line-height: 3.15rem;
  vertical-align: top;
}

.webf-button-xlarge, .webf-button.xlarge {
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  padding: 0.36rem 1.2rem;
}
.webf-button-xlarge .webf-button-icon, .webf-button.xlarge .webf-button-icon {
  font-size: 1em;
  vertical-align: middle;
}
.webf-button-xlarge .webf-button-icon.webf-icon-left, .webf-button.xlarge .webf-button-icon.webf-icon-left {
  margin-right: 0.4rem;
}
.webf-button-xlarge .webf-button-icon.webf-icon-right, .webf-button.xlarge .webf-button-icon.webf-icon-right {
  margin-left: 0.4rem;
}
.webf-button-xlarge .webf-button-caret, .webf-button.xlarge .webf-button-caret {
  border: 0.42rem solid transparent;
  display: inline-block;
  height: 0;
  margin-left: 0.4rem;
  position: relative;
  vertical-align: baseline;
  width: 0;
}
.webf-button-xlarge .webf-button-caret.webf-button-caret-down, .webf-button.xlarge .webf-button-caret.webf-button-caret-down {
  top: 0.21rem;
}
.webf-button-xlarge .webf-button-caret.webf-button-caret-left, .webf-button.xlarge .webf-button-caret.webf-button-caret-left {
  right: 0.21rem;
}
.webf-button-xlarge .webf-button-caret.webf-button-caret-right, .webf-button.xlarge .webf-button-caret.webf-button-caret-right {
  left: 0.21rem;
}
.webf-button-xlarge .webf-button-caret.webf-button-caret-up, .webf-button.xlarge .webf-button-caret.webf-button-caret-up {
  bottom: 0.21rem;
}
.webf-button-xlarge.webf-button-icon-only, .webf-button-xlarge.icon-only, .webf-button.xlarge.webf-button-icon-only, .webf-button.xlarge.icon-only {
  height: 3.6rem;
  text-align: center;
  width: 3.6rem;
  padding: 0;
}
.webf-button-xlarge.webf-button-icon-only .webf-button-icon, .webf-button-xlarge.icon-only .webf-button-icon, .webf-button.xlarge.webf-button-icon-only .webf-button-icon, .webf-button.xlarge.icon-only .webf-button-icon {
  font-size: 1.56rem;
  line-height: 3.6rem;
  vertical-align: top;
}

.webf-buttons-group {
  display: inline-block;
  vertical-align: middle;
}
.webf-buttons-group .webf-buttons-group, .webf-buttons-group .webf-button {
  float: left;
}
.webf-buttons-group .webf-buttons-group:not(:first-child), .webf-buttons-group .webf-buttons-group:not(:first-child) .webf-button, .webf-buttons-group .webf-button:not(:first-child), .webf-buttons-group .webf-button:not(:first-child) .webf-button {
  margin-left: -1px;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.webf-buttons-group .webf-buttons-group:not(:last-child), .webf-buttons-group .webf-buttons-group:not(:last-child) .webf-button, .webf-buttons-group .webf-button:not(:last-child), .webf-buttons-group .webf-button:not(:last-child) .webf-button {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.webf-buttons-group .webf-buttons-group:not(:first-child):not(:last-child), .webf-buttons-group .webf-buttons-group:not(:first-child):not(:last-child) .webf-button, .webf-buttons-group .webf-button:not(:first-child):not(:last-child), .webf-buttons-group .webf-button:not(:first-child):not(:last-child) .webf-button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.webf-button-toggle.webf-button-active .d-inactive {
  display: none;
}
.webf-button-toggle.webf-button-active .d-active {
  display: inline-block;
}
.webf-button-toggle input[type=checkbox], .webf-button-toggle input[type=radio] {
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.webf-button-toggle .d-inactive {
  display: inline-block;
}
.webf-button-toggle .d-active {
  display: none;
}

.webf-wrapper-carousel {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.webf-wrapper-carousel .webf-viewport-carousel {
  position: relative;
  background: #fff;
  width: 100%;
}
.webf-wrapper-carousel .webf-viewport-carousel .webf-carousel li.webf-slide {
  list-style-type: none;
  position: relative;
  margin: 0;
}
.webf-wrapper-carousel .webf-viewport-carousel .webf-carousel li.webf-slide img {
  display: block;
  max-width: 100%;
}
.webf-wrapper-carousel a.webf-carousel-nav {
  cursor: pointer;
  outline: none;
  display: block;
  height: 32px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 32px;
  z-index: 9999;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url(/build/images/controls.fdcd37c5.png);
  display: block;
  text-decoration: none;
}
.webf-wrapper-carousel a.webf-carousel-nav.webf-prev {
  left: 10px;
  background-position: 0 -32px;
}
.webf-wrapper-carousel a.webf-carousel-nav.webf-prev:hover {
  background-position: 0 0;
}
.webf-wrapper-carousel a.webf-carousel-nav.webf-next {
  right: 10px;
  background-position: -43px -32px;
}
.webf-wrapper-carousel a.webf-carousel-nav.webf-next:hover {
  background-position: -43px 0;
}

.webf-checkboxwrapper.classic {
  box-sizing: border-box;
  border: 1px solid gray;
  color: #999;
  display: inline-block;
  vertical-align: text-bottom;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-color: #fff;
}
.webf-checkboxwrapper.classic.mousedown {
  background-color: #f1f1f1;
  -webkit-box-shadow: inset 0px 0px 3px 0 #ccc;
  -moz-box-shadow: inset 0px 0px 3px 0 #ccc;
  box-shadow: inset 0px 0px 3px 0 #ccc;
}
.webf-checkboxwrapper.classic a.checkbox {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 1px;
  margin: 0;
}
.webf-checkboxwrapper.classic.checked a.checkbox {
  background-image: url(/build/images/checkmark.d6cf82b1.png);
}
.webf-checkboxwrapper.classic.disabled {
  border-color: #ddd;
}
.webf-checkboxwrapper.classic.disabled.checked a.checkbox {
  background-image: url(/build/images/checkmark-disable.ddee4bc5.png);
}

.webf-checkboxwrapper.switch {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: text-bottom;
  width: 2rem;
  height: 1rem;
  cursor: pointer;
  background-color: transparent;
}
.webf-checkboxwrapper.switch a.checkbox {
  position: relative;
  border-radius: 1rem;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid darkgray;
  transition: background-color 200ms, border-color 200ms;
}
.webf-checkboxwrapper.switch a.checkbox:before {
  content: "";
  position: absolute;
  top: calc(0.25rem / 2 - 1px);
  left: 0.125rem;
  right: auto;
  width: 0.75rem;
  height: 0.75rem;
  background-color: darkgray;
  border-radius: 50%;
  transition: left 200ms, background-color 200ms;
}
.webf-checkboxwrapper.switch.checked a.checkbox {
  border-color: #4b70df;
  background-color: #4b70df;
}
.webf-checkboxwrapper.switch.checked a.checkbox:before {
  content: "";
  position: absolute;
  left: calc(2rem - 0.75rem - 0.25rem / 2 - 2px);
  width: 0.75rem;
  height: 0.75rem;
  background-color: white;
  border-radius: 50%;
}
.webf-checkboxwrapper.switch.disabled {
  border-color: #ddd;
}
.webf-checkboxwrapper.checkmark a.checkbox {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: transparent;
}
.webf-checkboxwrapper.checkmark.checked a.checkbox {
  color: #4b70df;
}
.webf-checkboxwrapper.checkmark.disabled.checked a.checkbox {
  color: #eee;
}

.webf-checkboxwrapper {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: text-bottom;
  cursor: pointer;
  line-height: 1rem;
  height: 1rem;
  flex-shrink: 0;
}
.webf-checkboxwrapper .checkbox {
  min-height: auto;
  margin: 0;
  padding: 0;
}

.webf-cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #555555;
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-family: Arial, sans-serif;
  padding: 5px 0;
  z-index: 100000000;
}
.webf-cookie-consent .message {
  margin: 5px 40px 10px;
}
.webf-cookie-consent .close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 0.6em;
}
.webf-cookie-consent .buttons .cookie-accept, .webf-cookie-consent .buttons .cookie-decline {
  margin: 0 5px;
}

.webf-dialog.webf-confirm {
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .webf-dialog.webf-confirm {
    height: 100vh;
  }
}
.webf-dialog.webf-confirm .webf-box-confirm {
  width: 500px;
  max-width: 90%;
}
.webf-dialog.webf-confirm .webf-box-confirm .box-body {
  text-align: center;
  font-size: 1.15rem;
  padding: 1.5rem 1rem;
  line-height: normal;
}
.webf-dialog.webf-confirm.confirm-opening .webf-box-confirm {
  animation-duration: 300ms;
}
@media (max-width: 992px) {
  .webf-dialog.webf-confirm.confirm-opening .webf-box-confirm {
    animation-name: scalein;
  }
}
@media (min-width: 993px) {
  .webf-dialog.webf-confirm.confirm-opening .webf-box-confirm {
    animation-name: slidein;
  }
}
.webf-dialog.webf-confirm.confirm-closing .webf-box-confirm {
  animation-duration: 150ms;
}
@media (max-width: 992px) {
  .webf-dialog.webf-confirm.confirm-closing .webf-box-confirm {
    animation-name: scaleout;
  }
}
@media (min-width: 993px) {
  .webf-dialog.webf-confirm.confirm-closing .webf-box-confirm {
    animation-name: slideout;
  }
}

@keyframes scalein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slidein {
  from {
    opacity: 0;
    margin-bottom: 30%;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes scaleout {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes slideout {
  from {
    opacity: 1;
    margin-bottom: 0;
  }
  to {
    opacity: 0;
    margin-bottom: 30%;
  }
}
.webf-datagrid {
  width: 100%;
  border: 1px solid #bbbbbb;
  position: relative;
  font-size: 13px;
  font-family: Arial, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.webf-datagrid * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.webf-datagrid .header {
  overflow: hidden;
  background-image: -owg-linear-gradient(white, #dbe2ee);
  background-image: -webkit-linear-gradient(white, #dbe2ee);
  background-image: -moz-linear-gradient(white, #dbe2ee);
  background-image: -o-linear-gradient(white, #dbe2ee);
  background-image: linear-gradient(white, #dbe2ee);
  color: #444;
  text-align: center;
  padding: 7px;
  min-height: 28px;
  white-space: nowrap;
}
.webf-datagrid .header.hidden {
  display: none;
}
.webf-datagrid > .toolbar {
  font-size: 0;
  background-color: #ededed;
  border-bottom: 1px solid #bbbbbb;
  color: #444;
  padding: 7px;
}
.webf-datagrid > .toolbar.hidden {
  display: none;
}
.webf-datagrid > .toolbar > .component {
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}
.webf-datagrid > .toolbar > .component .webf-button {
  font-size: 13px;
  vertical-align: top;
  height: 27px;
}
.webf-datagrid > .toolbar > .component.search {
  font-size: 0;
}
.webf-datagrid > .toolbar > .component.search input[type=text] {
  font-size: 13px;
  border: 1px solid #bbbbbb;
  padding: 3px 5px;
  height: 27px;
  width: 180px;
}
.webf-datagrid > .toolbar > .component select.selectColumns {
  display: none;
  width: 200px;
}
.webf-datagrid .columns .column, .webf-datagrid .columns .last-column {
  position: relative;
}
.webf-datagrid .columns .column .resizer, .webf-datagrid .columns .last-column .resizer {
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  width: 7px;
  cursor: col-resize;
  height: 100%;
  margin-left: -4px;
}
.webf-datagrid .columns .last-column {
  border-right: 0;
}
.webf-datagrid .columns, .webf-datagrid .footer {
  overflow: hidden;
}
.webf-datagrid .columns > table, .webf-datagrid .footer > table {
  background-image: -owg-linear-gradient(#fdfdfd, #e6e6e6);
  background-image: -webkit-linear-gradient(#fdfdfd, #e6e6e6);
  background-image: -moz-linear-gradient(#fdfdfd, #e6e6e6);
  background-image: -o-linear-gradient(#fdfdfd, #e6e6e6);
  background-image: linear-gradient(#fdfdfd, #e6e6e6);
  border-bottom: 1px solid #bbbbbb;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  vertical-align: top;
  border-collapse: collapse;
  border-spacing: 0;
}
.webf-datagrid .columns > table tr:last-child, .webf-datagrid .footer > table tr:last-child {
  border-right: 0;
}
.webf-datagrid .columns > table tr td, .webf-datagrid .footer > table tr td {
  border-right: 1px solid #bbbbbb;
}
.webf-datagrid .columns > table tr td > .col-wrapper > .column-name, .webf-datagrid .footer > table tr td > .col-wrapper > .column-name {
  display: block;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.webf-datagrid .columns > table tr td > .col-wrapper > .sort, .webf-datagrid .footer > table tr td > .col-wrapper > .sort {
  float: right;
  line-height: 28px;
  margin: 0 5px;
}
.webf-datagrid .columns.column-hidden, .webf-datagrid .footer.column-hidden {
  display: none;
}
.webf-datagrid .columns .last-column, .webf-datagrid .footer .last-column {
  border-right: 0;
}
.webf-datagrid .footer > table {
  background: none;
  border-top: 1px solid #bbbbbb;
  border-bottom: 0;
}
.webf-datagrid .footer > table tr td {
  background: #eef5eb;
}
.webf-datagrid .hscroll-grid {
  width: 100%;
  height: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.webf-datagrid .hscroll-grid > div {
  height: 0.1px;
}
.webf-datagrid .grid {
  background: #fff;
  overflow: auto;
  height: 336px;
}
.webf-datagrid .grid.has-empty-records {
  overflow-y: hidden;
}
.webf-datagrid .grid > table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
.webf-datagrid .grid > table tr.grid-size td {
  height: 0;
  border: 0;
}
.webf-datagrid .grid > table tr.record:nth-child(odd) {
  background-color: #e9eef9;
}
.webf-datagrid .grid > table tr.record.selected {
  background-color: #b9c7ec;
}
.webf-datagrid .grid > table tr.record td {
  border-right: 1px solid transparent;
}
.webf-datagrid .grid > table tr.record td.editable {
  line-height: normal;
  font-size: 0;
}
.webf-datagrid .grid > table tr.record td.editable input, .webf-datagrid .grid > table tr.record td.editable textarea, .webf-datagrid .grid > table tr.record td.editable select {
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: auto;
}
.webf-datagrid .grid > table tr.record td.editable.editing input, .webf-datagrid .grid > table tr.record td.editable.editing textarea, .webf-datagrid .grid > table tr.record td.editable.editing select {
  border-color: #4b70df !important;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgb(255, 255, 255);
}
.webf-datagrid .grid > table tr.record td.editable > textarea {
  color: inherit;
  font-family: inherit;
  border: 2px solid transparent;
  padding: 3px 5px 2px;
  resize: none;
  width: 100%;
  height: 30px;
  line-height: 21px;
  font-size: 13px;
  background-color: transparent;
}
.webf-datagrid .grid > table tr.record td.editable > textarea:focus, .webf-datagrid .grid > table tr.record td.editable > textarea:active {
  background-color: white;
}
.webf-datagrid .grid > table tr.record td.editable label.select {
  display: inline-flex;
  margin: 0;
  padding: 0;
  height: 30px;
  border: 0;
  width: 100%;
  position: relative;
  color: inherit;
  align-items: center;
}
.webf-datagrid .grid > table tr.record td.editable label.select select {
  display: block;
  font-size: 13px;
  width: 100%;
  height: 100%;
  padding: 0 24px 0 10px;
  background-color: transparent;
  border: 2px solid transparent;
  color: inherit;
  overflow: hidden;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.webf-datagrid .grid > table tr.record td.editable label.select select:focus, .webf-datagrid .grid > table tr.record td.editable label.select select:active {
  background-color: white;
}
.webf-datagrid .grid > table tr.record td.editable.editing label.select:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
  color: inherit;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 30px;
  height: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input {
  position: absolute;
  opacity: 0;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  transition-duration: 0.2s;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input ~ .checkmark {
  background-color: #fff;
  border: 1px solid #bbbbbb;
  border-radius: 15%;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:focus ~ .checkmark,
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:hover ~ .checkmark {
  border-color: #4b70df;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:checked ~ .checkmark {
  background-color: #4b70df;
  border: 0;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:checked:focus ~ .checkmark,
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:checked:hover ~ .checkmark {
  background-color: #2550d2;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.webf-datagrid .grid > table tr.record td.editable.cell-checkbox .webf-datagrid-checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-date, .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetime, .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datepicker, .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetimepicker {
  display: flex;
  align-items: center;
  height: 30px;
}
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-date input[type=text],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-date input[type=date],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-date input[type=datetime-local], .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetime input[type=text],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetime input[type=date],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetime input[type=datetime-local], .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datepicker input[type=text],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datepicker input[type=date],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datepicker input[type=datetime-local], .webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetimepicker input[type=text],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetimepicker input[type=date],
.webf-datagrid .grid > table tr.record td.editable > .webf-datagrid-datetimepicker input[type=datetime-local] {
  width: 100%;
  font-size: 0.8rem;
  padding: 3px 5px;
  color: #555;
  border: 1px solid #bbbbbb;
  font-family: Arial, sans-serif;
}
.webf-datagrid .grid > table tr.record td > .webf-datagrid-readonly {
  padding: 3px 5px 2px;
  border: 2px solid transparent;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.webf-datagrid .grid > table tr.record td.last-column {
  border-right: 0;
}
.webf-datagrid .grid > table tr.record td.last-column > div {
  width: 7px;
  margin-left: -7px;
  padding: 0;
}
.webf-datagrid .grid > table tr.record.row-hidden {
  display: none;
}
.webf-datagrid .grid:focus {
  outline: none;
}

.webf-dialog.webf-dialog-datetimepicker {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin-top: 5%;
  justify-content: center;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker {
  width: 480px;
  max-width: 90%;
  -webkit-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .webf-button {
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .title .webf-dropdown > .webf-select {
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.5rem;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .title .webf-dropdown > .webf-select > .select {
  font-size: 0.8rem;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .title .webf-dropdown > .webf-select > .select > .handle-down {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2rem;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .title .webf-dropdown:first-child {
  width: 130px;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .toolbar .title .webf-dropdown:last-child {
  width: 85px;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-week-days, .webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-datepicker {
  width: 100%;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-week-days th, .webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-datepicker th {
  height: 39px;
  line-height: 39px;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-week-days td, .webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-datepicker td {
  background: none;
}
.webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-week-days td > a, .webf-dialog.webf-dialog-datetimepicker .webf-datetimepicker .webf-datetimepicker-calendar .webf-tb-datepicker td > a {
  display: block;
  margin: auto;
  font-size: 0.9rem;
  text-decoration: none;
  text-align: center;
  padding: 0;
  width: 39px;
  height: 39px;
  line-height: 39px;
  cursor: pointer;
}

.webf-dropdown-datetimepicker.webf-datetimepicker {
  min-width: 250px;
  position: absolute;
  -webkit-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
}

.webf-datetimepicker {
  border: 1px solid #ccc;
  background: #fff;
  width: 250px;
  color: #333;
  font-family: Arial, helvetica, sans-serif;
}
.webf-datetimepicker .toolbar {
  display: flex;
  position: relative;
  height: auto;
  padding: 4px 3px;
  align-items: flex-start;
}
.webf-datetimepicker .toolbar .title {
  text-align: center;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
}
.webf-datetimepicker .toolbar .title > span {
  height: 27px;
  line-height: 27px;
}
.webf-datetimepicker .toolbar .title .webf-dropdown {
  display: inline-block;
  text-align: left;
  margin: 0;
}
.webf-datetimepicker .toolbar .title .webf-dropdown > .webf-select {
  padding: 4px 8px;
  background-image: none;
  border-color: #d5d5d5;
}
.webf-datetimepicker .toolbar .title .webf-dropdown > .webf-select.open {
  box-shadow: none;
}
.webf-datetimepicker .toolbar .title .webf-dropdown:first-child {
  width: 110px;
}
.webf-datetimepicker .toolbar .title .webf-dropdown:first-child > .webf-select {
  border-right: 0;
}
.webf-datetimepicker .toolbar .title .webf-dropdown:last-child {
  width: 65px;
}
.webf-datetimepicker .toolbar .title .webf-dropdown:last-child > .webf-select {
  border-left: 0;
}
.webf-datetimepicker .toolbar .title .webf-select-drop .webf-list-item > a,
.webf-datetimepicker .toolbar .title .webf-select-drop .webf-list-item > label {
  font-size: 0.8rem;
}
.webf-datetimepicker .toolbar .webf-button {
  height: 27px;
}
.webf-datetimepicker .week-days {
  margin: 0 5px;
}
.webf-datetimepicker .webf-tb-week-days, .webf-datetimepicker .webf-tb-datepicker {
  width: 240px;
  border-collapse: collapse;
  table-layout: fixed;
}
.webf-datetimepicker .webf-tb-week-days th, .webf-datetimepicker .webf-tb-week-days td, .webf-datetimepicker .webf-tb-datepicker th, .webf-datetimepicker .webf-tb-datepicker td {
  width: 25px;
  height: 25px;
  line-height: 25px;
}
.webf-datetimepicker .webf-tb-week-days td, .webf-datetimepicker .webf-tb-datepicker td {
  height: auto;
}
.webf-datetimepicker .webf-tb-week-days th, .webf-datetimepicker .webf-tb-datepicker th {
  font-weight: bold;
  text-align: center;
  font-size: 0.9em;
}
.webf-datetimepicker .webf-tb-week-days td, .webf-datetimepicker .webf-tb-datepicker td {
  background: none;
  padding: 1px;
}
.webf-datetimepicker .webf-tb-week-days td a, .webf-datetimepicker .webf-tb-datepicker td a {
  display: block;
  height: 100%;
  text-decoration: none;
  color: #666;
  text-align: center;
  font-size: 12px;
  line-height: 31px;
  padding: 0;
  cursor: pointer;
  border: 1px solid transparent;
}
.webf-datetimepicker .webf-tb-week-days td.other-month a, .webf-datetimepicker .webf-tb-datepicker td.other-month a {
  color: #bbb;
}
.webf-datetimepicker .webf-tb-week-days td.today a, .webf-datetimepicker .webf-tb-datepicker td.today a {
  background-color: #e3e8fa;
  color: #4b70df;
  border-radius: 50%;
}
.webf-datetimepicker .webf-tb-week-days td.current-date a, .webf-datetimepicker .webf-tb-datepicker td.current-date a {
  background-color: #4b70df;
  color: white;
  font-weight: bold;
  border-radius: 50%;
}
.webf-datetimepicker .webf-tb-week-days td.cur-month.sunday:not(.disabled):not(.today):not(.current-date), .webf-datetimepicker .webf-tb-datepicker td.cur-month.sunday:not(.disabled):not(.today):not(.current-date) {
  color: #ff4d4d;
}
.webf-datetimepicker .webf-tb-week-days td:not(.disabled):not(.today):not(.current-date):not(.start):not(.end) a:hover, .webf-datetimepicker .webf-tb-datepicker td:not(.disabled):not(.today):not(.current-date):not(.start):not(.end) a:hover {
  border-color: #4b70df;
  background-color: #f8fafe;
  color: #4b70df;
}
.webf-datetimepicker .webf-tb-week-days td:not(.disabled):not(.today):not(.current-date).start a:hover, .webf-datetimepicker .webf-tb-week-days td:not(.disabled):not(.today):not(.current-date).end a:hover, .webf-datetimepicker .webf-tb-datepicker td:not(.disabled):not(.today):not(.current-date).start a:hover, .webf-datetimepicker .webf-tb-datepicker td:not(.disabled):not(.today):not(.current-date).end a:hover {
  border-color: #4b70df;
  background-color: #7692e7;
  color: white;
}
.webf-datetimepicker .webf-tb-week-days td.hidden-day, .webf-datetimepicker .webf-tb-datepicker td.hidden-day {
  visibility: hidden;
}
.webf-datetimepicker .webf-tb-week-days td.disabled a, .webf-datetimepicker .webf-tb-datepicker td.disabled a {
  cursor: default;
  background-color: #fff;
  color: silver;
}
.webf-datetimepicker .webf-tb-week-days td.selected a, .webf-datetimepicker .webf-tb-datepicker td.selected a {
  background-color: #cdd7f6;
  border-color: #cdd7f6;
  color: #4b70df;
}
.webf-datetimepicker .webf-tb-week-days td.start a, .webf-datetimepicker .webf-tb-week-days td.end a, .webf-datetimepicker .webf-tb-datepicker td.start a, .webf-datetimepicker .webf-tb-datepicker td.end a {
  background-color: #4b70df;
  border-color: black;
  color: white;
}
.webf-datetimepicker .webf-datetimepicker-calendar {
  width: 100%;
  overflow: hidden;
}
.webf-datetimepicker .webf-datetimepicker-calendar > ul.ul-calendars {
  width: 200%;
  overflow: hidden;
  height: 100%;
  left: 0;
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.webf-datetimepicker .webf-datetimepicker-calendar > ul.ul-calendars > li {
  float: left;
  width: 50%;
}
.webf-datetimepicker .timepicker {
  padding: 0 0.5rem;
  color: #555;
}
.webf-datetimepicker .timepicker .timepicker-slider {
  padding: 5px 0 5px 10px;
}
.webf-datetimepicker .timepicker .timepicker-slider .hours, .webf-datetimepicker .timepicker .timepicker-slider .minutes, .webf-datetimepicker .timepicker .timepicker-slider .seconds {
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
}
.webf-datetimepicker .timepicker .timepicker-slider label {
  display: block;
  float: left;
}
.webf-datetimepicker .timepicker .timepicker-slider .webf-slider {
  margin: 0 6px 0 75px;
  top: 5px;
}
.webf-datetimepicker .timepicker .time {
  margin: 5px 0;
}
.webf-datetimepicker .timepicker .time .text {
  font-size: 18px;
  text-align: right;
  padding-right: 5px;
  font-weight: bold;
}
.webf-datetimepicker .timepicker .timepicker-spinner label {
  display: block;
  float: left;
  height: 20px;
  line-height: 20px;
  margin-left: 7px;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px 0;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-input-text {
  text-align: center;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-spinner {
  float: left;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-spinner .webf-input-spinner {
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #bbbbbb;
  border-right: 0;
  color: #555;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-spinner .webf-spin-btn {
  color: #555;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-spinner .webf-spin-btn.up {
  border-bottom: 0;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .webf-spinner .webf-spin-btn.down {
  border-top: 0;
}
.webf-datetimepicker .timepicker .timepicker-spinner .spinners .time-separator {
  display: inline-block;
  float: left;
  height: 20px;
  margin: 0 5px;
}
.webf-datetimepicker .timepicker .timepicker-select {
  overflow: hidden;
  *zoom: 1;
}
.webf-datetimepicker .timepicker .timepicker-select label {
  display: block;
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 3px 0 0 7px;
}
.webf-datetimepicker .timepicker .timepicker-select .selects {
  float: right;
  margin: 5px 5px 5px 0;
}
.webf-datetimepicker .timepicker .timepicker-select select {
  float: left;
  width: 44px;
}
.webf-datetimepicker .timepicker .timepicker-select .time-separator {
  display: inline-block;
  float: left;
  height: 20px;
  margin: 0 5px;
}
.webf-datetimepicker .buttons {
  float: right;
  padding: 7px 5px;
}
.webf-datetimepicker .buttons .webf-button {
  float: left;
  margin-left: 0.6rem;
}

.webf-overlay-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.webf-dialog {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  outline: 0;
}
.webf-dialog.dialog-responsive {
  width: 728px;
  top: auto;
  left: auto;
  right: auto;
}
@media screen and (max-width: 768px) {
  .webf-dialog.dialog-responsive {
    left: 20px !important;
    right: 20px !important;
    width: auto !important;
  }
}
.webf-dialog > .webf-box {
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.webf-dialog > .webf-box .webf-dialog-submit {
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.webf-dialog > .webf-box .box-header {
  display: flex;
  justify-content: space-between;
}
.webf-dialog > .webf-box .box-header.draggable {
  cursor: move;
}
.webf-dialog > .webf-box .box-header .wrap-buttons .webf-button {
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.webf-dialog > .webf-box .box-header .wrap-buttons .webf-button i.webf {
  font-size: 11px;
}
.webf-dialog > .webf-box .box-footer {
  overflow: hidden;
  *zoom: 1;
}
.webf-dialog > .webf-box .box-footer .wrapper {
  float: right;
}
.webf-dialog > .webf-box .box-footer .wrapper .webf-button {
  float: left;
  margin-left: 5px;
}
.webf-dialog.maximized {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
.webf-dialog.maximized .webf-box {
  height: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.webf-dialog.maximized .webf-box .box-body {
  overflow: auto;
}
.webf-dialog.maximized .webf-box .box-header {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.webf-dialog.maximized .webf-box .box-footer {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.webf-draggable {
  transition-duration: 250ms;
  transition-property: opacity;
}
.webf-draggable.dragging {
  opacity: 0.3;
}

.webf-droppable.hover {
  border-style: dashed;
  border-color: black;
}

.webf-draghandler {
  pointer-events: none;
  display: flex;
  align-items: center;
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e8f0fe;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  color: #1967d2;
  border-radius: 5px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  transition-property: width, height;
  transition-duration: 200ms;
}
.webf-draghandler > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.webf-draghandler.positionning {
  transition-property: width, height, top, left, transform;
}
.webf-draghandler.positionning.fast {
  transition-duration: 100ms;
  transition-property: top, left;
}
.webf-draghandler.positionning.fast:not(:last-child) {
  box-shadow: none;
}
.webf-draghandler.dragging {
  width: 200px !important;
  height: 42px !important;
}

.webf-dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow-x: unset;
  margin-bottom: 0;
}
.webf-dropdown .webf-dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  right: auto;
  top: 100%;
  z-index: 1000;
  border: 1px solid #bbbbbb;
  background-color: #fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
}
.webf-dropdown.alignmenu-up .webf-dropdown-menu {
  bottom: 100%;
  top: auto;
}
.webf-dropdown.alignmenu-right .webf-dropdown-menu {
  right: 0;
  left: auto;
}
.webf-dropdown.open {
  overflow-x: visible;
  margin-bottom: 0;
}
.webf-dropdown.open .webf-dropdown-menu {
  display: block;
}

.webf-buttons-group .webf-dropdown {
  display: inline-block;
}
.webf-buttons-group .webf-dropdown .webf-dropdown-menu {
  left: -1px;
}
.webf-buttons-group .webf-dropdown.alignmenu-right .webf-dropdown-menu {
  left: auto;
}

.webf-dropdown-slider {
  position: absolute;
  background: none;
  border: 0;
  float: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#ged.grid .elements {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}
@media screen and (max-width: 991px) {
  #ged.grid .elements {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 767px) {
  #ged.grid .elements {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
#ged.grid .elements .element {
  position: relative;
  height: 12rem;
  border: 1px solid #add8e6;
  margin: 0.25rem;
  min-width: 0;
}
#ged.grid .elements .element .preview {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  font-size: 3rem;
  color: #64b5f6;
}
#ged.grid .elements .element .preview img {
  max-width: 100%;
  max-height: 100%;
}
#ged.grid .elements .element .wrap-infos {
  display: flex;
  flex-flow: wrap;
  flex: 1 1 100%;
  min-width: 0;
}
#ged.grid .elements .element .wrap-infos .name-element {
  flex: 1 1 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
}
#ged.grid .elements .element .wrap-infos .name-element .name {
  font-weight: bold;
  font-size: 0.85rem;
  margin: 0 0.25rem;
  padding: 0 4px;
  border: 1px solid transparent;
  overflow: hidden;
}
#ged.grid .elements .element .wrap-infos .filesize {
  position: absolute;
  bottom: 0.5rem;
  right: 2.3rem;
  font-size: 0.7rem;
  font-style: italic;
  text-align: right;
}
#ged.grid .elements .element .wrap-infos .date-creation {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
}
#ged.grid .elements .element .actions {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #999;
}
#ged.grid .elements .element .actions .ellipsis {
  margin-right: 0;
  padding: 0 1rem;
  font-size: 1.6rem;
  cursor: pointer;
}

#ged.list .elements {
  display: flex;
  flex-flow: row wrap;
}
#ged.list .elements .element {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 4rem;
}
#ged.list .elements .element .preview {
  padding-left: 1rem;
  flex: 1 1 6rem;
  align-content: center;
  justify-content: center;
  font-size: 2.5rem;
  text-align: center;
  color: #64b5f6;
}
#ged.list .elements .element .preview img {
  display: block;
  margin: auto;
  max-width: 95%;
  max-height: 3.8rem;
}
#ged.list .elements .element .wrap-infos {
  display: flex;
  flex-flow: wrap;
  flex: 1 1 100%;
  min-width: 0;
  margin-left: 0.3rem;
}
#ged.list .elements .element .wrap-infos .name-element {
  flex: 1 1 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#ged.list .elements .element .wrap-infos .name-element .name {
  font-size: 0.8rem;
  font-weight: bold;
  padding-left: 4px;
  border: 1px solid transparent;
}
#ged.list .elements .element .wrap-infos .filesize {
  flex: 1 1 20px;
  padding-left: 4px;
  font-size: 0.7rem;
  font-style: italic;
}
#ged.list .elements .element .wrap-infos .date-creation {
  flex: 1 1 20px;
  font-size: 0.7rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  text-align: right;
}
#ged.list .elements .element .actions {
  color: #999;
}
#ged.list .elements .element .actions .ellipsis {
  font-size: 1.6rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

nav.ged {
  display: flex;
  flex-flow: row nowrap;
  line-height: 2.5rem;
  justify-content: flex-start;
}
nav.ged ol.breadcrumb {
  height: 2.5rem;
  line-height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
nav.ged ol.breadcrumb li {
  color: #eee;
}
nav.ged ol.breadcrumb li::before {
  color: #ddd;
}
nav.ged ol.breadcrumb li a {
  font-weight: bold;
  color: #eee;
}
nav.ged ol.breadcrumb li a.home {
  font-size: 1.2rem;
}
nav.ged ol.breadcrumb li a:hover {
  color: #fff;
}
nav.ged .buttons-navbar {
  display: flex;
  font-size: 1.3rem;
  color: #fff;
}
nav.ged .buttons-navbar .btn-display,
nav.ged .buttons-navbar .btn-actions {
  padding: 0 0.8rem;
  margin: 0 0.2rem;
  cursor: pointer;
}
nav.ged .buttons-navbar .btn-display.active {
  background-color: #1b93f3;
}

#ged {
  /* Webf Scrollbar */
}
#ged .wrap-elements .webf-scrollbox-container {
  width: 100%;
}
#ged .wrap-elements .webf-scrollbox-wrapper:hover .webf-scrollbar, #ged .wrap-elements .webf-scrollbox-wrapper.dragging .webf-scrollbar {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
}
@media screen and (max-width: 767px) {
  #ged .wrap-elements .webf-scrollbox-wrapper:hover .webf-scrollbar, #ged .wrap-elements .webf-scrollbox-wrapper.dragging .webf-scrollbar {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
  }
}
#ged .wrap-elements .webf-scrollbox-wrapper .webf-scrollbar.y {
  width: 5px;
  background: transparent;
}
#ged .wrap-elements .webf-scrollbox-wrapper .webf-scrollbar.y .handler {
  background: #2373d9;
}

#ged.thumbnails .elements {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}
@media screen and (max-width: 991px) {
  #ged.thumbnails .elements {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 767px) {
  #ged.thumbnails .elements {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
#ged.thumbnails .elements .element {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 3rem;
  border: 1px solid #add8e6;
  margin: 0.5rem 0.3rem;
  min-width: 0;
}
#ged.thumbnails .elements .element .preview {
  display: flex;
  align-items: center;
  flex: 1 1 20px;
  justify-content: center;
  color: #64b5f6;
  padding: 3px;
  font-size: 2rem;
}
#ged.thumbnails .elements .element .preview img {
  max-width: 100%;
  max-height: 100%;
}
#ged.thumbnails .elements .element .wrap-infos {
  flex: 1 1 50%;
  flex-flow: nowrap;
  min-width: 0;
}
#ged.thumbnails .elements .element .wrap-infos .name-element {
  flex: 1 1 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#ged.thumbnails .elements .element .wrap-infos .name-element .name {
  font-weight: bold;
  font-size: 0.85rem;
  margin: 0 0.25rem;
  padding: 0 4px;
  border: 1px solid transparent;
  overflow: hidden;
}
#ged.thumbnails .elements .element .wrap-infos .filesize {
  display: none;
}
#ged.thumbnails .elements .element .wrap-infos .date-creation {
  display: none;
}
#ged.thumbnails .elements .element .actions {
  width: 2rem;
}
#ged.thumbnails .elements .element .actions .ellipsis {
  font-size: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.webf-filemanager {
  width: 100%;
}
.webf-filemanager .btn-new {
  width: 100%;
}
.webf-filemanager .wrap-elements {
  overflow-y: auto;
  height: calc(100vh - 3.5rem - 2.5rem);
}
.webf-filemanager .wrap-elements .elements .element {
  background: #fff;
}
.webf-filemanager .wrap-elements .elements .element .name-element {
  color: #5b636a;
}
.webf-filemanager .wrap-elements .elements .element .filesize {
  color: #808080;
}
.webf-filemanager .wrap-elements .elements .element .date-creation {
  color: #5F5F5F;
}
.webf-filemanager .wrap-elements .elements .element:hover {
  background-color: #f9f9f9;
}
.webf-filemanager .wrap-elements .elements .element.selected {
  background-color: #ECECEC;
}
.webf-filemanager .wrap-elements .elements .element.selected .filesize {
  color: #333;
}
.webf-filemanager .wrap-elements .elements .element.selected .date-creation {
  color: #212121;
}
.webf-filemanager .wrap-elements .elements .element.selected .wrap-infos .name-element .name {
  color: #333;
}
.webf-filemanager .wrap-elements .elements .element.selected .wrap-infos .name-element .name.editable {
  border-color: #f5f5f5;
  background-color: #fff;
  outline: none;
  color: #333;
}
.webf-filemanager .wrap-elements .elements .element.selected .wrap-infos .name-element .name::selection {
  background: #a8d1ff;
}
.webf-filemanager .wrap-elements .elements .element.selected .wrap-infos .name-element .name::-moz-selection {
  background: #a8d1ff;
}
.webf-filemanager .wrap-elements .elements .element.selected .actions {
  color: #777;
}

.webf-agenda {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  font-family: arial, Helvetica, sans-serif;
}
.webf-agenda table {
  border-collapse: collapse;
}
.webf-agenda table tr {
  vertical-align: top;
}
.webf-agenda * {
  box-sizing: content-box;
}
.webf-agenda.agenda-day .days {
  display: none;
}
.webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y {
  width: 8px;
}
.webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y, .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y:hover {
  opacity: 1;
  background: none;
}
.webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler {
  width: 80%;
  background: #555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler:hover {
  background: #000;
}

.webf-agenda > .toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #e8eff3;
}
.webf-agenda > .toolbar > .buttons {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .webf-agenda > .toolbar > .buttons .webf-button {
    border-radius: 0.1875rem;
    font-size: 0.75rem;
    padding: 0.225rem 0.75rem;
  }
}
.webf-agenda > .toolbar > .title {
  text-align: center;
  font-size: 16px;
  margin-top: 5px;
  width: 100%;
}
@media (max-width: 991px) {
  .webf-agenda > .toolbar > .title {
    display: none;
  }
}
.webf-agenda > .toolbar > .views {
  flex-shrink: 0;
}
.webf-agenda > .toolbar > .views .webf-button {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.webf-agenda > .toolbar > .views .webf-button.mouseover, .webf-agenda > .toolbar > .views .webf-button.focus {
  border-color: #CCCCCC;
}
.webf-agenda > .toolbar > .views .webf-button.selected {
  background: #ccc;
  background: #4b70df;
  color: #fff;
  filter: none;
  border-color: #aaa;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}
.webf-agenda > .toolbar > .views .webf-button.prev-week, .webf-agenda > .toolbar > .views .webf-button.next-week {
  padding-left: 14px;
  padding-right: 14px;
}
.webf-agenda > .toolbar > .views .webf-button.prev-week {
  padding-left: 9px;
  padding-right: 9px;
}
.webf-agenda > .toolbar > .views .webf-button.next-week {
  padding-left: 9px;
  padding-right: 9px;
}
.webf-agenda > .toolbar > .views .webf-button.today {
  font-size: 14px;
}
.webf-agenda > .toolbar > .views .webf-button.datepicker > span {
  margin-left: 0.6rem;
}
@media (max-width: 768px) {
  .webf-agenda > .toolbar > .views .webf-button.datepicker > span {
    display: none;
  }
}

.webf-agenda .days {
  padding: 10px 0 5px;
  background: #e8eff3;
}
.webf-agenda .days .tb-days {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.webf-agenda .days .tb-days .td-day a.link-days {
  color: #555;
  font-size: 12px;
}
.webf-agenda .days .tb-days .td-day a.link-days.disabled {
  cursor: default;
}
.webf-agenda .days .tb-days .td-day a.link-days.disabled:hover {
  text-decoration: none;
}

.webf-agenda .days .tb-days .td-hours,
.webf-agenda .users .tb-users .td-hours {
  width: 45px;
}

.webf-agenda .users {
  padding: 2px 0;
  background: #e8eff3;
}

.webf-agenda .users .tb-users {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}

.webf-agenda .users .tb-users td .user {
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
  /*   text-overflow: ellipsis; */
}

.webf-agenda .allday {
  min-height: 24px;
  /*   height: 24px; */
  border: 0;
  border-top: 1px solid #ccc;
  background: #ccc;
  border-bottom: 3px double #808080;
  position: relative;
}

.webf-agenda .allday .tb-grid {
  width: 100%;
  height: 24px;
}
.webf-agenda .allday .td-first {
  width: 45px;
  background-color: #e8eff3;
}
.webf-agenda .allday .td-col-days {
  background-color: #fff;
  display: block;
  height: 24px; /* à déterminer en js */
  position: relative;
}
.webf-agenda .allday .td-col-days .tb-days {
  width: 100%;
  table-layout: fixed;
  height: 100%;
}
.webf-agenda .allday .td-col-days .tb-days .td-col-allday {
  border-right: 1px solid #808080;
}
.webf-agenda .allday .td-col-days .tb-days .td-col-allday.hover {
  background: black;
  opacity: 0.07;
  filter: alpha(opacity=5);
}
.webf-agenda .allday .td-col-days .tb-days .td-col-allday:last-child {
  border-right: 0;
}
.webf-agenda .allday .td-col-days .tb-events {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  table-layout: fixed;
}
.webf-agenda .allday .td-col-days .tb-events .event {
  border: 1px solid #5F94D2;
  background-color: #68A1E5;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 12px;
  line-height: 12px;
  padding: 1px 2px;
  font-size: 11px;
  margin: 1px 2px 1px 1px;
  cursor: default;
}
.webf-agenda .grid {
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  overflow: auto;
}
.webf-agenda .grid .tb-grid {
  width: 100%;
  table-layout: fixed;
}
.webf-agenda .grid .tb-grid .td-hours {
  width: 45px;
  background: #e8eff3;
  /*   border-bottom: 1px solid #aaa; */ /* crée un décalage sur chrome! */
}
.webf-agenda .grid .tb-grid .td-hours span.hour {
  display: block;
  float: right;
  margin: 0 5px 0 0;
  font-size: 13px;
}
.webf-agenda .grid .tb-grid .td-hours .border-bottom {
  position: relative;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ccc;
  font-size: 0;
}
.webf-agenda .grid .tb-grid .timeslot {
  /*   height: 20px; */
  border-bottom: 1px dotted #ddd;
}
.webf-agenda .grid .tb-grid .timeslot.hour {
  border-bottom: 1px solid #ccc;
}
.webf-agenda .grid .agenda {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.webf-agenda .grid .agenda .tb-agenda {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-agenda .grid .agenda .tb-agenda .td-hours {
  width: 45px;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day {
  border-right: 1px solid #808080;
  height: 100%;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day:last-child {
  border-right: 0;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full {
  position: relative;
  height: 100%;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .hour-marker {
  position: absolute;
  width: 100%;
  background-color: red;
  height: 1px;
  top: 0;
  left: 0;
  display: none;
  z-index: 2;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td {
  border-right: 1px dashed #D3D3D3;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td .col-user {
  position: relative;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td:last-child {
  border: 0;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event {
  box-sizing: border-box;
  width: 100%;
  background: #68A1E5;
  color: #fff;
  position: absolute;
  left: 0;
  cursor: default;
  overflow: hidden;
  z-index: 1;
  border-bottom: 1px solid #5f94d2;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title {
  /*   border: 1px solid #1B62C0; */
  /*   height: 15px; */
  min-height: 15px;
  line-height: 15px;
  font-size: 10px;
  /*   text-align: center; */
  text-align: left;
  background: #2B72D0;
  /*   white-space: nowrap; */
  /*   overflow: hidden; */
  text-indent: 2px;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title {
  /*   cursor: move; */
}
.webf-agenda .grid .agenda .tb-agenda .tb-agenda-days {
  width: 100%;
  table-layout: fixed;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .content {
  /*   text-align: center; */
  text-align: left;
  overflow: hidden;
  padding: 3px 1px 0;
  font-size: 11px;
  line-height: 13px;
  height: 100%;
  border: 1px solid #5f94d2;
  border-top: 0;
  word-wrap: break-word;
  width: 100%;
}
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .handle-resizable {
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  text-align: center;
  font-size: 9px;
  height: 10px;
  line-height: 8px;
  background: none;
  cursor: s-resize;
}
.webf-agenda .allday .tb-grid .td-col-days .tb-events .event.temporary,
.webf-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event.temporary {
  opacity: 0.6 !important;
  filter: alpha(opacity=60);
}
.webf-agenda .tb-grid .td-day-month {
  border: 1px solid #ccc;
}
.webf-agenda .tb-grid .td-day-month.drop {
  background: #f2f5ff;
}
.webf-agenda .tb-grid .td-day-month.drop .cell-day .num-day {
  background-color: #e2e9ff;
}
.webf-agenda .tb-grid .td-day-month.first-row {
  border-top: 0;
}
.webf-agenda .tb-grid .td-day-month.last-row {
  border-bottom: 0;
}
.webf-agenda .tb-grid .td-day-month.first-col {
  border-left: 0;
}
.webf-agenda .tb-grid .td-day-month.last-col {
  border-right: 0;
}
.webf-agenda .tb-grid .td-day-month .cell-day {
  position: relative;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16px;
  line-height: 16px;
  text-align: right;
  overflow: hidden;
  background: #f5f5f5;
  color: #aaa;
  font-size: 11px;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day a {
  color: #aaa;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day a.disabled {
  cursor: default;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day a.disabled:hover {
  text-decoration: none;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day span {
  display: block;
  padding-right: 4px;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day.current {
  color: #444;
  font-weight: bold;
}
.webf-agenda .tb-grid .td-day-month .cell-day .num-day.current a {
  color: #444;
}
.webf-agenda .tb-grid .td-day-month .cell-day .events {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 18px;
  left: 0;
}
.webf-agenda .tb-grid .td-day-month .cell-day .events .event {
  color: #fff;
  /*   cursor: pointer; */
}
.webf-agenda .tb-grid .td-day-month .cell-day .events .event .title {
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
}
.webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y {
  width: 8px;
}
.webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y, .webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y:hover {
  opacity: 1;
  background: none;
}
.webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler {
  width: 80%;
  background: #555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler:hover {
  background: #000;
}
@media (max-width: 768px) {
  .webf-agenda .webf-agenda .webf-scrollbox-wrapper .webf-scrollbar.y {
    display: none;
  }
}
.webf-agenda .webf-agenda-user {
  position: relative;
}
.webf-agenda .webf-agenda-tooltip-user .triangle {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #ccc;
  position: relative;
  top: -6px;
  margin: auto;
  z-index: 10001;
}
.webf-agenda .webf-agenda-tooltip-user .triangle .inner-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #fff;
  top: 1px;
  left: -6px;
}
.webf-agenda .webf-agenda-tooltip-user .content {
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  padding: 5px 10px;
  position: relative;
  top: -6px;
  z-index: 10000;
  box-shadow: 2px 2px 5px #ddd;
}

.webf-agenda-dragging-event .title {
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
  color: #fff;
}

.webf-image-crop {
  background: none;
  display: block;
  line-height: 0;
  position: relative;
  cursor: crosshair;
}
.webf-image-crop .outer-selection {
  position: absolute;
  background: #000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3;
}
.webf-image-crop .outer-selection.nw {
  top: 0px;
  left: 0px;
}
.webf-image-crop .outer-selection.n {
  top: 0px;
}
.webf-image-crop .outer-selection.ne {
  top: 0px;
  right: 0px;
}
.webf-image-crop .outer-selection.e {
  right: 0px;
}
.webf-image-crop .outer-selection.se {
  bottom: 0px;
  right: 0px;
}
.webf-image-crop .outer-selection.s {
  bottom: 0px;
}
.webf-image-crop .outer-selection.sw {
  bottom: 0px;
  left: 0px;
}
.webf-image-crop .outer-selection.w {
  left: 0px;
}
.webf-image-crop .selection-border {
  position: absolute;
  background-color: transparent;
  background-image: url("data:image/gif;base64,R0lGODlhCAAIAJEAAKqqqv///wAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQJCgAAACwAAAAACAAIAAACDZQFCadrzVRMB9FZ5SwAIfkECQoAAAAsAAAAAAgACAAAAg+ELqCYaudeW9ChyOyltQAAIfkECQoAAAAsAAAAAAgACAAAAg8EhGKXm+rQYtC0WGl9oAAAIfkECQoAAAAsAAAAAAgACAAAAg+EhWKQernaYmjCWLF7qAAAIfkECQoAAAAsAAAAAAgACAAAAg2EISmna81UTAfRWeUsACH5BAkKAAAALAAAAAAIAAgAAAIPFA6imGrnXlvQocjspbUAACH5BAkKAAAALAAAAAAIAAgAAAIPlIBgl5vq0GLQtFhpfaIAACH5BAUKAAAALAAAAAAIAAgAAAIPlIFgknq52mJowlixe6gAADs=");
  background-position: 0 0;
  background-repeat: repeat;
  font-size: 0;
  display: none;
}
.webf-image-crop .selection-border.n, .webf-image-crop .selection-border.s {
  height: 1px;
}
.webf-image-crop .selection-border.e, .webf-image-crop .selection-border.w {
  width: 1px;
}
.webf-image-crop .selected-area {
  position: absolute;
  cursor: move;
  display: none;
  background: #fff;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}
.webf-image-crop .handle {
  position: absolute;
  background-color: #333;
  border: 1px solid #eee;
  font-size: 1px;
  height: 8px;
  width: 8px;
  margin: -4px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
  display: none;
}
.webf-image-crop .handle.nw {
  cursor: nw-resize;
}
.webf-image-crop .handle.n {
  cursor: n-resize;
}
.webf-image-crop .handle.ne {
  cursor: ne-resize;
}
.webf-image-crop .handle.e {
  cursor: e-resize;
}
.webf-image-crop .handle.se {
  cursor: se-resize;
}
.webf-image-crop .handle.s {
  cursor: s-resize;
}
.webf-image-crop .handle.sw {
  cursor: sw-resize;
}
.webf-image-crop .handle.w {
  cursor: w-resize;
}

.webf-lightbox {
  position: fixed;
  overflow: hidden;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.webf-lightbox.closing {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.webf-lightbox.open {
  opacity: 1;
  width: 100%;
  height: 100%;
}
.webf-lightbox .webf-lightbox-nav {
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-decoration: none;
}
@media (max-width: 991px) {
  .webf-lightbox .webf-lightbox-nav {
    width: 2.25rem;
    height: 2.25rem;
  }
}
.webf-lightbox .webf-lightbox-nav:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2.4rem;
}
@media (max-width: 991px) {
  .webf-lightbox .webf-lightbox-nav:after {
    font-size: 1.8rem;
  }
}
.webf-lightbox .webf-lightbox-nav.prev {
  left: 2rem;
}
@media (max-width: 991px) {
  .webf-lightbox .webf-lightbox-nav.prev {
    left: 0.4rem;
  }
}
.webf-lightbox .webf-lightbox-nav.prev:after {
  content: "\f053";
}
.webf-lightbox .webf-lightbox-nav.next {
  right: 2rem;
}
@media (max-width: 991px) {
  .webf-lightbox .webf-lightbox-nav.next {
    right: 0.4rem;
  }
}
.webf-lightbox .webf-lightbox-nav.next:after {
  content: "\f054";
}
.webf-lightbox .webf-lightbox-buttons {
  position: absolute;
  width: 100%;
  background: none;
  top: 0;
  padding-right: 8px;
}
.webf-lightbox .webf-lightbox-buttons .webf-lightbox-button {
  margin: 5px 2px;
  float: right;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  border-radius: 40px;
  font-weight: bold;
  background-color: black;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
}
.webf-lightbox .webf-lightbox-buttons .webf-lightbox-button:hover {
  background: #555;
}
.webf-lightbox .webf-lightbox-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-container {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  height: 100%;
  max-height: 100%;
  width: calc(var(--n) * 100%);
  transform: translate(calc(var(--i, 0) / var(--n, 0) * -100% + var(--tx, 0px)));
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-container.smooth {
  transition: transform calc(var(--f, 1) * var(--dt, 500ms)) ease-out;
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-container .item-container {
  height: 95%;
  margin: 0 calc(2.5% / var(--n));
  width: calc(95% / var(--n));
  display: flex;
  align-items: center;
  justify-content: center;
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-container .item-container img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-container .item-container iframe {
  width: calc(90% / var(--n));
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-caption {
  font-size: 1rem;
  color: white;
  text-align: center;
  background: black;
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  opacity: 1;
  line-height: 18px;
}
.webf-lightbox .webf-lightbox-wrapper .webf-lightbox-caption > .text {
  padding: 0.8rem;
}
.webf-lightbox .webf-lightbox-loading {
  background: url(/build/images/loading.82991312.gif) center center no-repeat;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.webf-lightbox iframe {
  width: 530px;
  height: 95%;
  max-height: 100%;
  position: absolute;
  border: 0;
}
.webf-lightbox:not(.open) iframe {
  display: none;
}

.webf-lightbox-img-preloaded,
.webf-lightbox-iframe-preloaded {
  position: absolute !important;
  top: -99999px !important;
  left: -99999px !important;
}

.webf-list-entities {
  background: white;
}
.webf-list-entities .webf-head-entities {
  display: flex;
  padding-right: 1rem;
  align-items: flex-end;
}
.webf-list-entities .webf-head-entities .webf-head {
  color: #b5b5c3;
  padding: 0.4rem 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.webf-list-entities .webf-head-entities .webf-head.--toggle-details {
  flex-shrink: 0;
  width: 2.5rem;
  padding: 0 1rem;
}
@media (max-width: 767px) {
  .webf-list-entities .webf-head-entities .webf-head.--toggle-details {
    width: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.webf-list-entities .webf-head-entities .webf-head.--h5 {
  width: 100%;
  padding-right: 1rem;
}
.webf-list-entities .webf-head-entities .webf-head.--date-entree {
  width: 200px;
  flex-shrink: 0;
}
.webf-list-entities .webf-head-entities .webf-head.--buttons {
  width: 125px;
  flex-shrink: 0;
  text-align: right;
}
.webf-list-entities > .webf-head-entities ~ .webf-entity, .webf-list-entities > .webf-entity:first-child {
  border-top: 1px solid #E3E7EA;
}
.webf-list-entities > .webf-head-entities ~ .webf-entity ~ .webf-entity {
  border-top: 0;
}
.webf-list-entities > .webf-entity {
  padding: 0;
  font-size: 0.8rem;
  color: #555;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #E3E7EA;
}
.webf-list-entities > .webf-entity > .webf-header {
  overflow: initial;
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0.5rem 1rem;
  transition: color 250ms, background-color 300ms;
}
@media (max-width: 767px) {
  .webf-list-entities > .webf-entity > .webf-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.webf-list-entities > .webf-entity > .webf-header > .toggle-details {
  width: 2.5rem;
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 0 1rem;
  font-size: 1rem;
  color: #4b70df;
  transition: 300ms transform;
}
@media (max-width: 767px) {
  .webf-list-entities > .webf-entity > .webf-header > .toggle-details {
    width: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.webf-list-entities > .webf-entity > .webf-header > .toggle-details.unfolding {
  transform: rotate(90deg) !important;
}
.webf-list-entities > .webf-entity > .webf-header > .toggle-details.folding {
  transform: rotate(0deg) !important;
}
.webf-list-entities > .webf-entity > .webf-header > h5, .webf-list-entities > .webf-entity > .webf-header > a {
  line-height: inherit;
  color: #555;
  width: 100%;
  padding-right: 1rem;
  font-size: 0.8rem;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.webf-list-entities > .webf-entity > .webf-header > h5 > a {
  text-decoration: none;
  color: #555;
}
.webf-list-entities > .webf-entity > .webf-header > h5 > a:hover {
  color: black;
}
.webf-list-entities > .webf-entity > .webf-header:hover {
  background-color: #f9fafc;
}
.webf-list-entities > .webf-entity > .webf-header > a {
  text-decoration: none;
}
.webf-list-entities > .webf-entity > .webf-header > a:hover {
  color: black;
}
.webf-list-entities > .webf-entity > .webf-header > .--date-entree {
  width: 200px;
  flex-shrink: 0;
}
.webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 125px;
  padding-left: 1rem;
  opacity: 0.5;
  flex-shrink: 0;
  transition: 300ms opacity;
}
@media (max-width: 767px) {
  .webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group {
    width: 35px;
  }
}
.webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group .webf-button {
  padding: 0.3rem 0.6rem;
  min-width: 35px;
  background-color: #fff;
  border-color: transparent;
  font-size: 0.8rem;
}
.webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group .webf-button:hover {
  background-color: #e6e6e6;
}
@media screen and (max-width: 768px) {
  .webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group {
    opacity: 1;
  }
  .webf-list-entities > .webf-entity > .webf-header > .webf-buttons-group > .webf-button {
    border-color: #bbbbbb;
  }
}
.webf-list-entities > .webf-entity > .webf-body {
  display: none;
  padding: 0.5rem 1rem;
  border-top: 1px solid #E3E7EA;
}
.webf-list-entities > .webf-entity > .webf-body > .webf-details {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: 2rem;
  padding: 1rem 0 1rem 1.5rem;
  background: linear-gradient(90deg, transparent 0%, #EAEDF1 8px, transparent 8px, transparent 100%);
}
@media (max-width: 767px) {
  .webf-list-entities > .webf-entity > .webf-body {
    padding: 0.5rem 0;
  }
  .webf-list-entities > .webf-entity > .webf-body:first-child {
    border-top: 0;
  }
  .webf-list-entities > .webf-entity > .webf-body > .webf-details {
    padding: 0;
    margin-left: 0;
    background: none;
  }
  .webf-list-entities > .webf-entity > .webf-body.padding-body {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .webf-list-entities > .webf-entity > .webf-body > .webf-header, .webf-list-entities > .webf-entity > .webf-body > .webf-details > .webf-header {
    padding-left: 0;
    padding-right: 0.5rem;
  }
}
.webf-list-entities > .webf-entity.unfolded > .webf-header > .toggle-details {
  transform: rotate(90deg);
}
.webf-list-entities > .webf-entity.unfolded > .webf-body {
  display: block;
}
.webf-list-entities > .webf-entity.hidden-buttons > .webf-buttons-group {
  visibility: hidden;
}
.webf-list-entities > .webf-entity:hover > .webf-header > .webf-buttons-group {
  visibility: visible;
  opacity: 0.75;
}
.webf-list-entities > .webf-entity:hover > .webf-header > .webf-buttons-group .webf-button {
  border-color: #E3E7EA;
}
.webf-list-entities > .webf-entity:hover > .webf-header > .webf-buttons-group:hover {
  opacity: 1;
}
.webf-list-entities > .webf-entity.success {
  border-bottom: 1px solid #93cd7c;
}
.webf-list-entities > .webf-entity.success > .webf-header {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #00733e;
}
.webf-list-entities > .webf-entity.success > .webf-header:hover {
  background-color: #d0e9c6;
}
.webf-list-entities > .webf-entity.success > .webf-header > .toggle-details {
  color: #00733e;
}
.webf-list-entities > .webf-entity.success > .webf-header > .webf-buttons-group .webf-button {
  background-color: #d0e9c6;
  border-color: #a2d48f;
  color: #00733e;
}
.webf-list-entities > .webf-entity.success > .webf-header > .webf-buttons-group .webf-button:hover {
  background-color: #b2dba1;
}
.webf-list-entities > .webf-entity.success > .webf-header > .webf-buttons-group .webf-button:active, .webf-list-entities > .webf-entity.success > .webf-header > .webf-buttons-group .webf-button.webf-active, .webf-list-entities > .webf-entity.success > .webf-header > .webf-buttons-group .webf-button.webf-state-active {
  -webkit-box-shadow: inset 0px 0px 5px 2px #75be57;
  -moz-box-shadow: inset 0px 0px 5px 2px #75be57;
  box-shadow: inset 0px 0px 5px 2px #75be57;
}
.webf-list-entities > .webf-entity.danger {
  border-bottom: 1px solid #ce8383;
}
.webf-list-entities > .webf-entity.danger > .webf-header {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}
.webf-list-entities > .webf-entity.danger > .webf-header:hover {
  background-color: #ebcccc;
}
.webf-list-entities > .webf-entity.danger > .webf-header > .toggle-details {
  color: #a94442;
}
.webf-list-entities > .webf-entity.danger > .webf-header > .webf-buttons-group .webf-button {
  background-color: #ebcccc;
  border-color: #d59595;
  color: #a94442;
}
.webf-list-entities > .webf-entity.danger > .webf-header > .webf-buttons-group .webf-button:hover {
  background-color: #dca7a7;
}
.webf-list-entities > .webf-entity.danger > .webf-header > .webf-buttons-group .webf-button:active, .webf-list-entities > .webf-entity.danger > .webf-header > .webf-buttons-group .webf-button.webf-active, .webf-list-entities > .webf-entity.danger > .webf-header > .webf-buttons-group .webf-button.webf-state-active {
  -webkit-box-shadow: inset 0px 0px 5px 2px #c05e5e;
  -moz-box-shadow: inset 0px 0px 5px 2px #c05e5e;
  box-shadow: inset 0px 0px 5px 2px #c05e5e;
}
.webf-list-entities > .webf-entity.warning {
  border-bottom: 1px solid #f0db70;
}
.webf-list-entities > .webf-entity.warning > .webf-header {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  color: #8a6d3b;
}
.webf-list-entities > .webf-entity.warning > .webf-header:hover {
  background-color: #faf2cc;
}
.webf-list-entities > .webf-entity.warning > .webf-header > .toggle-details {
  color: #8a6d3b;
}
.webf-list-entities > .webf-entity.warning > .webf-header > .webf-buttons-group .webf-button {
  background-color: #faf2cc;
  border-color: #f3e086;
  color: #8a6d3b;
}
.webf-list-entities > .webf-entity.warning > .webf-header > .webf-buttons-group .webf-button:hover {
  background-color: #f5e79e;
}
.webf-list-entities > .webf-entity.warning > .webf-header > .webf-buttons-group .webf-button:active, .webf-list-entities > .webf-entity.warning > .webf-header > .webf-buttons-group .webf-button.webf-active, .webf-list-entities > .webf-entity.warning > .webf-header > .webf-buttons-group .webf-button.webf-state-active {
  -webkit-box-shadow: inset 0px 0px 5px 2px #ebd042;
  -moz-box-shadow: inset 0px 0px 5px 2px #ebd042;
  box-shadow: inset 0px 0px 5px 2px #ebd042;
}
.webf-list-entities > .webf-entity.unfoldable > .webf-header {
  padding-left: 0;
  cursor: pointer;
}

.webf-list {
  list-style-type: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 1px solid #bbbbbb;
  background: #fff;
  overflow-y: auto;
  outline: none;
}
.webf-list .webf-list-item > a, .webf-list .webf-list-item > label {
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  white-space: nowrap;
  height: 27px;
  line-height: 20px;
  font-size: 0.8rem;
  padding: 3px 10px;
  border: 1px solid transparent;
  color: #555;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0;
}
.webf-list .webf-list-item > a:hover, .webf-list .webf-list-item > label:hover {
  text-decoration: none;
}
.webf-list .webf-list-item:hover > a, .webf-list .webf-list-item.webf-focus > a, .webf-list .webf-list-item.webf-active > a {
  border-color: transparent;
  color: white;
  background-image: none;
  background-color: #4b70df;
}
.webf-list .webf-list-item.disabled > a, .webf-list .webf-list-item.disabled > label {
  color: #a2a2a2;
  background: none;
  border-color: transparent;
  cursor: default;
}
.webf-list .webf-list-item.webf-list-item-option.enabled:hover > a {
  border: 1px solid transparent;
  color: #555;
  background: none;
}
.webf-list .webf-list-item.webf-list-item-option.enabled.webf-focus > a, .webf-list .webf-list-item.webf-list-item-option.enabled.webf-focus > label {
  border-color: transparent;
  color: #555;
  background-image: none;
  background-color: whitesmoke;
}
.webf-list .webf-list-item.webf-list-item-option.enabled.webf-active > a, .webf-list .webf-list-item.webf-list-item-option.enabled.webf-active > label {
  border-color: transparent;
  color: white;
  background-image: none;
  background-color: #4b70df;
}
.webf-list .webf-list-item.webf-list-item-option.group > a {
  padding-left: 12px;
}
.webf-list .webf-list-item.webf-list-item-option strong {
  font-weight: bold;
  text-decoration: underline;
}
.webf-list .webf-list-item.webf-list-item-group > label {
  font-weight: normal;
  border-color: transparent;
  margin-bottom: 0;
  color: #4a5056;
  height: auto;
  padding: 0 0.3rem;
  background: #ededed;
  font-size: 0.65rem;
}
.webf-list .webf-list-item.webf-list-item-group:hover > label, .webf-list .webf-list-item.webf-list-item-group.webf-focus > label {
  background: #e8e8e8 !important;
  color: #41464b !important;
}
.webf-list .webf-list-item.webf-list-item-separator {
  background-color: #ddd;
  margin: 4px 0;
  height: 1px;
  font-size: 0;
}
.webf-list .webf-list-item .webf-list-item-icon {
  float: left;
  width: 20px;
  min-height: 1px;
}

.webf-md-text {
  line-height: 24px;
}
.webf-md-text, .webf-md-text.filled {
  height: 52px;
  position: relative;
  padding: 0;
  margin-bottom: 8px;
  background-color: #f5f5f5;
}
.webf-md-text .wrapper, .webf-md-text.filled .wrapper {
  height: 0;
}
.webf-md-text > label,
.webf-md-text .wrapper > label, .webf-md-text.filled > label,
.webf-md-text.filled .wrapper > label {
  font-family: Roboto, sans-serif;
  display: inline-block;
  width: 100%;
  text-align: left !important;
  position: relative;
  line-height: 16px;
  font-size: 16px;
  color: #9e9e9e;
  font-weight: 400;
  margin: 0;
  padding: 0 0 0 12px;
  transform: translateY(18px);
  height: 21px;
  overflow-y: hidden;
  word-break: break-all;
  transform-origin: left bottom;
  transition-duration: 0.3s;
  transition-property: transform, font-size, scale, padding-top, padding-left, padding-bottom, padding-right, border;
}
.webf-md-text input[type=text], .webf-md-text input[type=email], .webf-md-text input[type=date], .webf-md-text input[type=password], .webf-md-text.filled input[type=text], .webf-md-text.filled input[type=email], .webf-md-text.filled input[type=date], .webf-md-text.filled input[type=password] {
  display: block;
  position: relative;
  width: 100%;
  background: transparent;
  line-height: 16px;
  padding: 8px 0 8px 9px;
  border: none;
  -webkit-appearance: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  height: 43px;
  transform: translateY(-18px);
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.webf-md-text.with-icons, .webf-md-text.with-left-icon, .webf-md-text.with-right-icon, .webf-md-text.filled.with-icons, .webf-md-text.filled.with-left-icon, .webf-md-text.filled.with-right-icon {
  display: flex;
}
.webf-md-text.with-icons .wrapper, .webf-md-text.with-left-icon .wrapper, .webf-md-text.with-right-icon .wrapper, .webf-md-text.filled.with-icons .wrapper, .webf-md-text.filled.with-left-icon .wrapper, .webf-md-text.filled.with-right-icon .wrapper {
  width: 100%;
}
.webf-md-text.with-icons .icon, .webf-md-text.with-left-icon .icon, .webf-md-text.with-right-icon .icon, .webf-md-text.filled.with-icons .icon, .webf-md-text.filled.with-left-icon .icon, .webf-md-text.filled.with-right-icon .icon {
  position: relative;
  height: 100%;
  padding: 10px 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.webf-md-text.with-icons .icon > span, .webf-md-text.with-left-icon .icon > span, .webf-md-text.with-right-icon .icon > span, .webf-md-text.filled.with-icons .icon > span, .webf-md-text.filled.with-left-icon .icon > span, .webf-md-text.filled.with-right-icon .icon > span {
  white-space: nowrap;
  min-width: 20px;
  text-align: center;
}
.webf-md-text.with-icons .icon > i, .webf-md-text.with-left-icon .icon > i, .webf-md-text.with-right-icon .icon > i, .webf-md-text.filled.with-icons .icon > i, .webf-md-text.filled.with-left-icon .icon > i, .webf-md-text.filled.with-right-icon .icon > i {
  width: 20px;
}
.webf-md-text.with-icons .wrapper > label, .webf-md-text.with-left-icon .wrapper > label, .webf-md-text.filled.with-icons .wrapper > label, .webf-md-text.filled.with-left-icon .wrapper > label {
  padding-left: 0;
}
.webf-md-text.with-icons .wrapper textarea, .webf-md-text.with-icons .wrapper input[type=text], .webf-md-text.with-icons .wrapper input[type=email], .webf-md-text.with-icons .wrapper input[type=date], .webf-md-text.with-icons .wrapper input[type=password], .webf-md-text.with-left-icon .wrapper textarea, .webf-md-text.with-left-icon .wrapper input[type=text], .webf-md-text.with-left-icon .wrapper input[type=email], .webf-md-text.with-left-icon .wrapper input[type=date], .webf-md-text.with-left-icon .wrapper input[type=password], .webf-md-text.filled.with-icons .wrapper textarea, .webf-md-text.filled.with-icons .wrapper input[type=text], .webf-md-text.filled.with-icons .wrapper input[type=email], .webf-md-text.filled.with-icons .wrapper input[type=date], .webf-md-text.filled.with-icons .wrapper input[type=password], .webf-md-text.filled.with-left-icon .wrapper textarea, .webf-md-text.filled.with-left-icon .wrapper input[type=text], .webf-md-text.filled.with-left-icon .wrapper input[type=email], .webf-md-text.filled.with-left-icon .wrapper input[type=date], .webf-md-text.filled.with-left-icon .wrapper input[type=password] {
  padding-left: 0;
}
.webf-md-text::after, .webf-md-text::before, .webf-md-text.filled::after, .webf-md-text.filled::before {
  content: "";
  height: 2px;
  background-color: #d9d9d9;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.webf-md-text::after, .webf-md-text.filled::after {
  background-color: #4b70df;
  transform: scaleX(0);
  transition-property: transform;
  transition-duration: 0.3s;
}
.webf-md-text.is-focused > label,
.webf-md-text.is-focused .wrapper > label, .webf-md-text.filled.is-focused > label,
.webf-md-text.filled.is-focused .wrapper > label {
  transform: translateY(0) scale(80%);
  color: #4b70df;
}
.webf-md-text.is-focused::after, .webf-md-text.filled.is-focused::after {
  transform: scaleX(100%);
}
.webf-md-text.has-label > label,
.webf-md-text.has-label .wrapper > label, .webf-md-text.filled.has-label > label,
.webf-md-text.filled.has-label .wrapper > label {
  width: auto;
  transform: translateY(3px) scale(80%);
}
.webf-md-text.has-label input[type=text], .webf-md-text.has-label input[type=email], .webf-md-text.has-label input[type=date], .webf-md-text.has-label input[type=password], .webf-md-text.filled.has-label input[type=text], .webf-md-text.filled.has-label input[type=email], .webf-md-text.filled.has-label input[type=date], .webf-md-text.filled.has-label input[type=password] {
  height: 20px;
  transform: translateY(0px);
  padding: 10px 0 17px 9px;
  top: -4px;
}
.webf-md-text.outlined {
  height: 40px;
  background-color: white;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 12px;
  transition-duration: 0.3s;
}
.webf-md-text.outlined .wrapper {
  height: 0;
}
.webf-md-text.outlined > label,
.webf-md-text.outlined > .wrapper > label {
  transform: translate(0, 10px);
}
.webf-md-text.outlined input[type=text], .webf-md-text.outlined input[type=email], .webf-md-text.outlined input[type=date], .webf-md-text.outlined input[type=password] {
  height: 36px;
  transform: translate(0, -28px);
  box-shadow: none;
  -webkit-box-shadow: none;
}
.webf-md-text.outlined.with-icons .wrapper > label, .webf-md-text.outlined.with-left-icon .wrapper > label {
  padding-left: 0;
}
.webf-md-text.outlined.with-icons .wrapper textarea, .webf-md-text.outlined.with-icons .wrapper input[type=text], .webf-md-text.outlined.with-icons .wrapper input[type=email], .webf-md-text.outlined.with-icons .wrapper input[type=date], .webf-md-text.outlined.with-icons .wrapper input[type=password], .webf-md-text.outlined.with-left-icon .wrapper textarea, .webf-md-text.outlined.with-left-icon .wrapper input[type=text], .webf-md-text.outlined.with-left-icon .wrapper input[type=email], .webf-md-text.outlined.with-left-icon .wrapper input[type=date], .webf-md-text.outlined.with-left-icon .wrapper input[type=password] {
  padding-left: 0;
}
.webf-md-text.outlined::after, .webf-md-text.outlined::before {
  height: 0;
}
.webf-md-text.outlined.is-focused {
  border-color: #4b70df;
}
.webf-md-text.outlined.has-label > label,
.webf-md-text.outlined.has-label .wrapper > label {
  transform: translateY(-12px) scale(80%);
  display: inline-block;
  background-color: white;
  padding: 0 4px 0 4px;
  margin: 0 0 0 6px;
  height: 17px;
  overflow-y: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  width: auto;
}
.webf-md-text.outlined.has-label input[type=text], .webf-md-text.outlined.has-label input[type=email], .webf-md-text.outlined.has-label input[type=date], .webf-md-text.outlined.has-label input[type=password] {
  height: 29px;
  transform: translateY(-13px);
  padding: 1px 0 7px 9px;
}
.webf-md-text.outlined.with-icons.has-label > label,
.webf-md-text.outlined.with-icons.has-label .wrapper > label, .webf-md-text.outlined.with-left-icon.has-label > label,
.webf-md-text.outlined.with-left-icon.has-label .wrapper > label {
  transform: translate(-36px, -11px) scale(80%);
}
.webf-md-text.has-error {
  border-color: red;
}
.webf-md-text.has-error:before, .webf-md-text.has-error:after {
  color: red;
  border-color: red;
  background-color: red;
}
.webf-md-text.has-error > label,
.webf-md-text.has-error .wrapper > label {
  color: red;
}
.webf-md-text.has-error.is-focused {
  border-color: red;
}
.webf-md-text.has-error.with-icons .icon, .webf-md-text.has-error.with-left-icon .icon, .webf-md-text.has-error.with-right-icon .icon {
  color: red;
}
.webf-md-text.has-error, .webf-md-text.has-error.filled {
  background-color: #fff0f0;
}
.webf-md-text.has-error.outlined {
  background-color: white;
}

.webf-md-textarea {
  position: relative;
  height: auto;
  background: #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 21.5px;
}
.webf-md-textarea > label,
.webf-md-textarea .wrapper > label, .webf-md-textarea.filled > label,
.webf-md-textarea.filled .wrapper > label {
  display: block;
  text-align: left !important;
  width: 100%;
  position: absolute;
  margin: 0;
  padding: 12px 12px;
  transform-origin: left center;
  transition-duration: 0.3s;
  transition-property: transform, font-size, scale, padding-top, padding-left, padding-bottom, padding-right, border;
  line-height: 16px;
  font-size: 16px;
  color: #9e9e9e;
  cursor: text;
}
.webf-md-textarea textarea, .webf-md-textarea.filled textarea {
  border: none;
  -webkit-appearance: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  background: transparent;
  position: relative;
  line-height: 20px;
  height: auto;
  width: 100%;
  border: 0;
  display: block;
  padding: 0 8px 5px 9px;
  top: 21.5px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.webf-md-textarea::after, .webf-md-textarea::before, .webf-md-textarea.filled::after, .webf-md-textarea.filled::before {
  content: "";
  height: 2px;
  background-color: #d9d9d9;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.webf-md-textarea.has-label > label,
.webf-md-textarea.has-label .wrapper > label, .webf-md-textarea.filled.has-label > label,
.webf-md-textarea.filled.has-label .wrapper > label {
  position: absolute;
  margin: 0;
  padding: 4px 12px;
  transform: translate(0, 0) scale(80%);
  transform-origin: left center;
  display: block;
  width: auto;
}
.webf-md-textarea.has-label textarea, .webf-md-textarea.filled.has-label textarea {
  height: calc(100% - 25px);
}
.webf-md-textarea.is-focused > label,
.webf-md-textarea.is-focused .wrapper > label, .webf-md-textarea.filled.is-focused > label,
.webf-md-textarea.filled.is-focused .wrapper > label {
  color: #4b70df;
}
.webf-md-textarea.is-focused::after, .webf-md-textarea.filled.is-focused::after {
  transform: scaleX(100%);
}
.webf-md-textarea.outlined {
  background-color: white;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.webf-md-textarea.outlined > label,
.webf-md-textarea.outlined .wrapper > label {
  transform: translate(0, 0);
  padding: 6px 12px 12px;
}
.webf-md-textarea.outlined textarea {
  top: 12px;
}
.webf-md-textarea.outlined::after, .webf-md-textarea.outlined::before {
  height: 0;
}
.webf-md-textarea.outlined.has-label > label,
.webf-md-textarea.outlined.has-label .wrapper > label {
  transform: translate(0, -12px) scale(80%);
  display: inline-block;
  background-color: white;
  padding: 3px 4px 0 4px;
  margin: 0 0 0 6px;
  height: 21px;
  overflow-y: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.webf-md-text.has-error, .webf-md-textarea.has-error {
  border-color: red;
}
.webf-md-text.has-error:before, .webf-md-text.has-error:after, .webf-md-textarea.has-error:before, .webf-md-textarea.has-error:after {
  color: red;
  border-color: red;
  background-color: red;
}
.webf-md-text.has-error > label,
.webf-md-text.has-error .wrapper > label, .webf-md-textarea.has-error > label,
.webf-md-textarea.has-error .wrapper > label {
  color: red;
}
.webf-md-text.has-error.is-focused, .webf-md-textarea.has-error.is-focused {
  border-color: red;
}
.webf-md-text.has-error.with-icons .icon, .webf-md-text.has-error.with-left-icon .icon, .webf-md-text.has-error.with-right-icon .icon, .webf-md-textarea.has-error.with-icons .icon, .webf-md-textarea.has-error.with-left-icon .icon, .webf-md-textarea.has-error.with-right-icon .icon {
  color: red;
}
.webf-md-text.has-error, .webf-md-text.has-error.filled, .webf-md-textarea.has-error, .webf-md-textarea.has-error.filled {
  background-color: #fff0f0;
}
.webf-md-text.has-error.outlined, .webf-md-textarea.has-error.outlined {
  background-color: white;
}
.webf-md-text.is-valid, .webf-md-textarea.is-valid {
  border-color: green;
}
.webf-md-text.is-valid:before, .webf-md-text.is-valid:after, .webf-md-textarea.is-valid:before, .webf-md-textarea.is-valid:after {
  color: green;
  border-color: green;
  background-color: green;
}
.webf-md-text.is-valid > label,
.webf-md-text.is-valid .wrapper > label, .webf-md-textarea.is-valid > label,
.webf-md-textarea.is-valid .wrapper > label {
  color: green;
}
.webf-md-text.is-valid.is-focused, .webf-md-textarea.is-valid.is-focused {
  border-color: green;
}
.webf-md-text.is-valid.with-icons .icon, .webf-md-text.is-valid.with-left-icon .icon, .webf-md-text.is-valid.with-right-icon .icon, .webf-md-textarea.is-valid.with-icons .icon, .webf-md-textarea.is-valid.with-left-icon .icon, .webf-md-textarea.is-valid.with-right-icon .icon {
  color: green;
}
.webf-md-text.is-valid, .webf-md-text.is-valid.filled, .webf-md-textarea.is-valid, .webf-md-textarea.is-valid.filled {
  background-color: #e6ffe6;
}
.webf-md-text.is-valid.outlined, .webf-md-textarea.is-valid.outlined {
  background-color: white;
}

.webf-md-list {
  font-family: Roboto, sans-serif;
  list-style-type: none;
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  overflow-y: auto;
  outline: none;
}
.webf-md-list .webf-list-item > a, .webf-md-list .webf-list-item > label {
  text-align: left;
  box-sizing: border-box;
  display: block;
  white-space: nowrap;
  font-size: 1rem;
  padding: 8px 10px;
  border: 1px solid transparent;
  color: black;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0;
  height: 38px;
}
.webf-md-list .webf-list-item > a:hover, .webf-md-list .webf-list-item > label:hover {
  text-decoration: none;
}
.webf-md-list .webf-list-item:hover > a, .webf-md-list .webf-list-item.webf-focus > a {
  border-color: transparent;
  color: #4b70df;
  background-image: none;
  background-color: #f7f7f7;
}
.webf-md-list .webf-list-item.webf-active > a {
  border-color: transparent;
  color: #4b70df;
  background-image: none;
  background-color: #f2f2f2;
}
.webf-md-list .webf-list-item.disabled > a, .webf-md-list .webf-list-item.disabled > label {
  color: #a2a2a2;
  background: none;
  border-color: transparent;
  cursor: default;
}
.webf-md-list .webf-list-item.webf-list-item-option.enabled:hover > a {
  border: 1px solid transparent;
  color: black;
  background: none;
}
.webf-md-list .webf-list-item.webf-list-item-option.enabled.webf-focus > a, .webf-md-list .webf-list-item.webf-list-item-option.enabled.webf-focus > label {
  border-color: transparent;
  color: #4b70df;
  background-image: none;
  background-color: #f7f7f7;
}
.webf-md-list .webf-list-item.webf-list-item-option.enabled.webf-active > a, .webf-md-list .webf-list-item.webf-list-item-option.enabled.webf-active > label {
  border-color: transparent;
  color: #4b70df;
  background-image: none;
  background-color: #f2f2f2;
}
.webf-md-list .webf-list-item.webf-list-item-option.group > a {
  padding-left: 12px;
}
.webf-md-list .webf-list-item.webf-list-item-option strong {
  font-weight: bold;
  text-decoration: underline;
}
.webf-md-list .webf-list-item.webf-list-item-group > label {
  font-weight: normal;
  border-color: transparent;
  margin-bottom: 0;
  color: #4a5056;
  height: auto;
  padding: 0 0.3rem;
  background: #ededed;
  font-size: 0.65rem;
}
.webf-md-list .webf-list-item.webf-list-item-group:hover > label, .webf-md-list .webf-list-item.webf-list-item-group.webf-focus > label {
  background: #f7f7f7 !important;
  color: #41464b !important;
}
.webf-md-list .webf-list-item.webf-list-item-separator {
  background-color: #ddd;
  margin: 4px 0;
  height: 1px;
  font-size: 0;
}
.webf-md-list .webf-list-item .webf-list-item-icon {
  float: left;
  width: 20px;
  min-height: 20px;
  margin-right: 8px;
}

.webf-navigation-frame-selection {
  pointer-events: none;
  position: absolute;
  top: 200px;
  left: 200px;
  width: 300px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 2px 0 gray;
}

.webf-progressbar {
  text-align: left;
  overflow: hidden;
  height: 2em;
  border: 1px solid #bbbbbb;
  background: #fff;
  position: relative;
}
.webf-progressbar .inner-progress {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  font-size: 0px;
  background-image: -owg-linear-gradient(to bottom, #6e8ce5 10%, #315bda 60%);
  background-image: -webkit-linear-gradient(to bottom, #6e8ce5 10%, #315bda 60%);
  background-image: -moz-linear-gradient(to bottom, #6e8ce5 10%, #315bda 60%);
  background-image: -o-linear-gradient(to bottom, #6e8ce5 10%, #315bda 60%);
  background-image: linear-gradient(to bottom, #6e8ce5 10%, #315bda 60%);
}

.webf-radiowrapper {
  box-sizing: border-box;
  border: 1px solid #999;
  color: #999;
  display: inline-block;
  vertical-align: text-bottom;
  width: 16px;
  height: 16px;
  line-height: 16px;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  font-size: 0;
  border-radius: 8px;
}
.webf-radiowrapper.mousedown {
  background-color: #f1f1f1;
  -webkit-box-shadow: inset 0px 0px 3px 0 #ccc;
  -moz-box-shadow: inset 0px 0px 3px 0 #ccc;
  box-shadow: inset 0px 0px 3px 0 #ccc;
}
.webf-radiowrapper a.radio {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 1px;
}
.webf-radiowrapper.checked a.radio {
  background-image: url(/build/images/checked.1b9fab1e.png);
}
.webf-radiowrapper.disabled {
  border-color: #ddd;
}
.webf-radiowrapper.disabled.checked a.radio {
  background-image: url(/build/images/checked-disabled.47bc86a9.png);
}

.webf-resizable {
  position: relative;
}
.webf-resizable .resize-handler {
  position: absolute;
  border: 0;
  padding: 0;
  font-size: 0;
}
.webf-resizable .resize-handler.corner {
  width: 12px;
  height: 12px;
}
.webf-resizable .resize-handler.corner.nw {
  top: -6px;
  left: -6px;
  cursor: nwse-resize;
}
.webf-resizable .resize-handler.corner.ne {
  top: -6px;
  right: -6px;
  cursor: nesw-resize;
}
.webf-resizable .resize-handler.corner.se {
  bottom: -6px;
  right: -6px;
  cursor: nwse-resize;
}
.webf-resizable .resize-handler.corner.sw {
  bottom: -6px;
  left: -6px;
  cursor: nesw-resize;
}
.webf-resizable .resize-handler.border.horizontal {
  height: 12px;
  cursor: row-resize;
}
.webf-resizable .resize-handler.border.horizontal.n, .webf-resizable .resize-handler.border.horizontal.s {
  left: -6px;
  right: -6px;
}
.webf-resizable .resize-handler.border.horizontal.n {
  top: -6px;
}
.webf-resizable .resize-handler.border.horizontal.s {
  bottom: -6px;
}
.webf-resizable .resize-handler.border.vertical {
  width: 12px;
  cursor: col-resize;
}
.webf-resizable .resize-handler.border.vertical.w, .webf-resizable .resize-handler.border.vertical.e {
  top: -6px;
  bottom: -6px;
}
.webf-resizable .resize-handler.border.vertical.w {
  left: -6px;
}
.webf-resizable .resize-handler.border.vertical.e {
  right: -6px;
}

.webf-richtexteditor-color-selector {
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
}
.webf-richtexteditor-color-selector .palette {
  margin: 5px 0;
}
.webf-richtexteditor-color-selector .palette:not(:last-child) {
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.webf-richtexteditor-color-selector .palette table {
  width: auto;
}
.webf-richtexteditor-color-selector .palette table td a.cell {
  margin: 1px;
  border: 1px solid #fff;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
  display: block;
}
.webf-richtexteditor-color-selector .palette table td a.cell:hover {
  border-color: #000;
}

.webf-richtexteditor-image #btn_upload_image {
  height: auto;
}

.webf-richtexteditor-table-selector {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid silver;
  width: 250px;
}
.webf-richtexteditor-table-selector table {
  width: 100%;
  border-collapse: collapse;
}
.webf-richtexteditor-table-selector table td {
  border: 1px solid #999;
  width: 25px;
  height: 25px;
  background-color: #fff;
  cursor: pointer;
}
.webf-richtexteditor-table-selector table td.hover {
  background-color: #eaeaea;
}

.dialog-table-properties {
  width: 800px;
}
.dialog-table-properties form {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dialog-table-properties form .form-group {
  margin-top: 2px;
  margin-bottom: 2px;
}
.dialog-table-properties form .form-group input {
  text-align: right;
}
.dialog-table-properties .apercu {
  padding-top: 10px;
  max-height: 250px;
  overflow-x: auto;
  overflow-y: auto;
}
.dialog-table-properties .apercu table {
  border: 1px solid #555;
  border-collapse: collapse;
  width: 1px;
  table-layout: fixed;
}
.dialog-table-properties .apercu table td {
  border: 1px solid #555;
  height: 20px;
}

.webf-richtexteditor {
  border: 1px solid #bbbbbb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-family: "Trebuchet MS", "Helvetica Neue", Helvetica, Tahoma, sans-serif;
  font-size: 12pt;
  line-height: 22px;
  color: #555;
  background-color: white;
  outline: none;
  min-height: 40px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #f0f3f8;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls {
  padding: 5px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls {
  line-height: 11px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .pipe {
  margin: auto 3px;
  display: inline-block;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-dropdown {
  display: inline-block;
  width: auto;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button {
  position: relative;
  margin-bottom: 3px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button label {
  display: inline-block;
  margin: 0 8px 0 0;
  text-align: left;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button.heading label {
  min-width: 100px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button.fontname label {
  min-width: 150px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button.fontsize label {
  min-width: 50px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .btn-tooltip {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  outline: none;
  cursor: pointer;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .webf-select-button {
  width: auto;
  position: relative;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .webf-select-button label {
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 80px;
  text-align: left;
  cursor: pointer;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .webf-select-button.fontname label {
  width: 110px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .webf-select-button.forecolor label {
  text-shadow: 1px 1px 1px #aaa;
  width: 15px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-button .webf-select-button > i.webf {
  position: absolute;
  top: 6px;
  right: 6px;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-richtexteditor-list {
  max-height: 190px;
  overflow-y: auto;
}
.webf-richtexteditor .webf-richtexteditor-toolbar .wrap-controls .controls .webf-richtexteditor-list.webf-richtexteditor-fontsizepx li > a {
  width: 50px;
}
.webf-richtexteditor .webf-content {
  outline: none;
  padding: 5px 10px;
  min-height: 55px;
}
.webf-richtexteditor .webf-content.fixed-height {
  overflow-y: auto;
}
.webf-richtexteditor .webf-content .webf-table {
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.webf-richtexteditor .webf-content .webf-table td {
  border: 1px solid #ddd;
  height: 24px;
  width: 100px;
}
.webf-richtexteditor .webf-content ol, .webf-richtexteditor .webf-content ul {
  margin: 0 0 16px 0;
  padding: 0 0 0 30px;
}
.webf-richtexteditor .webf-content ul {
  list-style-type: disc;
}
.webf-richtexteditor .webf-content img {
  max-width: 100%;
}

.webf-richtexteditor-tooltip .text {
  color: #eee;
  font-size: 13px;
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  background: #333;
  padding: 3px 10px;
}
.webf-richtexteditor-tooltip .triangle {
  width: 7px;
  height: 5px;
  margin: auto;
  background: transparent url(/build/images/triangle-inverse.91aaa3bb.png);
}

.webf-richtexteditor-dialog .webf-checkboxwrapper {
  margin-top: 8px;
}

.webf-scrollbox-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.webf-scrollbox-wrapper > .webf-scrollbox-container {
  overflow: hidden;
  position: absolute;
}
.webf-scrollbox-wrapper > .webf-scrollbar {
  position: absolute;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x, .webf-scrollbox-wrapper > .webf-scrollbar.y {
  background-color: #eee;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x .handler, .webf-scrollbox-wrapper > .webf-scrollbar.y .handler {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #bbbbbb;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x .handler:hover, .webf-scrollbox-wrapper > .webf-scrollbar.y .handler:hover {
  background-color: #959595;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x:hover, .webf-scrollbox-wrapper > .webf-scrollbar.y:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x.webf-invisible, .webf-scrollbox-wrapper > .webf-scrollbar.y.webf-invisible {
  visibility: hidden;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x {
  bottom: 0;
  left: 0;
  height: 14px;
  width: 100%;
}
.webf-scrollbox-wrapper > .webf-scrollbar.x .handler {
  height: 100%;
}
.webf-scrollbox-wrapper > .webf-scrollbar.y {
  top: 0;
  right: 0;
  width: 14px;
  height: 100%;
}
.webf-scrollbox-wrapper > .webf-scrollbar.y .handler {
  width: 100%;
}
.webf-scrollbox-wrapper:hover .webf-scrollbar, .webf-scrollbox-wrapper.dragging .webf-scrollbar {
  display: block;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}
.webf-scrollbox-wrapper.dragging-x .webf-scrollbar.x .handler {
  background-color: #959595;
}
.webf-scrollbox-wrapper.dragging-y .webf-scrollbar.y .handler {
  background-color: #959595;
}

.webf-select {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  padding: 3px 0 3px 8px;
  border: 1px solid gainsboro;
  background-color: #fff;
  background-image: -owg-linear-gradient(#ffffff 0%, #eeeeee 100%);
  background-image: -webkit-linear-gradient(#ffffff 0%, #eeeeee 100%);
  background-image: -moz-linear-gradient(#ffffff 0%, #eeeeee 100%);
  background-image: -o-linear-gradient(#ffffff 0%, #eeeeee 100%);
  background-image: linear-gradient(#ffffff 0%, #eeeeee 100%);
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
}
.webf-select.open {
  background-image: -owg-linear-gradient(#eeeeee 0%, #ffffff 100%);
  background-image: -webkit-linear-gradient(#eeeeee 0%, #ffffff 100%);
  background-image: -moz-linear-gradient(#eeeeee 0%, #ffffff 100%);
  background-image: -o-linear-gradient(#eeeeee 0%, #ffffff 100%);
  background-image: linear-gradient(#eeeeee 0%, #ffffff 100%);
  -webkit-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
}
.webf-select .select {
  color: #555;
  font-size: 0.8rem;
}
.webf-select .select .selected-area {
  text-decoration: none;
  display: block;
  margin-right: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
}
.webf-select .select .handle-down {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 5px;
  font-size: 13px;
  float: right;
}

.webf-dropdown .webf-dropdown-menu.webf-select-drop {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid gainsboro;
  border-top: 0;
  background-color: #fff;
  width: 100%;
  margin-top: -1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.175) 0 3px 3px;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.multiple {
  display: inline-block;
  border-top: 1px solid #bbbbbb;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options {
  padding: 0;
  margin: 0;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option {
  position: relative;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option.selected > a, .webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option.selected > label {
  color: #4b70df;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option.selected .webf-checkboxwrapper.checked a.checkbox {
  color: #7692e7;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option > a, .webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option > label {
  display: block;
  padding-left: 28px !important;
  border: 0;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option.enabled > a:hover,
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option.enabled > label:hover {
  color: #4b70df;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option .webf-checkboxwrapper {
  position: absolute;
  top: 5px;
  left: 6px;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option .webf-checkboxwrapper.checked a.checkbox {
  color: #aaa;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop.checkable > .webf-list.options li.webf-list-item-option:hover .webf-checkboxwrapper.checked a.checkbox {
  color: #7692e7;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop > .filter {
  padding: 6px 8px;
  position: relative;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop > .filter label {
  display: block;
  position: relative;
  margin: 0;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop > .filter label .search {
  line-height: initial;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, "Lucida Grande", verdana, sans-serif;
  font-size: 14px;
  color: #555555;
  background-color: #fff;
  border: 1px solid gainsboro;
  padding: 8px 30px 8px 4px;
  width: 100%;
  outline: none;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop > .filter label .icon-search {
  position: absolute;
  color: #a0aabb;
  top: 8px;
  right: 10px;
  font-size: 16px;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop > ul.options {
  position: relative;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.webf-dropdown .webf-dropdown-menu.webf-select-drop .webf-item-hidden {
  display: none;
}

.webf-select-drop {
  max-height: 270px;
  overflow-y: auto;
}
.webf-select-drop.multiple {
  overflow-x: auto;
  border: 1px solid #BBB;
  line-height: 0;
}
.webf-select-drop.multiple .webf-list {
  border: 0;
}
.webf-select-drop.multiple > ul {
  min-width: 100%;
  width: auto;
}
.webf-select-drop .webf-checkboxwrapper .checkbox {
  padding: 0;
  min-height: auto;
}

.webf-dropdown.webf-md-select.open, .webf-dropdown.webf-md-select.filled.open {
  margin-bottom: 0;
}
.webf-dropdown.webf-md-select .webf-select-drop, .webf-dropdown.webf-md-select.filled .webf-select-drop {
  margin-top: -8px;
}
.webf-dropdown.webf-md-select.outlined .webf-select-drop {
  margin-top: -12px;
}
.webf-dropdown.webf-md-select .webf-md-text input[type=text] {
  cursor: default;
}
.webf-dropdown.webf-md-select .webf-md-list {
  display: block;
  box-shadow: none;
}
.webf-dropdown.webf-md-select .webf-md-list .webf-list-item.webf-active.webf-focus > a {
  border-color: transparent;
  color: #4b70df;
  background-image: none;
  background-color: #e6e6e6;
}

.webf-slider {
  background: #ddd;
  border: 1px solid #ddd;
  position: relative;
  border-radius: 0.55rem;
}
.webf-slider > a.handler {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background: transparent;
  border: 2px solid transparent;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}
.webf-slider > a.handler .inner-handler {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #565a5f;
}
.webf-slider > a.handler:hover .inner-handler {
  background: #4b70df;
}
.webf-slider > a.handler.webf-slider-focus {
  border-color: #4b70df;
}
.webf-slider.horizontal {
  height: 0.55rem;
}
.webf-slider.horizontal > a.handler {
  top: -0.625rem;
  left: 0;
  margin-top: -1px;
  margin-left: -0.9rem;
  margin-right: -0.9rem;
}
.webf-slider.vertical {
  width: 0.55rem;
  height: 150px;
}
.webf-slider.vertical > a.handler {
  left: -0.625rem;
  bottom: 0;
  margin-left: -1px;
  margin-top: -0.9rem;
  margin-bottom: -0.9rem;
}

.webf-sortable-ghost {
  visibility: hidden !important;
}

.webf-sortable-clone {
  position: fixed !important;
  z-index: 100000 !important;
  pointer-events: none !important;
  margin: 0 !important;
}

.list-sortable .webf-entity {
  background-color: #fff;
}

.list-sortable.webf-sortable-droppable {
  min-height: 50px;
  border: 1px solid #4b70df;
  background-color: #b7c6f2;
}

.webf-spinner {
  display: table;
}
.webf-spinner .webf-input-spinner {
  display: table-cell;
  height: 100%;
  padding: 4px 12px;
  width: 100%;
}
.webf-spinner .webf-spinner-buttons {
  display: table-cell;
  width: 1%;
  height: 100%;
  padding: 0 16px;
  position: relative;
  background-color: #eee;
  border: 1px solid #c0c0c0;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #333;
  align-items: center;
  justify-content: center;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.up, .webf-spinner .webf-spinner-buttons .webf-spin-btn.down {
  position: absolute;
  left: 0;
  display: flex;
  cursor: pointer;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.up i.webf, .webf-spinner .webf-spinner-buttons .webf-spin-btn.down i.webf {
  margin: auto;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.up:hover, .webf-spinner .webf-spinner-buttons .webf-spin-btn.down:hover {
  background-color: #dfdfdf;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.up {
  top: 0;
  bottom: 50%;
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.up i.webf {
  vertical-align: top;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.down {
  top: 50%;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dadada;
}
.webf-spinner .webf-spinner-buttons .webf-spin-btn.down i.webf {
  vertical-align: top;
}
.webf-spinner .webf-spinner-buttons.left {
  border-right: 0;
}
.webf-spinner .webf-spinner-buttons.right {
  border-left: 0;
}
.webf-spinner .webf-spinner-buttons.alone .webf-spin-btn.up, .webf-spinner .webf-spinner-buttons.alone .webf-spin-btn.down {
  top: 0;
  bottom: 0;
  border-top: 0;
  border-bottom: 0;
}

.webf-stickybar {
  display: none;
  position: fixed;
  width: 100%;
  height: 40px;
  line-height: 40px;
  left: 0;
  bottom: 0;
  background: #f8f8f8;
  text-align: center;
  font-size: 0;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.3);
}
.webf-stickybar.visible {
  display: block;
}
.webf-stickybar .webf-sticky-button {
  font-size: 13px;
  margin: 0 3px;
}

@inport "compass";
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style {
  display: block;
  overflow: hidden;
  *zoom: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-default {
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab {
  border: 1px solid white;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab > a {
  background-color: white;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: white;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-primary {
  background-color: #4b70df;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab {
  border: 1px solid #4b70df;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab > a {
  background-color: #4b70df;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #4b70df;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-secondary {
  background-color: #f0f3f8;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab {
  border: 1px solid #f0f3f8;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab > a {
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-custom {
  background-color: #fff;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab {
  border: 1px solid #fff;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab > a {
  background-color: #fff;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #fff;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-light {
  background-color: #f0f3f8;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab {
  border: 1px solid #f0f3f8;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab > a {
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-dark {
  background-color: #555;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab {
  border: 1px solid #555;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab > a {
  background-color: #555;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #555;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-action {
  background-color: #e44982;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab {
  border: 1px solid #e44982;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab > a {
  background-color: #e44982;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #e44982;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-info {
  background-color: #4dbbe2;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab {
  border: 1px solid #4dbbe2;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab > a {
  background-color: #4dbbe2;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #4dbbe2;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-success {
  background-color: #66BB6A;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab {
  border: 1px solid #66BB6A;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab > a {
  background-color: #66BB6A;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #66BB6A;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-warning {
  background-color: #f6b74d;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab {
  border: 1px solid #f6b74d;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab > a {
  background-color: #f6b74d;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f6b74d;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-danger {
  background-color: #ed5655;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab {
  border: 1px solid #ed5655;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab > a {
  background-color: #ed5655;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #ed5655;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-lightgrey {
  background-color: #f5f5f5;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab {
  border: 1px solid #f5f5f5;
  border-bottom-width: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab > a {
  background-color: #f5f5f5;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #3c3c3c;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style > li.webf-tab {
  float: left;
  background-color: white;
  font-size: 14px;
  font-weight: bold;
}
.webf-tabs-wrapper.webf-horizontal > .webf-tabs.webf-style > li.webf-tab > a {
  text-decoration: none;
  display: inline-block;
  padding: 7px 10px;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-top: 0;
  background-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-default {
  border-color: white;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-primary {
  border-color: #4b70df;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-secondary {
  border-color: #f0f3f8;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-custom {
  border-color: #fff;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-light {
  border-color: #f0f3f8;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-dark {
  border-color: #555;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-action {
  border-color: #e44982;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-info {
  border-color: #4dbbe2;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-success {
  border-color: #66BB6A;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-warning {
  border-color: #f6b74d;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-danger {
  border-color: #ed5655;
}
.webf-tabs-wrapper.webf-horizontal > .webf-panels > .webf-panel.webf-style.webf-panel-lightgrey {
  border-color: #f5f5f5;
}
.webf-tabs-wrapper.webf-vertical {
  display: flex;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style {
  display: block;
  overflow: hidden;
  *zoom: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab {
  border: 1px solid white;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab > a {
  display: block;
  background-color: white;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-default > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: white;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab {
  border: 1px solid #4b70df;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab > a {
  display: block;
  background-color: #4b70df;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-primary > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #4b70df;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab {
  border: 1px solid #f0f3f8;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab > a {
  display: block;
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-secondary > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab {
  border: 1px solid #fff;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab > a {
  display: block;
  background-color: #fff;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-custom > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #fff;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab {
  border: 1px solid #f0f3f8;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab > a {
  display: block;
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-light > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab {
  border: 1px solid #555;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab > a {
  display: block;
  background-color: #555;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-dark > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #555;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab {
  border: 1px solid #e44982;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab > a {
  display: block;
  background-color: #e44982;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-action > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #e44982;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab {
  border: 1px solid #4dbbe2;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab > a {
  display: block;
  background-color: #4dbbe2;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-info > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #4dbbe2;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab {
  border: 1px solid #66BB6A;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab > a {
  display: block;
  background-color: #66BB6A;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-success > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #66BB6A;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab {
  border: 1px solid #f6b74d;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab > a {
  display: block;
  background-color: #f6b74d;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-warning > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #f6b74d;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab {
  border: 1px solid #ed5655;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab > a {
  display: block;
  background-color: #ed5655;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-danger > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #ed5655;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab {
  border: 1px solid #f5f5f5;
  border-right: 0;
  background-color: white;
  text-align: right;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab > a {
  display: block;
  background-color: #f5f5f5;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style.webf-tabs-lightgrey > li.webf-tab.webf-tab-active > a {
  background-color: white;
  color: #3c3c3c;
  text-shadow: none;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style > li.webf-tab {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style > li.webf-tab > a {
  text-decoration: none;
  display: inline-block;
  padding: 7px 10px;
}
.webf-tabs-wrapper.webf-vertical > .webf-tabs.webf-style {
  flex: 1;
}
.webf-tabs-wrapper.webf-vertical > .webf-panels {
  flex: 4;
}
.webf-tabs-wrapper.webf-vertical > .webf-panels > .webf-panel.webf-style {
  padding: 10px;
  border: 0;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion {
  display: none;
  background-color: white;
  font-size: 14px;
  font-weight: bold;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-default {
  border: 1px solid white;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-default > a {
  background-color: white;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-default.webf-tab-active > a {
  background-color: white;
  color: white;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-primary {
  border: 1px solid #4b70df;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-primary > a {
  background-color: #4b70df;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-primary.webf-tab-active > a {
  background-color: white;
  color: #4b70df;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-secondary {
  border: 1px solid #f0f3f8;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-secondary > a {
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-secondary.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-custom {
  border: 1px solid #fff;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-custom > a {
  background-color: #fff;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-custom.webf-tab-active > a {
  background-color: white;
  color: #fff;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-light {
  border: 1px solid #f0f3f8;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-light > a {
  background-color: #f0f3f8;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-light.webf-tab-active > a {
  background-color: white;
  color: #f0f3f8;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-dark {
  border: 1px solid #555;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-dark > a {
  background-color: #555;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-dark.webf-tab-active > a {
  background-color: white;
  color: #555;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-action {
  border: 1px solid #e44982;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-action > a {
  background-color: #e44982;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-action.webf-tab-active > a {
  background-color: white;
  color: #e44982;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-info {
  border: 1px solid #4dbbe2;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-info > a {
  background-color: #4dbbe2;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-info.webf-tab-active > a {
  background-color: white;
  color: #4dbbe2;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-success {
  border: 1px solid #66BB6A;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-success > a {
  background-color: #66BB6A;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-success.webf-tab-active > a {
  background-color: white;
  color: #66BB6A;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-warning {
  border: 1px solid #f6b74d;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-warning > a {
  background-color: #f6b74d;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-warning.webf-tab-active > a {
  background-color: white;
  color: #f6b74d;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-danger {
  border: 1px solid #ed5655;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-danger > a {
  background-color: #ed5655;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-danger.webf-tab-active > a {
  background-color: white;
  color: #ed5655;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-lightgrey {
  border: 1px solid #f5f5f5;
  background-color: white;
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-lightgrey > a {
  background-color: #f5f5f5;
  color: #555;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.webf-tabs-wrapper .webf-tab-accordion.webf-tab-lightgrey.webf-tab-active > a {
  background-color: white;
  color: #3c3c3c;
  text-shadow: none;
}
.webf-tabs-wrapper .webf-tab-accordion > a {
  text-decoration: none;
  display: block;
  padding: 7px 12px;
}
.webf-tabs-wrapper .webf-tab-accordion:not(:first-of-type) {
  border-top: 0;
}
.webf-tabs-wrapper .webf-tab-accordion:not(:last-of-type) {
  border-bottom: 0;
}
.webf-tabs-wrapper .webf-tab-accordion:not(:first-of-type):not(:last-of-type) {
  border-top: 0;
  border-bottom: 0;
}

.webf-tagsinput {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #555;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  padding: 3px 6px;
  line-height: 22px;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  cursor: text;
  outline: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
.webf-tagsinput > .webf-tag {
  background-color: #4b70df;
  margin: 0 4px 0 0;
  color: white;
  display: inline-block;
  padding: 5px;
  font-size: 11.5px;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.webf-tagsinput > .webf-tag:focus {
  background-color: #2146b8;
  outline: none;
}
.webf-tagsinput > .webf-tag .remove {
  margin-left: 7px;
  cursor: pointer;
  font-size: 10px;
}
.webf-tagsinput input.webf-tagsinput-text {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 13px;
  background-color: transparent;
  margin: 0;
  padding: 2px 0;
  max-width: inherit;
}
.webf-tagsinput input.webf-tagsinput-text.empty {
  width: 100%;
}

.wrapper-webf-textarea {
  display: inline-block;
  line-height: 1px;
}

.webf-textarea {
  border: 0 none white !important;
  overflow: hidden;
  padding: 0 !important;
  outline: none !important;
  background-color: transparent !important;
  resize: none !important;
  box-shadow: none !important;
}

.webf-simple-line {
  height: auto !important;
}

.webf-textarea-mirror {
  min-height: auto !important;
}

.webf-timepicker {
  border: 1px solid #bbbbbb;
  background-color: #fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.175) 2px 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.175) 2px 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.175) 2px 3px 3px;
}
.webf-timepicker * {
  box-sizing: border-box;
}
.webf-timepicker table {
  padding: 3px 5px;
}
.webf-timepicker table tr td {
  height: 30px;
  padding: 0 4px;
  text-align: center;
}
.webf-timepicker table tr td input[type=text] {
  display: inline-block;
  color: #555555;
  font-size: 13px;
  height: 26px;
  line-height: 18px;
  margin: 0;
  text-align: center;
  width: 35px;
}
.webf-timepicker table tr td a {
  display: inline-block;
  font-size: 19px;
  color: black;
  width: 35px;
  padding: 7px 0;
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.webf-timepicker table tr td a:hover {
  background: #eee;
  border-color: #bbbbbb;
}
.webf-timepicker table tr td a.up {
  cursor: n-resize;
}
.webf-timepicker table tr td a.down {
  cursor: s-resize;
}
.webf-timepicker table tr td.td-seconds, .webf-timepicker table tr td.time-separator.minutes-seconds, .webf-timepicker table tr td.td-meridian, .webf-timepicker table tr td.time-separator.meridian {
  display: none;
}
.webf-timepicker.show-seconds table tr td.td-seconds,
.webf-timepicker.show-seconds table tr td.time-separator.minutes-seconds {
  display: table-cell;
}
.webf-timepicker.show-meridian table tr td.td-meridian,
.webf-timepicker.show-meridian table tr td.time-separator.meridian {
  display: table-cell;
}

.webf-dialog.webf-dialog-timepicker {
  width: auto;
}
.webf-dialog.webf-dialog-timepicker .webf-timepicker {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.webf-dialog.webf-dialog-timepicker .webf-timepicker table tr td {
  padding: 0 8px;
}
.webf-dialog.webf-dialog-timepicker .webf-timepicker table tr td input[type=text] {
  width: 40px;
}

.webf-toast-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #333;
  opacity: 0.3;
  -webkit-transition: 200ms;
  -moz-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
.webf-toast-overlay.webf-enter {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 0;
}
.webf-toast-overlay.webf-leave {
  opacity: 0;
}

.webf-toast {
  position: absolute;
  padding: 5px 10px;
  color: #ddd;
  background-color: #333;
  -webkit-box-shadow: 1px 1px 2px 0px rgb(50, 50, 50);
  -moz-box-shadow: 1px 1px 2px 0px rgb(50, 50, 50);
  box-shadow: 1px 1px 2px 0px rgb(50, 50, 50);
  -webkit-transition: 200ms;
  -moz-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
.webf-toast.dismissible {
  padding-right: 15px;
}
.webf-toast.webf-enter {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: translateY(calc(100% + 10px));
  -moz-transform: translateY(calc(100% + 10px));
  -ms-transform: translateY(calc(100% + 10px));
  -o-transform: translateY(calc(100% + 10px));
  transform: translateY(calc(100% + 10px));
}
.webf-toast.webf-leave {
  -webkit-transform: translateX(calc(-100% - 12px));
  -moz-transform: translateX(calc(-100% - 12px));
  -ms-transform: translateX(calc(-100% - 12px));
  -o-transform: translateX(calc(-100% - 12px));
  transform: translateX(calc(-100% - 12px));
}
.webf-toast button {
  float: right;
  position: relative;
  left: 10px;
}

.webf-tooltip {
  position: relative;
  display: inline-block;
}
.webf-tooltip > .tooltiptext, .webf-tooltip > label > .tooltiptext {
  visibility: hidden;
  opacity: 0;
  font-family: Arial, sans-serif;
  font-size: 0.7rem;
  line-height: 1rem;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  position: absolute;
  z-index: 10000;
  width: auto;
  bottom: calc(100% + 5px);
  left: 50%;
  margin: auto;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-delay: 600ms;
}
.webf-tooltip > .tooltiptext > .triangle, .webf-tooltip > label > .tooltiptext > .triangle {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
.webf-tooltip > .tooltiptext.hidden-tooltip, .webf-tooltip > label > .tooltiptext.hidden-tooltip {
  top: -9999px;
  left: -9999px;
}
.webf-tooltip:hover > .tooltiptext, .webf-tooltip:hover > label > .tooltiptext {
  left: 50%;
  top: auto;
  display: block;
  visibility: visible;
  opacity: 1;
}
.webf-tooltip.webf-tooltip-bottom > .tooltiptext, .webf-tooltip.webf-tooltip-bottom > label > .tooltiptext {
  bottom: auto;
  top: calc(100% + 5px);
}
.webf-tooltip.webf-tooltip-bottom > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-bottom > label > .tooltiptext > .triangle {
  right: auto;
  top: -10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
.webf-tooltip.webf-tooltip-top-right > .tooltiptext, .webf-tooltip.webf-tooltip-top-right > label > .tooltiptext {
  left: 0 !important;
  right: auto;
}
.webf-tooltip.webf-tooltip-top-right > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-top-right > label > .tooltiptext > .triangle {
  right: auto;
}
.webf-tooltip.webf-tooltip-top-left > .tooltiptext, .webf-tooltip.webf-tooltip-top-left > label > .tooltiptext {
  left: auto;
  right: 0;
}
.webf-tooltip.webf-tooltip-top-left > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-top-left > label > .tooltiptext > .triangle {
  left: auto;
}
.webf-tooltip.webf-tooltip-bottom-right > .tooltiptext, .webf-tooltip.webf-tooltip-bottom-right > label > .tooltiptext {
  left: 0 !important;
  right: auto;
  bottom: auto;
  top: calc(100% + 5px);
}
.webf-tooltip.webf-tooltip-bottom-right > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-bottom-right > label > .tooltiptext > .triangle {
  right: auto;
  top: -10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
.webf-tooltip.webf-tooltip-bottom-left > .tooltiptext, .webf-tooltip.webf-tooltip-bottom-left > label > .tooltiptext {
  left: auto;
  right: 0;
  bottom: auto;
  top: calc(100% + 5px);
}
.webf-tooltip.webf-tooltip-bottom-left > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-bottom-left > label > .tooltiptext > .triangle {
  left: auto;
  top: -10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
.webf-tooltip.webf-tooltip-left > .tooltiptext, .webf-tooltip.webf-tooltip-left > label > .tooltiptext {
  left: auto;
  right: calc(100% + 5px);
  top: 0;
  bottom: auto;
}
.webf-tooltip.webf-tooltip-left > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-left > label > .tooltiptext > .triangle {
  left: calc(100% + 5px);
  right: auto;
  top: 50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}
.webf-tooltip.webf-tooltip-right > .tooltiptext, .webf-tooltip.webf-tooltip-right > label > .tooltiptext {
  left: calc(100% + 5px);
  top: 0;
  bottom: auto;
}
.webf-tooltip.webf-tooltip-right > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-right > label > .tooltiptext > .triangle {
  right: auto;
  top: 50%;
  left: -5px;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}
.webf-tooltip.webf-tooltip-right-top > .tooltiptext, .webf-tooltip.webf-tooltip-right-top > label > .tooltiptext {
  right: auto;
  left: calc(100% + 5px);
  bottom: 0;
  top: auto;
}
.webf-tooltip.webf-tooltip-right-top > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-right-top > label > .tooltiptext > .triangle {
  right: auto;
  top: auto;
  left: -5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}
.webf-tooltip.webf-tooltip-left-bottom > .tooltiptext, .webf-tooltip.webf-tooltip-left-bottom > label > .tooltiptext {
  left: auto;
  right: calc(100% + 5px);
  top: 0;
  bottom: auto;
}
.webf-tooltip.webf-tooltip-left-bottom > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-left-bottom > label > .tooltiptext > .triangle {
  left: auto;
  right: -5px;
  margin-right: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}
.webf-tooltip.webf-tooltip-left-top > .tooltiptext, .webf-tooltip.webf-tooltip-left-top > label > .tooltiptext {
  left: auto;
  right: calc(100% + 5px);
  bottom: 0;
  top: auto;
}
.webf-tooltip.webf-tooltip-left-top > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-left-top > label > .tooltiptext > .triangle {
  left: auto;
  top: auto;
  right: -5px;
  margin-right: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}
.webf-tooltip.webf-tooltip-right-bottom > .tooltiptext, .webf-tooltip.webf-tooltip-right-bottom > label > .tooltiptext {
  right: auto;
  left: calc(100% + 5px);
  top: 0;
  bottom: auto;
}
.webf-tooltip.webf-tooltip-right-bottom > .tooltiptext > .triangle, .webf-tooltip.webf-tooltip-right-bottom > label > .tooltiptext > .triangle {
  right: auto;
  left: -5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}

.webf-tree {
  height: 200px;
  overflow: auto;
  font-family: "Muli", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #8f8f8f;
  outline: none;
}
.webf-tree .root {
  padding-left: 0;
}
.webf-tree .root .group {
  transition: height 0.25s linear;
}
.webf-tree .root .group .item {
  display: flex;
  align-items: center;
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.28rem 0 0.28rem 0.6rem;
  border: 2px solid transparent;
  border-radius: 0 3rem 3rem 0;
  cursor: pointer;
}
.webf-tree .root .group .item.leaf i.fas.node {
  padding-left: 1.5rem;
}
.webf-tree .root .group .item + .group {
  display: none;
  overflow: hidden;
}
.webf-tree .root .group .item + .group.open {
  display: block;
}
.webf-tree .root .group .item.preselected {
  border: 2px solid #b7d0fc;
}
.webf-tree .root .group .item.active {
  background-color: #e8f0fe;
}
.webf-tree .root .group .item:not(.active):hover {
  background-color: #f5f5f5;
}
.webf-tree .root .group .item .indent {
  min-width: 1.35rem;
}
.webf-tree .root .group .item i.fas.webf-tree-caret {
  transition: transform 0.25s linear;
  padding: 0 0.5rem;
  font-size: 1.2rem;
}
.webf-tree .root .group .item i.fas.node {
  padding: 0 0.5rem 0 0;
  font-size: 1.3rem;
}
.webf-tree .root .group .item > span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.webf-tree .root .group .item.open i.fa-caret-right {
  transform: rotate(90deg);
}
.webf-tree.webf-scrollbox .webf-scrollbox-container {
  width: calc(100% - 12px);
}
.webf-tree.webf-scrollbox .webf-scrollbar.y {
  opacity: 1;
  width: 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-color: white;
  margin-right: 1px;
}
.webf-tree.webf-scrollbox .webf-scrollbar.y .handler {
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.webf-tree.webf-scrollbox .webf-scrollbar.y .handler:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.webf-tree-tooltip {
  background-color: rgba(95, 95, 95, 0.85);
  padding: 4px 10px;
  color: white;
  font-size: 11px;
  border-radius: 4px;
}

.webf-user-agenda {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  font-family: arial, Helvetica, sans-serif;
}
.webf-user-agenda table {
  border-collapse: collapse;
}
.webf-user-agenda table tr {
  vertical-align: top;
}
.webf-user-agenda * {
  box-sizing: content-box;
}
.webf-user-agenda .wrap-toolbar .sm .title {
  text-align: center;
  padding: 0.4rem 1rem;
}
.webf-user-agenda .wrap-toolbar .sm .title.today {
  color: #4b70df;
}
@media (min-width: 992px) {
  .webf-user-agenda .wrap-toolbar .sm {
    display: none;
  }
}
.webf-user-agenda .wrap-toolbar .toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #e8eff3;
}
.webf-user-agenda .wrap-toolbar .toolbar .buttons {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .webf-user-agenda .wrap-toolbar .toolbar .buttons .webf-button {
    font-size: 0.75rem;
    padding: 0.225rem 0.75rem;
  }
}
.webf-user-agenda .wrap-toolbar .toolbar .views {
  flex-shrink: 0;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.mouseover, .webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.focus {
  border-color: #CCCCCC;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.selected {
  background: #ccc;
  background: #4b70df;
  color: #fff;
  filter: none;
  border-color: #aaa;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.prev-week, .webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.next-week {
  padding-left: 14px;
  padding-right: 14px;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.prev-week {
  padding-left: 9px;
  padding-right: 9px;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.next-week {
  padding-left: 9px;
  padding-right: 9px;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.today {
  font-size: 14px;
}
.webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.datepicker > span {
  margin-left: 0.6rem;
}
@media (max-width: 768px) {
  .webf-user-agenda .wrap-toolbar .toolbar .views .webf-button.datepicker > span {
    display: none;
  }
}
.webf-user-agenda .wrap-toolbar .toolbar .lg .title {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.webf-user-agenda .wrap-toolbar .toolbar .lg .title.today {
  color: #4b70df;
}
@media (max-width: 991px) {
  .webf-user-agenda .wrap-toolbar .toolbar .lg .title {
    display: none;
  }
}
.webf-user-agenda .days {
  padding: 3px 0 0;
  background: #e8eff3;
}
.webf-user-agenda .days .tb-days {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.webf-user-agenda .days .tb-days .td-day a.link-days {
  color: #555;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
}
.webf-user-agenda .days .tb-days .td-day a.link-days:hover {
  cursor: pointer;
}
.webf-user-agenda .days .tb-days .td-day a.link-days:hover .nameWeekDay, .webf-user-agenda .days .tb-days .td-day a.link-days:hover .numMonthDay {
  color: #4b70df;
}
.webf-user-agenda .days .tb-days .td-day a.link-days .nameWeekDay {
  color: #999;
}
.webf-user-agenda .days .tb-days .td-day a.link-days .numMonthDay {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  font-size: 20px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.webf-user-agenda .days .tb-days .td-day a.link-days.today .numMonthDay {
  color: white;
  background-color: #4b70df;
}
.webf-user-agenda .days .tb-days .td-day a.link-days a.link-days.disabled {
  cursor: default;
}
.webf-user-agenda .days .tb-days .td-day a.link-days a.link-days.disabled:hover {
  text-decoration: none;
}
.webf-user-agenda .days .tb-days .td-hours {
  width: 26px;
}
.webf-user-agenda .users {
  padding: 2px 0;
  background: #e8eff3;
}
.webf-user-agenda .users .tb-users {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.webf-user-agenda .users .tb-users .td-hours {
  width: 26px;
}
.webf-user-agenda .users .tb-users td .user {
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
}
.webf-user-agenda .users .tb-users td .user label {
  margin: 0;
  font-size: 14px;
}
.webf-user-agenda .allday {
  /*   height: 24px; */
  border: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #fff;
  position: relative;
}
.webf-user-agenda .allday .tb-grid {
  width: 100%;
  height: 100%;
  min-height: 24px;
  table-layout: fixed;
}
.webf-user-agenda .allday .tb-grid tr:last-child {
  height: 18px;
}
.webf-user-agenda .allday .tb-grid .td-first {
  width: 26px;
  background-color: #e8eff3;
  border-right: 1px solid #ccc;
}
.webf-user-agenda .allday .tb-grid .td-col-days {
  background-color: #fff;
  position: relative;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days {
  width: 100%;
  table-layout: fixed;
  height: 100%;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-allday {
  border-right: 1px solid #ccc;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-allday:first-child {
  border-left: 1px solid #ccc;
  border-left: 0;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-allday:last-child {
  border-right: 0;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-padding-allday {
  border-right: 1px solid #ccc;
  height: 100%;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-padding-allday:first-child {
  border-left: 1px solid #ccc;
  border-left: 0;
}
.webf-user-agenda .allday .tb-grid .td-col-days .tb-days .td-col-padding-allday:last-child {
  border-right: 0;
}
.webf-user-agenda .allday .tb-grid .td-col-days .annotation {
  color: #fff;
  height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 2px 2px;
  border-radius: 3px;
  padding: 2px 4px;
  border: 1px solid #2550d2;
  background: #4b70df;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}
.webf-user-agenda .grid {
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  overflow: auto;
}
.webf-user-agenda .grid .tb-grid {
  width: 100%;
  table-layout: fixed;
}
.webf-user-agenda .grid .tb-grid .td-hours {
  width: 26px;
  background: #e8eff3;
  border-right: 1px solid #ccc;
}
.webf-user-agenda .grid .tb-grid .td-hours span.hour {
  display: block;
  float: right;
  margin: -10px 3px 0 0;
  font-size: 11px;
}
.webf-user-agenda .grid .tb-grid .td-hours .webf-border-bottom {
  position: relative;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  font-size: 0;
}
.webf-user-agenda .grid .tb-grid .timeslot {
  /*   height: 20px; */
  border-bottom: 1px dotted #ddd;
}
.webf-user-agenda .grid .tb-grid .timeslot.hour {
  border-bottom: 1px solid #ccc;
}
.webf-user-agenda .grid .tb-grid .td-day-month {
  border: 1px solid #ccc;
}
.webf-user-agenda .grid .tb-grid .td-day-month.drop {
  background: #f2f5ff;
}
.webf-user-agenda .grid .tb-grid .td-day-month.drop .cell-day .num-day {
  background-color: #e2e9ff;
}
.webf-user-agenda .grid .tb-grid .td-day-month.first-row {
  border-top: 0;
}
.webf-user-agenda .grid .tb-grid .td-day-month.last-row {
  border-bottom: 0;
}
.webf-user-agenda .grid .tb-grid .td-day-month.first-col {
  border-left: 0;
}
.webf-user-agenda .grid .tb-grid .td-day-month.last-col {
  border-right: 0;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day {
  position: relative;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 16px;
  line-height: 16px;
  text-align: right;
  overflow: hidden;
  background: #f5f5f5;
  color: #aaa;
  font-size: 11px;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day a {
  color: #aaa;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day a.disabled {
  cursor: default;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day a.disabled:hover {
  text-decoration: none;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day span {
  display: block;
  padding-right: 4px;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day.current {
  color: #444;
  font-weight: bold;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .num-day.current a {
  color: #444;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .events {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 18px;
  left: 0;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .events .event {
  color: #fff;
  cursor: pointer;
}
.webf-user-agenda .grid .tb-grid .td-day-month .cell-day .events .event .title {
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
}
.webf-user-agenda .grid .agenda {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.webf-user-agenda .grid .agenda .tb-agenda {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-hours {
  width: 26px;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day {
  border-right: 1px solid #ccc;
  height: 100%;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day:last-child {
  border-right: 0;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full {
  position: relative;
  height: 100%;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .hour-marker {
  position: absolute;
  width: 100%;
  background-color: red;
  height: 1px;
  top: 0;
  left: 0;
  display: none;
  z-index: 2;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td {
  border-right: 1px dashed #D3D3D3;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td .col-user {
  position: relative;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .tb-users td:last-child {
  border: 0;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event {
  width: 100%;
  background: #68A1E5;
  color: #fff;
  position: absolute;
  left: 0;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event.temporary {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title {
  border: 1px solid #1B62C0;
  min-height: 15px;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  background: #2B72D0;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .title.draggable {
  cursor: move;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .content {
  text-align: center;
  overflow: hidden;
  padding: 3px 3px 0;
  font-size: 13px;
  line-height: 15px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.webf-user-agenda .grid .agenda .tb-agenda .td-col-day .col-day-full .event .handle-resizable {
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  text-align: center;
  font-size: 9px;
  height: 10px;
  line-height: 8px;
  background: none;
  cursor: s-resize;
  opacity: 0;
}
.webf-user-agenda .grid .agenda .tb-agenda .tb-agenda-days {
  width: 100%;
  table-layout: fixed;
}
.webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y {
  width: 8px;
}
.webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y, .webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y:hover {
  opacity: 1;
  background: none;
}
.webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler {
  width: 80%;
  background: #555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y .handler:hover {
  background: #000;
}
@media (max-width: 768px) {
  .webf-user-agenda .webf-scrollbox-wrapper .webf-scrollbar.y {
    display: none;
  }
}

.webf-user-agenda-dragging-event .title {
  color: #fff;
  cursor: pointer;
  margin: 0 2px 2px 2px;
  border: 1px solid #1B62C0;
  height: 15px;
  min-height: 15px;
  line-height: 15px;
  background: #2B72D0;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 3px;
}

.webf-user-agenda-user {
  position: relative;
}

.webf-user-agenda-tooltip-user .triangle {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #ccc;
  position: relative;
  top: -6px;
  margin: auto;
  z-index: 10001;
}
.webf-user-agenda-tooltip-user .triangle .inner-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid #fff;
  top: 1px;
  left: -6px;
}
.webf-user-agenda-tooltip-user .content {
  width: auto;
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  padding: 5px 10px;
  position: relative;
  top: -6px;
  z-index: 10000;
  box-shadow: 2px 2px 5px #ddd;
}

input {
  outline: 0;
  outline: none;
}
input[readonly] {
  cursor: not-allowed;
}

textarea[readonly] {
  cursor: not-allowed;
}

.with-addon + .input-group-addon {
  cursor: help;
}

.webf-dialog.dialog-loading {
  width: auto;
  border: none;
  background: transparent;
  box-shadow: none;
}
.webf-dialog.dialog-loading .wrap-img-dialog-wait {
  width: 120px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 75px;
}
header .logo-acte2i img {
  width: 110px;
}
header .navbar a.lien-menu {
  color: #003D6B;
  font-size: 18px;
  text-decoration: none;
}
header .navbar .affichage-menu-cache {
  padding: 10px 16px;
  box-shadow: none;
}
header .navbar .affichage-menu-cache:hover {
  border-color: #003D6B;
}
header .menu-cache .logo-acte2i-menu {
  width: 120px;
}
header .menu-cache .contenu-menu-cache .nav-link {
  padding: 15px 0;
}
header .menu-cache .contenu-menu-cache .lien-menu-cache {
  color: white;
}
header .menu-cache .contenu-menu-cache .lien-menu-cache:hover {
  color: #FA8A07;
}

@media (max-width: 576px) {
  header a.bouton-contact {
    padding: 4px 15px;
  }
}
@media (max-width: 490px) {
  .bouton-contact,
  .bouton-espace-client {
    display: none;
  }
}
footer a {
  color: black;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer .bloc-contact-footer h3 {
  font-size: 50px;
  text-align: center;
  color: #00A3E2;
  font-weight: 700;
}
footer .bloc-contact-footer form input {
  border: none;
  border-bottom: 1px solid #003D6B;
  outline: none;
  border-radius: 0;
  margin-bottom: 30px;
}
footer .bloc-contact-footer form textarea {
  height: 150px;
  border-color: #003D6B;
  border-radius: 5px;
  margin-top: 50px;
  resize: none;
}
footer .bloc-contact-footer form input:focus,
footer .bloc-contact-footer form textarea:focus {
  box-shadow: none;
  border-color: black;
}
footer .bloc-contact-footer form input::placeholder,
footer .bloc-contact-footer form textarea::placeholder {
  color: #939393;
}
footer .bloc-contact-footer form button {
  color: #00A3E2;
  border: none;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
footer .bloc-contact-footer form button:hover {
  background-color: #f4f4f4;
}
footer .bloc-informations-contact > div {
  margin-bottom: 30px;
}
footer .bloc-informations-contact .adresse {
  background-image: url(/build/images/pictogramme-carte.5d4b051d.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-top: 5px;
}
footer .bloc-informations-contact .telephone {
  background-image: url(/build/images/pictogramme-telephone.5b410732.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-top: 5px;
}
footer .bloc-informations-contact .email {
  background-image: url(/build/images/pictogramme-email.c3be0399.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-top: 5px;
}
footer .bloc-informations-contact .adresse a,
footer .bloc-informations-contact .telephone a,
footer .bloc-informations-contact .email a {
  color: black;
  text-decoration: none;
  padding-left: 40px;
  display: inline-block;
}
footer .bloc-informations-contact .adresse a:hover,
footer .bloc-informations-contact .telephone a:hover,
footer .bloc-informations-contact .email a:hover {
  text-decoration: underline;
}
footer .bloc-informations-contact .acces-espace-client {
  font-size: 1.2em;
}
footer .bloc-informations-contact .acces-espace-client:hover {
  text-decoration: none;
}
footer .bloc-informations-legales-acte2i h4 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
footer .bloc-informations-legales-acte2i .logos-certifications {
  width: 300px;
}
footer .bloc-informations-legales-acte2i .logo-anm-consommation {
  width: 130px;
}

@media (max-width: 568px) {
  footer .bloc-contact-footer h3 {
    font-size: 2.5em;
  }
  footer .bloc-informations-legales-acte2i .logos-certifications {
    width: 100%;
  }
}
@media (max-width: 991px) {
  footer .bloc-informations-contact > div {
    margin-bottom: 15px;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  footer .bloc-informations-contact .acces-espace-client {
    font-size: 1em;
  }
}
* {
  font-family: Poppins, "sans-serif";
}

.inter {
  font-family: Inter, "sans-serif";
}

.orange {
  color: #FA8A07;
}

.bleu-clair {
  color: #00A3E2;
}

.bleu-fonce {
  color: #003D6B;
}

.test {
  color: white;
}

.noir {
  color: #3A3A3A;
}

.bg-bleu-clair {
  background-color: #00A3E2;
}

.bg-bleu-clair-light {
  background-color: #e2f7ff;
}

.bg-orange {
  background-color: #FA8A07;
}

a.bouton-acte2i,
button.bouton-acte2i {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  border-radius: 38px;
  padding: 8px 30px;
  text-decoration: none;
}
a.bouton-acte2i.bouton-bleu-clair,
button.bouton-acte2i.bouton-bleu-clair {
  color: #00A3E2;
}
a.bouton-acte2i.bouton-border-bleu-clair,
button.bouton-acte2i.bouton-border-bleu-clair {
  border: 2.23px solid #00A3E2;
}
a.bouton-acte2i.bouton-border-bleu-clair:hover,
button.bouton-acte2i.bouton-border-bleu-clair:hover {
  background-color: #00A3E2;
  color: white;
}
a.bouton-acte2i.bouton-bg-bleu-clair,
button.bouton-acte2i.bouton-bg-bleu-clair {
  background-color: #00A3E2;
  color: white;
}
a.bouton-acte2i.bouton-bg-bleu-clair:hover,
button.bouton-acte2i.bouton-bg-bleu-clair:hover {
  background-color: white !important;
  color: #00A3E2 !important;
}
a.bouton-acte2i.bouton-orange,
button.bouton-acte2i.bouton-orange {
  color: #FA8A07;
}
a.bouton-acte2i.bouton-border-orange,
button.bouton-acte2i.bouton-border-orange {
  border: 2.23px solid #FA8A07;
}
a.bouton-acte2i.bouton-border-orange:hover,
button.bouton-acte2i.bouton-border-orange:hover {
  background-color: #FA8A07;
  color: white;
}
a.bouton-acte2i.bouton-bg-orange,
button.bouton-acte2i.bouton-bg-orange {
  background-color: #FA8A07;
  color: white;
}
a.bouton-acte2i.bouton-bg-orange:hover,
button.bouton-acte2i.bouton-bg-orange:hover {
  background-color: white;
  border-color: #FA8A07;
  color: #FA8A07;
}
a.bouton-acte2i.bouton-bg-blanc,
button.bouton-acte2i.bouton-bg-blanc {
  background-color: white;
}
a.bouton-acte2i.big-bouton-acte2i,
button.bouton-acte2i.big-bouton-acte2i {
  font-size: 20px;
  padding: 12px 40px;
}

.bloc-shadow {
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.25);
}

.bloc-newsletter {
  background: linear-gradient(284deg, #003D6B 6.42%, #00A3E2 82.19%);
}
.bloc-newsletter h2 {
  color: white;
  font-size: 3em;
  font-weight: 700;
}
.bloc-newsletter input {
  padding: 8px 10px 8px 33px;
  border-radius: 47px;
  border: 2.23px solid #00A3E2;
  outline: none;
  width: 45%;
  display: inline;
}

@media (max-width: 568px) {
  .bloc-newsletter h2 {
    font-size: 2.5em;
  }
}
@media (max-width: 767px) {
  .bloc-newsletter input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .bloc-newsletter a {
    display: inline-block;
    margin-top: 10px;
  }
}
