header {
  .logo-acte2i {
    img {
      //width: 150px;
      width: 110px;
    }
  }
  
  .navbar {
    a.lien-menu {
      color: $bleu-fonce;
      font-size: 18px;
      text-decoration: none;
    }
    
    .affichage-menu-cache {
      padding: 10px 16px;
      box-shadow: none;
      
      &:hover {
        border-color: $bleu-fonce;
      }
    }
  }
  
  .menu-cache {
    .logo-acte2i-menu {
      width: 120px;
    }
    
    .contenu-menu-cache {
      .nav-link {
        //border-bottom: 1px solid white;
        padding: 15px 0;
        
        &:first-child {
          //border-top: 1px solid white;
        }
      }
      
      .lien-menu-cache {
        color: white;
        
        &:hover {
          color: $orange;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  header {
    a.bouton-contact {
      padding: 4px 15px;
    }
  }
}

@media (max-width: 490px) {
  .bouton-contact,
  .bouton-espace-client {
    display: none;
  }
}
